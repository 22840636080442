import {
  Home,
  Image,
  Inbox,
  FileText,
  Calendar,
  UserPlus,
  Archive,
} from "react-feather";

export const ADMIN_MENUITEMS = [
  {
    menutitle: "Administration",
    menucontent: "Manage InXpector",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/admin/dash`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/add-wafer`,
        icon: Image,
        title: "Wafer Task",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/disposition`,
        icon: Inbox,
        title: "Disposition",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/archived`,
        icon: Archive,
        title: "Archived",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/add-users`,
        icon: UserPlus,
        title: "Manage Users",
        type: "link",
      },
      {
        title: "LiverMore",
        icon: FileText,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/livermore-wafer`,
            type: "link",
            title: "Wafer List",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/livermore-completed-lots`,
            type: "link",
            title: "Completed Lots",
          },
          {
            title: "Livermore Master",
            icon: FileText,
            type: "sub",
            active: false,
            children: [
              // {
              //   path: `${process.env.PUBLIC_URL}/admin/lm-area-master`,
              //   type: "link",
              //   title: "Area",
              // },
              {
                path: `${process.env.PUBLIC_URL}/admin/part-id-master`,
                type: "link",
                title: "Part ID",
              },
              // {
              //   path: `${process.env.PUBLIC_URL}/admin/lm-layer-master`,
              //   type: "link",
              //   title: "Layer",
              // },
              {
                path: `${process.env.PUBLIC_URL}/admin/stage-master`,
                type: "link",
                title: "Stage",
              },
            ],
          },
        
        ],
      },
      {
        title: "Master",
        icon: Calendar,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/area-master`,
            type: "link",
            title: "Area Master",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/layer-master`,
            type: "link",
            title: "Layer Master",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/step-master`,
            type: "link",
            title: "Step Master",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/admin/layer-master`,
          //   type: "link",
          //   title: "Layer Master",
          // },
          {
            path: `${process.env.PUBLIC_URL}/admin/defects-master`,
            type: "link",
            title: "Defects Master",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/admin/status-master`,
          //   type: "link",
          //   title: "Status Master",
          // },
        ],
      },
      {
        title: "Reports",
        icon: FileText,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/archived-report`,
            type: "link",
            title: "Archived",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Inxpection-report`,
            type: "link",
            title: "Inxpection By User Report",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Inxpection-completed-report`,
            type: "link",
            title: "Inxpection Report",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/admin/review-count-by-hours-report`,
          //   type: "link",
          //   title: "Review Count By Hours Report",
          // },
        ],
      },
    ],
  },
];

export const MASTER_MENUITEMS = [
  {
    menutitle: "Administration",
    menucontent: "Manage InXpector",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/admin/dash`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/add-wafer`,
        icon: Image,
        title: "Wafer Task",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/disposition`,
        icon: Inbox,
        title: "Disposition",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/archived`,
        icon: Archive,
        title: "Archived",
        type: "link",
      },
      {
        title: "LiverMore",
        icon: FileText,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/livermore-wafer`,
            type: "link",
            title: "Wafer List",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/livermore-completed-lots`,
            type: "link",
            title: "Completed Lots",
          },
          {
            title: "Livermore Master",
            icon: FileText,
            type: "sub",
            active: false,
            children: [
              // {
              //   path: `${process.env.PUBLIC_URL}/admin/lm-area-master`,
              //   type: "link",
              //   title: "Area",
              // },
              {
                path: `${process.env.PUBLIC_URL}/admin/part-id-master`,
                type: "link",
                title: "Part ID",
              },
              // {
              //   path: `${process.env.PUBLIC_URL}/admin/lm-layer-master`,
              //   type: "link",
              //   title: "Layer",
              // },
              {
                path: `${process.env.PUBLIC_URL}/admin/stage-master`,
                type: "link",
                title: "Stage",
              },
            ],
          },
        
        ],
      },
      {
        title: "Reports",
        icon: FileText,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/archived-report`,
            type: "link",
            title: "Archived Report",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Inxpection-report`,
            type: "link",
            title: "Inxpection By User Report",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Inxpection-completed-report`,
            type: "link",
            title: "Inxpection Report",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/admin/review-count-by-hours-report`,
          //   type: "link",
          //   title: "Review Count By Hours Report",
          // },
        ],
      },
    ],
  },
];
