import { Colors } from "../constant";

export const TablePriorityStyle = (priority) => {
  let priorityStyle = "";

  switch (priority) {
    case "High": {
      priorityStyle = "4px solid " + Colors.archive + " ";
      break;
    }
    case "Medium": {
      priorityStyle = "4px solid " + Colors.onreview + " ";
      break;
    }
    case "Low": {
      priorityStyle = "";
      break;
    }
  }
  return priorityStyle;
};

export const DispositionResponseStyle = (response) => {
  let responseStyle = "";

  switch (response) {
    case "Rejected": {
      responseStyle = "4px solid " + Colors.rejected + " ";
      break;
    }
    case "Further Review": {
      responseStyle = "4px solid " + Colors.furtherReview + " ";
      break;
    }
    case "Acceptable": {
      responseStyle = "4px solid " + Colors.acceptable + " ";
      break;
    }
    case "": {
      responseStyle = "";
      break;
    }
  }
  return responseStyle;
};

export const GetStatusColor = (status) => {
  let statusColor;

  switch (status) {
    case "Open": {
      statusColor = Colors.open;
      break;
    }
    case "On Review": {
      statusColor = Colors.onreview;
      break;
    }
    case "Success": {
      statusColor = Colors.reviewCompleted;
      break;
    }
    case "On Disposition": {
      statusColor = Colors.disposition;
      break;
    }
    case "Archived": {
      statusColor = Colors.archive;
      break;
    }
  }
  return statusColor;
};

export const SecondsToDHMS = (sec) => {
  var seconds = parseInt(sec);
  seconds = Number(seconds);
  if (seconds > 0) {
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    return (dDisplay + hDisplay + mDisplay + sDisplay);
  }
  else {
    let empty = "-";
    return (empty);
  }
}