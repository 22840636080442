import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    Label,
    CardHeader,
    Button,
    CardBody,
    Table,
} from "reactstrap";
import { fetchInxpectorByDate } from "../../../../api";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DataTable from "datatables.net";
import dayjs from "dayjs";
import { ValidationForm } from "react-bootstrap4-form-validation";
import $ from 'jquery';

const InxpectorDateTime_Reports = (props) => {

    const today = dayjs(new Date());
    var defaultToDate = new Date(dayjs(today).format("YYYY-MM-DD"));
    const defaultFromDate = new Date(
        dayjs(new Date()).subtract(30, "day").format("YYYY-MM-DD")
    );

    const [loading, setLoading] = useState(true);
    const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [waferList, setWaferList] = useState([]);
    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState(defaultToDate);

    useEffect(() => {
        GetInxpectorByWaferList();
    }, []);

    useEffect(() => {
        if (waferList.length > 0) {
            if (!$.fn.DataTable.isDataTable("#companyInxpectorTable")) {
                $("#companyInxpectorTable").DataTable({
                    ordering: false,
                    destroy: true,
                });
            }
        }
    }, [waferList]);

    const clearFilter = (event) => {
        event.preventDefault();
        setFromDate(defaultFromDate);
        setToDate(defaultToDate);

    };

    const setFilter = (event) => {
        event.preventDefault();
    };

    const GetSumImages = (wafer) => {

        const imageCount = wafer.inspectionData.reduce((total, data) => total + data.inspectByUserCount, 0);
        return imageCount;
    }

    const GetCalculation = (wafer) => {

        const result = wafer.inspectionData.reduce((total, data) => total + data.inspectedTime, 0);
        var seconds = parseInt(result);
        return secondsToDhms(seconds);

    }
    const secondsToDhms = (seconds) => {
        seconds = Number(seconds);
        if (seconds > 0) {
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor(seconds % (3600 * 24) / 3600);
            var m = Math.floor(seconds % 3600 / 60);
            var s = Math.floor(seconds % 60);

            var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

            console.log("secondstodhmm: "+ dDisplay + hDisplay + mDisplay + sDisplay);
            return (dDisplay + hDisplay + mDisplay + sDisplay);
        }
        else {
            let empty = "-";
            return (empty);
        }
    }
    const GetInxpectorByWaferList = () => {
        if (fromDate && toDate) {
            $('#companyInxpectorTable').dataTable().fnDestroy();
            var tkn = `Bearer ${token}`;
            let data = `company=${companyId}&fromDate=${fromDate}&toDate=${toDate}`;

            setWaferList([]);
            setLoading(true);

            fetchInxpectorByDate(data, tkn).then((res) => {
                if (res.data.success === true) {
                    if (res.data.data.length > 0) {
                        setWaferList(res.data.data);
                    } else {
                        setWaferList([]);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    setWaferList([]);
                }
            })
                .catch((err) => console.log(err.message));
        }
    };


    return (
        <Fragment>
            <Breadcrumb title="Company Inspection Report" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col lg="10">
                                <ValidationForm onSubmit={setFilter}>
                                    <Row>
                                        <Col lg="4" className="text-left">
                                            <Label htmlFor="filterVariableType">From Date</Label>
                                            <DatePicker
                                                required
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Click to select a from date"
                                                maxDate={toDate === "" ? new Date() : toDate}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                className="form-control"
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                errorMessage={{ required: "From Date Is Required" }}
                                            />
                                        </Col>
                                        <Col lg="4" className="text-left">
                                            <Label htmlFor="filterVariableType">To Date</Label>
                                            <DatePicker
                                                required
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Click to select a to date"
                                                maxDate={new Date()}
                                                minDate={fromDate}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                className="form-control"
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                errorMessage={{ required: "To Date Is Required" }}

                                            />
                                        </Col>

                                        <Col lg="4" className="text-left">
                                            <Button
                                                title="Add Filter"
                                                className="filter-btn"
                                                color="success"
                                                size="md"
                                                onClick={GetInxpectorByWaferList}
                                            >
                                                <i className="fa fa-filter"></i>
                                            </Button>
                                            <Button
                                                title="Clear Filter"
                                                className="filter-btn ml-2"
                                                onClick={clearFilter}
                                                color="danger"
                                                size="md"
                                            >
                                                <i className="fa fa-close"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ValidationForm>
                            </Col>
                        </Row>
                    </CardHeader>
                </Card>
                <div className="dash-status">
                    <Card>
                        <CardBody>
                            <Table
                                id="companyInxpectorTable"
                                className="withoutSort dash-status-table"
                                bordered
                                responsive
                            >
                                <thead>
                                    <tr>
                                        <th className="halogenix-column">S.No #</th>
                                        <th className="halogenix-column lot-col">Wafer Name</th>
                                        <th className="halogenix-column lot-col">Priority</th>
                                        <th className="halogenix-column lot-col">Area Type</th>
                                        <th className="halogenix-column lot-col">Variable Type</th>
                                        <th className="halogenix-column lot-col">Product Type</th>
                                        <th className="halogenix-column lot-col">Inxpection Image</th>
                                        <th className="halogenix-column lot-col">Total Time</th>
                                        <th className="halogenix-column">status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {waferList.length > 0 ? waferList.map((wafer, index) => (
                                        <tr key={wafer._id}>
                                            <td>{index + 1}</td>
                                            <td>{wafer.lot}</td>
                                            <td>{wafer.highPriority}</td>
                                            <td>{wafer.areaType}</td>
                                            <td>{wafer.variableType}</td>
                                            <td>{wafer.productType}</td>
                                            <td>{GetSumImages(wafer)}</td>
                                            <td>
                                                {GetCalculation(wafer)}
                                            </td>
                                            <td>{wafer.status}</td>

                                        </tr>
                                    ))
                                        : (
                                            <tr>
                                                <td colSpan="12" className="text-center">
                                                    {" "}
                                                    No data found.{" "}
                                                </td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                            </tr>
                                        )}


                                </tbody>
                            </Table>
                            {loading && (
                                <div className="text-center">
                                    <div className="loader-box">
                                        <div className="loader-9"></div>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>

            </Container>
        </Fragment>
    );
};

export default InxpectorDateTime_Reports;