import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  ValidationForm,
  FileInput,
  TextInput,
  Checkbox,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import moment from "moment";
import { fetchActiveVariable, fetchActiveLayer, createNewLayer, fetchActiveProduct, updateLayerDetail, fetchActiveArea } from "../../../../api";
import $ from "jquery";
import DataTable from "datatables.net";
import { toast } from 'react-toastify'
toast.configure();

const LayerMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [areaType, setAreaType] = useState("");
  const [variableType, setVariableType] = useState("");
  const [layerType, setLayerType] = useState("");
  const [productType, setProductType] = useState("");
  const [layerId, setLayerId] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [filterVariableList, setFilterVariableList] = useState([]);
  const [filterAreaType, setFilterAreaType] = useState([]);
  const [filterVariableType, setFilterVariableType] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [filterProductList, setFilterProductList] = useState([]);
  const [filterProductType, setFilterProductType] = useState([]);
  const [layerList, setLayerList] = useState([]);
  const [variableList, setVariableList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );
  const formRef = useRef(null);
  const filterFormRef = useRef(null);

  useEffect(() => {
    getLayerList();
    getAreaList();
    getVariableList();
    getProductList();
  }, []);

  const getAreaList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const area = fetchActiveArea(data, tkn).then((res) => {
      if (res.data.success === true) {
        setAreaList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  };

  const getProductList = (variable, filter) => {
    setProductList([])
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&variable=${variable}`;
    const status = fetchActiveProduct(data, tkn).then((res) => {
      if (res.data.success === true) {
        if (filter === true) {
          setFilterProductList(res.data.data)
        }
        else {
          setProductList(res.data.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };


  const getVariableList = (area, filter) => {
    setVariableList([])
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&area=${area}`;
    const status = fetchActiveVariable(data, tkn).then((res) => {
      if (res.data.success === true) {
        if (filter === true) {
          setFilterVariableList(res.data.data)
        }
        else {
          setVariableList(res.data.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };


  const getLayerList = () => {
    clearFilter()
    $('#layerTable').dataTable().fnDestroy();
    setLayerList([]);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const layer = fetchActiveLayer(data, tkn).then((res) => {
      if (res.data.success === true) {
        setLayerList(res.data.data); setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (layerList.length > 0) {
      $("#layerTable").dataTable({
        'columnDefs': [{
          'targets': [6], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }]
      });
    }
  }, [layerList]);

  const addLayer = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      area: areaType,
      variable: variableType,
      product: productType,
      layerType: layerType,
      isActive: isActive,
    });
    createNewLayer(body, tkn).then((res) => {
      //   // console.log(res.data.data);
      //   clearValues();
      //   toast.success("Layer details added successfully.", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      //   });
      // });
      if (res.data.success) {
        clearValues();
        toast.success("Layer details added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    });
  }

  const clearValues = () => {
    getLayerList();
    setLoading(false);
    setIsActive(true);
    setAreaType('');
    setVariableType('');
    setVariableList([]);
    setProductList([]);
    setProductType('');
    setLayerType('');
    closeCustomizer();
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setEdit(false);
    setIsActive(true);
    setAreaType('');
    setVariableType('');
    setVariableList([]);
    setProductList([]);
    setProductType('');
    setLayerType('');
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editLayer = (layer) => {
    setEdit(true);
    setLayerId(layer._id);
    setLayerType(layer.layerType)
    if (layer.area) {
      setAreaType(layer.area._id);
      getVariableList(layer.area._id);
    }
    if (layer.variable) {
      setVariableType(layer.variable._id)
      getProductList(layer.variable._id);
    }
    if (layer.product) {
      setProductType(layer.product._id);
    }
    setIsActive(layer.isActive);
    openCustomizer();
  };

  const updateLayerProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      area: areaType,
      variable: variableType,
      product: productType,
      layerType: layerType,
      isActive: isActive,
    });
    updateLayerDetail(layerId, body, tkn).then((res) => {

      if (res.data.success) {
        setLoading(false);
        clearValues();
        toast.success("Layer details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });

      }
      else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    }).catch(function (response) {
      //handle error
      clearValues();
      console.log(response);
    });
  };

  const onAreaSelect = (e) => {
    getVariableList(e.target.value);
    setAreaType(e.target.value);
    setVariableType("");
  };

  const onVariableSelect = (e) => {
    setVariableType(e.target.value);
    getProductList(e.target.value);
    setProductType("");
  };
  const onProductSelect = (e) => {
    setProductType(e.target.value);
  };

  const loadAssyByFilter = (event) => {

    event.preventDefault();

    var table = $("#layerTable").DataTable();
    if (filterAreaType.value !== "") {
      table.columns(1).search(filterAreaType.text).draw();
    }
    else {
      table
        .search('')
        .columns().search('')
        .draw();
    }
    if (filterVariableType.value !== "") {
      table.columns(2).search(filterVariableType.text).draw();
    }
    else {
      table
        .search('')
        .columns(2).search('')
        .draw();
    }
    if (filterProductType.value !== "") {
      table.columns(3).search(filterProductType.text).draw();
    }
    else {
      table
        .search('')
        .columns(3).search('')
        .draw();
    }
  };

  const onFilterAreaSelect = (e) => {
    getVariableList(e.target.value, true);
    setFilterVariableList([]);
    setFilterProductList([])
    setFilterVariableType({ value: "", text: "" });
    setFilterProductType({ value: "", text: "" });
    setFilterAreaType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  };

  const onFilterVariableSelect = (e) => {
    getProductList(e.target.value, true);
    setFilterProductList([])
    setFilterProductType({ value: "", text: "" });
    setFilterVariableType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  };
  const onFilterProductTypeSelect = (e) => {
    setFilterProductType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  };

  const clearFilter = (e) => {
    var table = $("#layerTable").DataTable();
    setFilterAreaType({ value: "", text: "" });
    setFilterVariableType({ value: "", text: "" });
    setFilterProductType({ value: "", text: "" });
    setFilterVariableList([]);
    setFilterProductList([]);
    table
      .search('')
      .columns().search('')
      .draw();
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Layer Master" />
      <Container className="master-container layer-conatiner" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add Layer
            </Button>
          </CardHeader>
        </Card>
        <Card>
        {layerList.length > 0 &&( <CardHeader>
            <ValidationForm onSubmit={loadAssyByFilter} ref={filterFormRef}>
              <Row>
                <Col lg="3" className="text-left">
                  <Label>Select Area</Label>
                  <SelectGroup
                    type="select"
                    name="waferArea"
                    value={filterAreaType.value}
                    errorMessage={{ required: "Area Is Required" }}
                    onChange={(e) => onFilterAreaSelect(e)}
                    className="form-control digits"
                    selected={filterAreaType.value}
                  >
                    <option value="">{"All"}</option>
                    {areaList &&
                      areaList.map((area, index) => (
                        <option text={area.areaType} value={area._id} key={area._id}>
                          {area.areaType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col>
                <Col lg="3" className="text-left">
                  <Label htmlFor="filterVariableType">Select Assy. No.</Label>
                  <SelectGroup
                    type="select"
                    name="filterVariableType"
                    onChange={(e) => onFilterVariableSelect(e)}
                    errorMessage={{ required: "Assy. No. Required" }}
                    className="form-control digits"
                    selected={filterVariableType.value}
                    value={filterVariableType.value}
                  >
                    <option value="">{"All"}</option>
                    {filterVariableList &&
                      filterVariableList.map((variable, index) => (
                        <option value={variable._id} key={variable._id}>
                          {variable.variableType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col>
                <Col lg="3" className="text-left">
                  <Label htmlFor="filterProductType">Product Type</Label>
                  <SelectGroup
                    type="select"
                    name="filterProductType"
                    value={filterProductType.value}
                    errorMessage={{ required: "Product Type Is Required" }}
                    onChange={(e) => onFilterProductTypeSelect(e)}
                    className="form-control digits"
                    selected="Select Product"
                  >
                    <option value="">{"All"}</option>
                    {filterProductList &&
                      filterProductList.map((product, index) => (
                        <option value={product._id} key={product._id}>
                          {product.productType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col>
                <Col lg="3">
                  <Button title="Add Filter" className="filter-btn" color="success" size="md">
                    <i className="fa fa-filter"></i>
                  </Button>
                  <Button title="Clear Filter" onClick={clearFilter} className="filter-btn ml-2" color="danger" size="md">
                    <i className="fa fa-close"></i>
                  </Button>
                </Col>
              </Row>
            </ValidationForm>
          </CardHeader>)}


          <CardBody>
            <Table id="layerTable"
              className="layer-table"
              bordered
              responsive>
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  <th className="name-col">Area</th>
                  <th className="name-col">Assy. No.</th>
                  <th className="name-col">Product Type</th>
                  <th className="name-col">Layer Type</th>
                  {/* <th className="name-col">Created On</th> */}
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {layerList.length > 0?layerList.map((layer, index) => (
                  <tr key={layer.area._id}>
                    <td>{index + 1}</td>
                    <td>{layer.area.areaType}</td>
                    <td>{layer.variable.variableType}</td>
                    <td>{layer.product.productType}</td>
                    <td>{layer.layerType}</td>
                    {/* <td>
                      {moment(layer.createAt).format("MM-DD-YYYY")} {moment(layer.createAt).format("hh:mm")}
                    </td> */}
                    <td data-order={layer.isActive} className="text-center">
                      {layer.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i
                        onClick={() => editLayer(layer)}
                        className="icon fa fa-pencil-square"
                      ></i>
                    </td>
                  </tr>
                )):
                <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                  </tr>
                }
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Layer Type</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateLayerProcess : addLayer}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="waferArea">Area</Label>
                <SelectGroup
                  type="select"
                  name="waferArea"
                  required
                  value={areaType}
                  errorMessage={{ required: "Area Is Required" }}
                  onChange={(e) => onAreaSelect(e)}
                  className="form-control digits"
                  selected="Select Area"
                >
                  <option value="">{"Select Area"}</option>
                  {areaList &&
                    areaList.map((area, index) => (
                      <option value={area._id} key={area._id}>
                        {area.areaType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="waferVariable">Assy. No.</Label>
                <SelectGroup
                  type="select"
                  name="waferVariable"
                  onChange={(e) => onVariableSelect(e)}
                  required
                  errorMessage={{ required: "Assy. No. Required" }}
                  className="form-control digits"
                  selected={variableType}
                  value={variableType}
                >
                  <option value="">{"Select Assy. No."}</option>
                  {variableList &&
                    variableList.map((variable, index) => (
                      <option value={variable._id} key={variable._id}>
                        {variable.variableType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="ProductType">Product Type</Label>
                <SelectGroup
                  type="select"
                  name="ProductType"
                  required
                  value={productType}
                  errorMessage={{ required: "Product Type Is Required" }}
                  onChange={(e) => onProductSelect(e)}
                  className="form-control digits"
                  selected="Select Product"
                >
                  <option value="">{"Select Product Type"}</option>
                  {productList &&
                    productList.map((product, index) => (
                      <option value={product._id} key={product._id}>
                        {product.productType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="layer-type">Layer Type</Label>
                <TextInput
                  required
                  className="form-control"
                  type="text"
                  name="layer-type"
                  errorMessage={{ required: "Layer Type Is Required" }}
                  placeholder="Enter Layer Type"
                  value={layerType}
                  onChange={(e) => setLayerType(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0">
                <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={e => setIsActive(!isActive)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update Layer Type
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add Layer Type
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default LayerMasterPage;
