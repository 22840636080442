import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Colors } from "../../constant/index";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Table,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Badge,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { getWafers, updateReview, getWaferswithCount } from "../../api";
import $ from "jquery";
import DataTable from "datatables.net";
import moment from "moment";
import {
  ValidationForm,
  TextInput,
  FileInput,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import { toast } from "react-toastify";
import { TablePriorityStyle, GetStatusColor } from "../../helpers";
toast.configure();

const Inspection = (props) => {
  const [loading, setLoading] = useState(true);
  const [wafersList, setWafersList] = useState(null);
  const [reviewList, setReviewList] = useState(null);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [inxpectionFolderUrl, setInxpectionFolderUrl] = useState("");
  const [folderUpdateModal, setFolderUpdateModal] = useState(false);
  const folderToggleModal = () => setFolderUpdateModal(!folderUpdateModal);

  const [wafer, setWafer] = useState(null);
  const [editWafer, setEditWafer] = useState(null);

  const history = useHistory();
  const formRef = useRef(null);

  useEffect(() => {
    loadWafers();
    loadReviewList();
    //console.log(userRole);
  }, []);
  useEffect(() => {
    if (wafersList && wafersList.length > 0) {
      $("#reviewTable").dataTable({});
    }
  }, [wafersList]);

  useEffect(() => {
    if (reviewList && reviewList.length > 0) {
      $("#underReviewTable").dataTable({});
    }
  }, [reviewList]);

  const loadWafers = () => {
    $("#reviewTable").dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isDisposed=false&reviewInProcess=false&reviewCompleted=false`;
    getWafers(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          // console.log(res.data.data);
          setWafersList(res.data.data);
          //
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const loadReviewList = () => {
    $("#underReviewTable").dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&reviewCompleted=false&reviewInProcess=true`;
    getWaferswithCount(data, tkn)
      .then((res) => {
       
           console.log(res.data);
          setReviewList(res.data);
          //
          setLoading(false);
      })
      .catch((err) => console.log(err.message));
  };

  const onSelectWafer = (wafer) => {
    setWafer(wafer);
    setModal(!modal);
    //
  };

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const goToInspection = () => {
    setModal(!modal);
    setLoading(true);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&id=${wafer._id}`;
    updateReview(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          //
          const _wafer = res.data.data;
          setLoading(false);
          history.push(`/inspect/${wafer._id}`, { wafer: _wafer });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
    setLoading(false);

    //to={{pathname: `/inspect/${wafer._id}`, state: {wafer}}}
  };

  const editWaferData = (wafer) => {
    setEditWafer(wafer);
    setFolderUpdateModal(!folderUpdateModal);
    //openCustomizer();
  };
  const updateWaferDetails = (event) => {
    event.preventDefault();
    setLoading(true);
    setLoading(true);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&id=${editWafer._id}&inxpectionFolderUrl=${inxpectionFolderUrl}`;
    updateReview(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          const _wafer = res.data.data;
          setLoading(false);
          setInxpectionFolderUrl("");
          toast.success("Wafer Image folder updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setFolderUpdateModal(!folderUpdateModal);
        } else {
          setLoading(false);
          toast.warning("Wafer Image folder update not completed", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setFolderUpdateModal(!folderUpdateModal);
        }
      })
      .catch((err) => console.log(err.message));
    setLoading(false);
  };

  const waferPriorityBadger = (priority) => {
    // status = status.toLower;
    if (priority === "Critical") {
      return "badge badge-danger";
    }
    else if (priority === "High") {
      return "badge badge-info";
    } else if (priority === "Medium") {
      return "badge badge-warning";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Wafers List" />
      <Container fluid={true}>
        <h6>To be Reviewed</h6>
        <Card>
          <CardBody>
            <Table id="reviewTable" className="wl-table" bordered responsive>
              <thead>
                <tr>
                  <th className="halogenix-column lot-col">Lot #</th>
                  <th className="halogenix-column">
                    Status
                  </th>
                  <th className="halogenix-column">Layer</th>
                  {userRole === "manager" && (
                    <th className="halogenix-column url-col">Folder Url</th>
                  )}
                  <th className="halogenix-column name-col">Remarks</th>
                  <th className="halogenix-column date-col">Added on</th>
                  <th className="halogenix-column name-col">Added By</th>
                  {userRole === "manager" && (
                    <th className="halogenix-column name-col">Edit</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {wafersList != null ? (
                  wafersList.map((wafer, index) =>
                    userRole === "inxpector" &&
                    wafer.inxpectionFolderUrl !== undefined ? (
                      <tr key={wafer._id}>
                        <td
                          onClick={() => onSelectWafer(wafer)}
                          style={{
                            borderLeft: TablePriorityStyle(wafer.highPriority),
                          }}
                          className="text-center mouse-hand"
                        >
                          <span
                            className={waferPriorityBadger(wafer.highPriority)}
                          >
                            {wafer.lot}
                          </span>
                        </td>

                        <td>
                          <span
                            style={{ backgroundColor: Colors.open }}
                            className="badge badge-danger"
                          >
                            {" "}
                            {wafer.status}
                          </span>
                        </td>

                        <td>
                          {wafer.layer._id != null
                            ? wafer.layer.layerType
                            : wafer.layer}
                        </td>
                        {/* {userRole === "manager" && (
                          <td className="url-col">
                            <a href={wafer.folderUrl} target="_blank">
                              {wafer.folderUrl}
                            </a>
                          </td>
                        )} */}
                        <td>{wafer.remarks}</td>
                        <td>
                          {moment(wafer.createAt).format("MM-D-YYYY H:mm")}
                        </td>
                        <td>{wafer.user.name}</td>
                        {/* {userRole === "manager" && (
                          <td className="text-center">
                            <i
                              onClick={() => editWaferData(wafer)}
                              className="icon fa fa-pencil-square"
                            ></i>
                          </td>
                        )} */}
                      </tr>
                    ) : (
                      userRole === "manager" && (
                        <tr key={wafer._id}>
                          <td
                            onClick={() => onSelectWafer(wafer)}
                            style={{
                              borderLeft: TablePriorityStyle(
                                wafer.highPriority
                              ),
                            }}
                            className="text-center"
                          >
                            <span
                              className={waferPriorityBadger(
                                wafer.highPriority
                              )}
                            >
                              {wafer.lot}
                            </span>
                          </td>
                          <td>
                            <span
                              style={{ backgroundColor: Colors.open }}
                              className="badge badge-danger"
                            >
                              {" "}
                              {wafer.status}
                            </span>
                          </td>

                          <td>
                            {wafer.layer._id != null
                              ? wafer.layer.layerType
                              : wafer.layer}
                          </td>
                          {/* {userRole === "manager" && ( */}
                          <td className="url-col">
                            <a href={wafer.folderUrl} target="_blank">
                              {wafer.folderUrl}
                            </a>
                          </td>
                          {/* )} */}
                          <td>{wafer.remarks}</td>
                          <td>
                            {moment(wafer.createAt).format("MM-D-YYYY H:mm")}
                          </td>
                          <td>{wafer.user.name}</td>
                          {/* {userRole === "manager" && ( */}
                          <td className="text-center">
                            {/* <i
                              onClick={() => editWaferData(wafer)}
                              className="icon fa fa-pencil-square"
                            ></i> */}
                          </td>
                          {/* )} */}
                        </tr>
                      )
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    {userRole === "manager" && <td className="d-none"></td>}
                    {userRole === "manager" && <td className="d-none"></td>}
                  </tr>
                )}
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>

        <h6>Under Review</h6>
        <Card>
          <CardBody>
            <Table
              id="underReviewTable"
              className="wl-table"
              bordered
              responsive
            >
              <thead>
                <tr>
                  {/* <th>S.No</th> */}
                  {/* <th></th> */}
                  <th className="halogenix-column lot-col">Lot #</th>
                  <th className="halogenix-column">
                    Status
                  </th>
                  <th className="halogenix-column">Reviewed</th>
                  {userRole === "manager" && (
                    <th className="halogenix-column url-col">Folder Url</th>
                  )}
                  <th className="halogenix-column name-col">Remarks</th>
                  <th className="halogenix-column date-col">Added on</th>
                  <th className="halogenix-column name-col"></th>
                </tr>
              </thead>
              <tbody>
                {reviewList != null ? (
                  reviewList.map((review, index) => (
                    <tr key={review._id}>
                      <td
                        onClick={() => onSelectWafer(review)}
                        style={{
                          borderLeft: TablePriorityStyle(review.highPriority),
                        }}
                        className="text-center mouse-hand"
                      >
                        <span
                          className={waferPriorityBadger(review.highPriority)}
                        >
                          {review.lot}
                        </span>
                      </td>
                      {/* <td>
                        <a
                          className="btn-square btn btn-light"
                          onClick={() => onSelectWafer(review)}
                        >
                          {review.highPriority === "High" && (
                            <i className="fa fa-exclamation-circle txt-danger"></i>
                          )}{" "}
                          {review.highPriority === "Medium" && (
                            <i className="fa fa-exclamation-circle txt-warning"></i>
                          )}{" "}
                          {review.lot}
                        </a>
                      </td> */}
                      <td>
                        <span
                          style={{ backgroundColor: Colors.onreview }}
                          className="badge badge-danger"
                        >
                          {review.status} 
                        </span>
                        {/* {
                          review.reviewData != null &&
                          <span  className="badge">(Reviewed - {review.reviewData.length})</span>
                        } */}
                      </td>

                      <td>
                        {review.reviewCount}
                      </td>
                      {userRole === "manager" && (
                        <td className="url-col">
                          <a href={review.folderUrl} target="_blank">
                            {review.folderUrl}
                          </a>
                        </td>
                      )}
                      <td>{review.remarks}</td>
                      <td>
                        {moment(review.createAt).format("MM-D-YYYY H:mm")}
                      </td>
                      <td></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    {userRole === "manager" && <td className="d-none"></td>}
                  </tr>
                )}
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h5>Select Wafer</h5>
        </div>
        {/* <div>
                      You have selcted lot 
                    </div> */}
      </div>
      <Modal isOpen={modal} toggle={toggleModal} size="sm">
        <ModalBody>Confirm Wafer Selection.</ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} className="btn btn-secondary">
            Close
          </Button>
          <button onClick={goToInspection} className="btn btn-success">
            Continue
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={folderUpdateModal} toggle={folderToggleModal} size="md">
        <ModalBody>
          <h6>Update Wafer folder</h6>
          <ValidationForm
            onSubmit={updateWaferDetails}
            ref={formRef}
            className="theme-form customizer-body custom-scrollbar"
          >
            <Row>
              <Col>
                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label htmlFor="waferLot">Lot # :</Label>
                    </Col>
                    <Col lg="12">
                      {/* <Label>{editWafer && editWafer.lot}</Label> */}
                      <TextInput
                        className="form-control"
                        name="waferLot"
                        type="text"
                        placeholder="Lot #"
                        value={editWafer && editWafer.lot}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label htmlFor="waferLayer">Layer :</Label>
                    </Col>
                    <Col lg="12">
                      {/* <Label>{editWafer && editWafer.layer}</Label> */}
                      <TextInput
                        className="form-control"
                        name="waferLayer"
                        type="text"
                        placeholder="layer"
                        value={
                          editWafer && editWafer.layer != null
                            ? editWafer.layer.layerType
                            : ""
                        }
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label htmlFor="waferArea">Area :</Label>
                    </Col>
                    <Col lg="12">
                      {/* <Label>{editWafer && editWafer.area.areaType}</Label> */}
                      <TextInput
                        className="form-control"
                        name="waferArea"
                        type="text"
                        placeholder="Area"
                        value={editWafer && editWafer.area.areaType}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label htmlFor="waferVariable">Assy. No.:</Label>
                    </Col>
                    <Col lg="12">
                      {/* <Label>
                        {editWafer && editWafer.variable.variableType}
                      </Label> */}
                      <TextInput
                        className="form-control"
                        name="waferVariable"
                        type="text"
                        placeholder="Variable"
                        value={editWafer && editWafer.variable.variableType}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label htmlFor="waferURL">Folder URL :</Label>
                    </Col>
                    <Col lg="12">
                      {/* <Label>{editWafer && editWafer.folderUrl}</Label> */}
                      <TextInput
                        className="form-control"
                        name="waferURL"
                        type="text"
                        placeholder="wafer Url"
                        value={editWafer && editWafer.folderUrl}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label htmlFor="waferRemark">
                        S3 Inxpection Folder Name
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <TextInput
                        value={inxpectionFolderUrl}
                        className="form-control"
                        required
                        multiline
                        rows="3"
                        name="inxpectionFolderUrl"
                        placeholder="RDL-00043_M2_DieLevel+2.5x+v3__SB-001+TRACE+2+AFTER+ETEST__DIE__772020/"
                        errorMessage={{
                          required: "inxpection Folder Name Is Required",
                        }}
                        onChange={(e) => setInxpectionFolderUrl(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup className="text-center">
                  <Button className="success">Update Wafer</Button>
                </FormGroup>
              </Col>
            </Row>
          </ValidationForm>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Inspection;
