import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  ValidationForm,
  FileInput,
  TextInput,
  Checkbox,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import moment from "moment";
import {
  fetchActiveDesign,
  createNewDesign,
  updateDesignDetail,
  fetchActiveLMArea,
} from "../../../../../api";
import $ from "jquery";
import DataTable from "datatables.net";
import { toast } from "react-toastify";
toast.configure();

const DesignMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  // const [areaType, setAreaType] = useState("");
  const [designType, setDesignType] = useState("");
  const [designId, setDesignId] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [filterAreaType, setFilterAreaType] = useState([]);
  // const [areaList, setAreaList] = useState([]);
  const [designList, setDesignList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const formRef = useRef(null);
  const filterFormRef = useRef(null);

  useEffect(() => {
    getDesignList();
    // getAreaList();
  }, []);

  // const getAreaList = () => {
  //   var tkn = `Bearer ${token}`;
  //   let data = `company=${companyId}&isActive=true`;
  //   const area = fetchActiveLMArea(data, tkn).then((res) => {
  //     if (res.data.success === true) {
  //       setAreaList(res.data.data);
  //       // setLoading(false);
  //     } else {
  //       // setLoading(false);
  //     }
  //   });
  // };

  const getDesignList = () => {
   
    // clearFilter()
    // $("#variableTable").dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    console.log(data);
     fetchActiveDesign(data, tkn).then((res) => {
      if (res.data.success === true) {
        setDesignList(res.data.data);
        console.log(res.data.data);
        // //$("#variableTable").dataTable().fnDestroy();
        // $("#variableTable").DataTable({
        //   ordering: false,
        //   "bDestroy": true
        // });

        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
   
    if (designList.length>0) {
      $("#variableTable").dataTable({
        'columnDefs': [{
          'targets': [3], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }],
      });
    }
   }, [designList]);

  const addDesign = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      // area: areaType,
      designType: designType,
      isActive: isActive,
    });
    createNewDesign(body, tkn).then((res) => {
      if (res.data.success) {
        getDesignList();
        clearValues();
        toast.success("Part ID details added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
        closeCustomizer();
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
      // closeCustomizer();
    });
  };

  const clearValues = () => {
    setLoading(false);
    setIsActive(true);
    // setAreaType("");
    setDesignType("");
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  };

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    clearValues();
    setEdit(false);
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editDesign = (design) => {
    setEdit(true);
    setDesignId(design._id);
    setDesignType(design.designType);
    // if (design.area) {
    //   setAreaType(design.area._id);
    // }
    setIsActive(design.isActive);
    openCustomizer();
  };

  const updateDesignProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      // area: areaType,
      designType: designType,
      isActive: isActive,
    });
    updateDesignDetail(designId, body, tkn)
      .then((res) => {
        if (res.data.success) {

          toast.success("Part ID details updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setEdit(false);
          getDesignList();
          clearValues();
          closeCustomizer();
        } else {
          toast.warning(res.data.data, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
        }
      })
      .catch(function (response) {
        //handle error
        clearValues();
        console.log(response);
      });
  };
  // const onAreaSelect = (e) => {
  //   setAreaType(e.target.value);
  // };
  // const onFilterAreaSelect = (e) => {
  //   setFilterAreaType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  // };
  const loadAssyByFilter = (event) => {
    event.preventDefault();
    var table = $("#variableTable").DataTable();
    if (filterAreaType.value !== "") {
      table.columns(1).search(filterAreaType.text).draw();
    }
    else {
      table
        .search('')
        .columns().search('')
        .draw();
    }
  };

  const clearFilter = (e) => {
    var table = $("#variableTable").DataTable();
    setFilterAreaType({ value: "", text: "" });

    table
      .search('')
      .columns().search('')
      .draw();
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Part ID Master" />
      <Container className="master-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add Part ID Type
            </Button>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <Table
              id="variableTable"
              className="master-table"
              bordered
              responsive
            >
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  <th className="name-col">Part ID</th>
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {designList.length>0?designList.map((design, index) => (
                  <tr key={design._id}>
                    <td>{index + 1}</td>
                  
                    <td>{design.designType}</td>
                 
                    <td data-order={design.isActive} className="text-center">
                      {design.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i
                        onClick={() => editDesign(design)}
                        className="icon fa fa-pencil-square"
                      ></i>
                    </td>
                  </tr>
                )):
                <tr>
                <td colSpan="12" className="text-center">
                  {" "}
              No data found.{" "}
                </td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
              </tr>
                }
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Layer</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateDesignProcess : addDesign}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="design-type">Part ID Type</Label>
                <TextInput
                  required
                  className="form-control"
                  type="text"
                  name="variable-type"
                  errorMessage={{ required: "Part ID Type Is Required" }}
                  placeholder="Enter Part ID Type"
                  value={designType}
                  onChange={(e) => setDesignType(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0">
                <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={(e) => setIsActive(!isActive)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update Part ID Type
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add Part ID Type
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default DesignMasterPage;
