import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  ValidationForm,
  FileInput,
  TextInput,
  Checkbox,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import moment from "moment";
import { fetchActiveVariable, fetchActiveProduct, createNewProduct, updateProductDetail, fetchActiveArea } from "../../../../api";
import $ from "jquery";
import DataTable from "datatables.net";
import { toast } from 'react-toastify'
toast.configure();

const ProductMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [areaType, setAreaType] = useState("");
  const [variableType, setVariableType] = useState("");
  const [productType, setProductType] = useState("");
  const [productId, setProductId] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [areaList, setAreaList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [variableList, setVariableList] = useState([]);
  const [filterVariableList, setFilterVariableList] = useState([]);
  const [filterAreaType, setFilterAreaType] = useState([]);
  const [filterVariableType, setFilterVariableType] = useState([]);
  const [edit, setEdit] = useState(false);
  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );
  const formRef = useRef(null);
  const filterFormRef = useRef(null);

  useEffect(() => {
    getProductList();
    getVariableList();
    getAreaList();
  }, []);

  const getAreaList = () => {

    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const area = fetchActiveArea(data, tkn).then((res) => {
      if (res.data.success === true) {
        setAreaList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  };

  const getVariableList = (area, filter) => {
    setVariableList([])
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&area=${area}`;
    const status = fetchActiveVariable(data, tkn).then((res) => {
      if (res.data.success === true) {
        if (filter === true) {

          setFilterVariableList(res.data.data)
        }
        else {
          setVariableList(res.data.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getProductList = () => {
    clearFilter()
    $('#productTable').dataTable().fnDestroy();
    setProductList([]);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const product = fetchActiveProduct(data, tkn).then((res) => {
      if (res.data.success === true) {
        setProductList(res.data.data); setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (productList.length > 0) {
      $("#productTable").dataTable({
        'columnDefs': [{
          'targets': [5], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }]
      });
    }
  }, [productList]);

  const addProduct = (event) => {
    event.preventDefault();
    setLoading(true);
    if (areaType !== "" && productType !== "") {
      var tkn = `Bearer ${token}`;
      const body = JSON.stringify({
        company: companyId,
        area: areaType,
        variable: variableType,
        productType: productType,
        isActive: isActive,
      });
      createNewProduct(body, tkn).then((res) => {
        if (res.data.success) {
          clearValues();
          toast.success("Step details added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
          closeCustomizer();
        } else {
          toast.warning(res.data.data, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
        }
      });
    } else {
      clearValues();
      toast.error("Please enter all values", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const clearValues = () => {
    getProductList();
    setLoading(false);
    setIsActive(true);
    setAreaType('');
    setVariableType('');
    setVariableList([]);
    setProductType('');
    closeCustomizer();
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setEdit(false);
    setLoading(false);
    setIsActive(true);
    setAreaType('');
    setVariableType('');
    setVariableList([]);
    setProductType('');
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editProduct = (product) => {
    setEdit(true);
    setProductId(product._id);
    setProductType(product.productType);
    if (product.area) {
      setAreaType(product.area._id);
      getVariableList(product.area._id);
    }
    if (product.variable) {
      setVariableType(product.variable._id)
    }

    setIsActive(product.isActive);
    openCustomizer();
  };

  const updateProductProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      area: areaType,
      variable: variableType,
      productType: productType,
      isActive: isActive,
    });
    updateProductDetail(productId, body, tkn).then((res) => {

      if (res.data.success) {
        setEdit(false);
        toast.success("Step details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
        clearValues();
      }
      else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    }).catch(function (response) {
      //handle error
      clearValues();
      console.log(response);
    });
  };

  const onAreaSelect = (e) => {
    getVariableList(e.target.value);
    setVariableType("");
    setAreaType(e.target.value);
  };
  const onVariableSelect = (e) => {
    setVariableType(e.target.value);
  };

  const loadAssyByFilter = (event) => {

    event.preventDefault();

    var table = $("#productTable").DataTable();
    if (filterAreaType.value !== "") {
      table.columns(1).search(filterAreaType.text).draw();
    }
    else {
      table
        .search('')
        .columns().search('')
        .draw();
    }
    if (filterVariableType.value !== "") {
      table.columns(2).search(filterVariableType.text).draw();
    }
    else {
      table
        .search('')
        .columns(2).search('')
        .draw();
    }
  };

  const onFilterAreaSelect = (e) => {
    getVariableList(e.target.value, true);
    setFilterVariableList([]);
    setFilterVariableType({ value: "", text: "" });
    setFilterAreaType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  };

  const onFilterVariableSelect = (e) => {
    setFilterVariableType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  };

  const clearFilter = (e) => {
    var table = $("#productTable").DataTable();
    setFilterAreaType({ value: "", text: "" });
    setFilterVariableType({ value: "", text: "" });
    setFilterVariableList([]);
    table
      .search('')
      .columns().search('')
      .draw();
  };
  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Step Master" />
      <Container className="master-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add Step
            </Button>
          </CardHeader>
        </Card>
        <Card>
        {productList.length>0&&(<CardHeader>
            <ValidationForm onSubmit={loadAssyByFilter} ref={filterFormRef}>
              <Row>
                <Col lg="4" className="text-left">
                  <Label>Select Area</Label>
                  <SelectGroup
                    type="select"
                    name="waferArea"
                    value={filterAreaType.value}
                    errorMessage={{ required: "Area Is Required" }}
                    onChange={(e) => onFilterAreaSelect(e)}
                    className="form-control digits"
                    selected={filterAreaType.value}
                  >
                    <option value="">{"All"}</option>
                    {areaList &&
                      areaList.map((area, index) => (
                        <option text={area.areaType} value={area._id} key={area._id}>
                          {area.areaType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col>
                <Col lg="4" className="text-left">
                  <Label htmlFor="filterVariableType">Select Layer</Label>
                  <SelectGroup
                    type="select"
                    name="filterVariableType"
                    onChange={(e) => onFilterVariableSelect(e)}
                    errorMessage={{ required: "Layer Required" }}
                    className="form-control digits"
                    selected={filterVariableType.value}
                    value={filterVariableType.value}
                  >
                    <option value="">{"All"}</option>
                   
                    {filterVariableList &&
                      filterVariableList.map((variable, index) => (
                        <option value={variable._id} key={variable._id}>
                          {variable.variableType}
                        </option>
                      ))}
                  </SelectGroup>

                </Col>
                <Col lg="4" className="text-left">
                  <Button title="Add Filter" className="filter-btn" color="success" size="md">
                    <i className="fa fa-filter"></i>
                  </Button>
                  <Button title="Clear Filter" onClick={clearFilter} className="filter-btn ml-2" color="danger" size="md">
                    <i className="fa fa-close"></i>
                  </Button>
                </Col>
              </Row>
            </ValidationForm>
          </CardHeader>)}
          <CardBody>
            <Table id="productTable"
              className="master-table"
              bordered
              responsive>
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  <th className="name-col">Area</th>
                  <th className="name-col">Layer</th>
                  <th className="name-col">Step</th>
                  {/* <th className="name-col">Created On</th> */}
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {productList.length>0?productList.map((product, index) => (
                  <tr key={product.area._id}>
                    <td>{index + 1}</td>
                    <td>{product.area.areaType}</td>
                    <td>{product.variable.variableType}</td>
                    <td>{product.productType}</td>
                    {/* <td>
                      {moment(product.createAt).format("MM-DD-YYYY")} {moment(product.createAt).format("hh:mm")}
                    </td> */}
                    <td data-order={product.isActive} className="text-center">
                      {product.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i
                        onClick={() => editProduct(product)}
                        className="icon fa fa-pencil-square"
                      ></i>
                    </td>
                  </tr>
                )):<tr>
                <td colSpan="12" className="text-center">
                  {" "}
                  No data found.{" "}
                </td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
              </tr>}
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Step</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateProductProcess : addProduct}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="waferArea">Area</Label>
                <SelectGroup
                  type="select"
                  name="waferArea"
                  required
                  value={areaType}
                  errorMessage={{ required: "Area Is Required" }}
                  onChange={(e) => onAreaSelect(e)}
                  className="form-control digits"
                  selected="Select Area"
                >
                  <option value="">{"Select Area"}</option>
                  {areaList &&
                    areaList.map((area, index) => (
                      <option value={area._id} key={area._id}>
                        {area.areaType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="waferVariable">Layer</Label>
                <SelectGroup
                  type="select"
                  name="waferVariable"
                  onChange={(e) => setVariableType(e.target.value)}
                  required
                  errorMessage={{ required: "Layer Required" }}
                  className="form-control digits"
                  selected={variableType}
                  value={variableType}
                >
                  <option value="">{"Select Layer"}</option>
                  {variableList &&
                    variableList.map((variable, index) => (
                      <option value={variable._id} key={variable._id}>
                        {variable.variableType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="product-type">Step</Label>
                <TextInput
                  required
                  className="form-control"
                  type="text"
                  name="product-type"
                  errorMessage={{ required: "Step Is Required" }}
                  placeholder="Enter Step"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                />
              </FormGroup>

            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0">
                <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={e => setIsActive(!isActive)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update Step
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add Step
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default ProductMasterPage;
