import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Label,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ListGroup,
  NavLink,
  Collapse,
} from "reactstrap";
import { toast } from "react-toastify";

import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from "validator";
import InnerImageZoom from "react-inner-image-zoom";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import ImageWithModal from "../../components/imageWithModal/ImageWithModal";
import DisplayRemarks from "./displayRemarks";
import {
  getWafers,
  updateReview,
  createReviewDataWithImage,
  fetchActiveDefetcts,
  getwaferInxpectionImages,
  updateReviewDataWithAnnotation,
  deleteReview,
} from "../../api";

// import Annotation from "react-image-annotation";
// import {
//   PointSelector,
//   RectangleSelector,
//   OvalSelector,
// } from "react-image-annotation/lib/selectors";

import Annotation from "../../../src/annotation";
import { RectangleSelector } from "../../../src/selectors";

import { Accordion } from "react-bootstrap";
import * as htmlToImage from "html-to-image";
import { toJpeg } from "html-to-image";
import $ from "jquery";

const InspectPage = () => {
  const [loading, setLoading] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [wafer, setWafer] = useState(null);
  const [updateTime, setUpdateTime] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [folderImages, setFolderImages] = useState([]);
  const [lastReviewedImageIndex, setLastReviewedImageIndex] = useState(0);
  const [imageName, setImageName] = useState("");
  const [zoomImageSrc, setZoomImageSrc] = useState("");
  const [loadImageToAnnotate, setLoadImageToAnnotate] = useState("");
  const [loadImageToAnnotateCount, setLoadImageToAnnotateCount] = useState(0);
  const [loadImageToAnnotateId, setLoadImageToAnnotateId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [reviewedImage, setReviewedImage] = useState(null);
  const [defectLogs, setDefectLogs] = useState(null);
  const [lotSpecificLibrary, setLotSpecificLibrary] = useState(null);
  const [defectsList, setDefectsList] = useState([]);
  const [editDefectsList, setEditDefectsList] = useState([]);
  const [selectedDefects, setSelectedDefects] = useState(0);
  const [annotation, setAnnotation] = useState({});
  const [annotations, setAnnotations] = useState([]);
  const [annotationValue, setAnnotationValue] = useState({});
  const [editAnnotation, setEditAnnotation] = useState({});
  const [editAnnotations, setEditAnnotations] = useState([]);
  const [editAnnotationValue, setEditAnnotationValue] = useState({});
  const [editReviewData, setEditReviewData] = useState(null);
  const [deletingReviewData, setDeletingReviewData] = useState(null);
  const [defectError, setDefectError] = useState("");

  const [editDefectError, setEditDefectError] = useState("");

  const [editRemarks, setEditRemarks] = useState("");
  const [editReviewIndex, setEditReviewIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const formRef = useRef(null);
  const editFormRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [zoomMode, setZoomMode] = useState(false);
  const [isLOpen, setIsLOpen] = useState(false);
  const Ltoggle = () => setIsLOpen(!isLOpen);
  const [updateModal, setUpdateModal] = useState(false);
  const toggleReviewModal = () => setUpdateModal(!updateModal);
  const [checkReview, setCheckReview] = useState(false);
  // const [pageSize, setPageSize] = useState({
  //   width: 800,
  //   height: 500,
  // });
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("currentUser"))
  );
  const { id } = useParams();
  const { state } = useLocation();
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));

  const history = useHistory();

  useEffect(() => {
    getDefetctsList();
    setWafer(state.wafer);
    getImagesList();
    assignReviewDeffects();
    assignWaferLotSpecificLibrary();
    if (state.wafer) {
      moveToExactImageCount(state.wafer.review.reviewData, folderImages);
    }
    console.log(state.wafer);
    console.log(user);
  }, []);

  // useEffect(() => {
  //   getImagesList();
  // }, [folderImages]);

  const assignReviewDeffects = () => {
    if (state.wafer.review) {
      setDefectLogs(state.wafer.review);
      checkReviewed(state.wafer.review);
      // console.log(state.wafer.review);
    }
  };

  const moveToExactImageCount = (reviewingData, folderImages) => {
    if (reviewingData.length > 0 && folderImages.length > 0) {
      if (reviewingData[reviewingData.length - 1]) {
        const lastReviewedAnnotatedIndex =
          reviewingData[reviewingData.length - 1].lastReviewedImageIndex; // data that is retrieved from the DB

        if (lastReviewedAnnotatedIndex < folderImages.length) {
          const nextReviewingAnnotatedIndex = lastReviewedAnnotatedIndex + 1; // increase the count so we can reach the next image

          setLoadImageToAnnotateCount(nextReviewingAnnotatedIndex);
          if (folderImages.length) {
            setLoadImageToAnnotate(folderImages[nextReviewingAnnotatedIndex]);
            setLoadImageToAnnotateId("img_bind_" + nextReviewingAnnotatedIndex);
          }
        } else {
          // last image is reached

          setLoadImageToAnnotateCount(lastReviewedAnnotatedIndex - 1);
          if (folderImages) {
            setLoadImageToAnnotate(
              folderImages[lastReviewedAnnotatedIndex - 1]
            );
            setLoadImageToAnnotateId(
              "img_bind_" + lastReviewedAnnotatedIndex - 1
            );
          }
        }
      } else {
        // something else
      }
    }
  };

  const assignWaferLotSpecificLibrary = () => {
    if (state.wafer.waferImages) {
      setLotSpecificLibrary(state.wafer.waferImages);
    }
  };

  useEffect(() => {
    if (state.wafer && folderImages.length > 0) {
      moveToExactImageCount(state.wafer.review.reviewData, folderImages);
    } else {
      let loadImage = folderImages[0];
      setLoadImageToAnnotate(loadImage); //res.data.imageList[0]
      setLoadImageToAnnotateCount(0);
      setLoadImageToAnnotateId("img_bind_0");
    }
  }, [folderImages]);

  const getImagesList = () => {
    setLoading(true);
    var tkn = `Bearer ${token}`;
    getwaferInxpectionImages(state.wafer._id, tkn)
      .then((res) => {
        if (res.data.success === true) {
          setLoading(false);
          setFolderImages(res.data.imageList);
          // if (state.wafer && folderImages.length > 0) {
          //   moveToExactImageCount(state.wafer.review.reviewData, folderImages);
          // } else {
          //   setLoadImageToAnnotate(res.data.imageList[0]);
          //   setLoadImageToAnnotateCount(0);
          //   setLoadImageToAnnotateId("img_bind_0");
          // }
          // //imageList
        } else {
          setLoading(false);
        }
      })
      // .then((res) => {
      //   if (state.wafer && folderImages.length > 0) {
      //     moveToExactImageCount(state.wafer.review.reviewData);
      //   } else {
      //     setLoadImageToAnnotate(res.data.imageList[0]);
      //     setLoadImageToAnnotateCount(0);
      //     setLoadImageToAnnotateId("img_bind_0");
      //   }
      // })
      .catch((err) => console.log(err.message));
    setLoading(false);
  };

  const getDefetctsList = () => {
    //setDefectsList([]);
    //setEditDefectsList([]);
    var tkn = `Bearer ${token}`;
    const data = `company=${companyId}&area=${state.wafer.area._id}`;
    fetchActiveDefetcts(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          // setDefectsList([]);
          // setEditDefectsList([]);
          //let choiceList = [];
          if (res.data.data.length > 0) {
            for (var i = 0; i < res.data.data.length; i++) {
              let FilteringDefects = [];
              FilteringDefects._id = res.data.data[i]._id;
              FilteringDefects.defectsType = res.data.data[i].defectsType;
              FilteringDefects.defectsImage = res.data.data[i].defectsImage;
              FilteringDefects.isChecked = false;
              //choiceList.push(FilteringDefects);
              defectsList.push(FilteringDefects);
              //editDefectsList.push(FilteringDefects);
              editDefectsList.push(FilteringDefects);
            }
            // setDefectsList(choiceList);
            // setEditDefectsList(choiceList);
          }

          // console.log(res.data)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editReviewProcess = (review) => {
    setLoading(true);
    resetDefects();
    //setUpdateModal(false);

    if (review.annotationValue.length > 0) {
      let bindData = [];
      for (let i = 0; i < review.annotationValue.length; i++) {
        //const { geometry, data } = review.annotationValue[i];
        if (review.annotationValue[i][0].geometry) {
          let newData = {};
          newData.geometry = review.annotationValue[i][0].geometry;
          newData.data = review.annotationValue[i][0].data;

          bindData.push(newData);
        }
      }
      setEditAnnotations(bindData);
    }
    const receivedList = editDefectsList;
    if (review.defectClassification.length > 0) {
      review.defectClassification.forEach((val) => {
        for (let j = 0; j < editDefectsList.length; j++) {
          if (editDefectsList[j].defectsType === val) {
            editDefectsList[j].isChecked = true;
          }
        }
        // for (let j = 0; j < receivedList.length; j++) {
        //   if (receivedList[j].defectsType === val) {
        //     receivedList[j].isChecked = true;
        //   }
        // }
      });
    }
    setEditReviewData(review);
    setEditReviewIndex(review.lastReviewedImageIndex);
    //setEditDefectsList(receivedList);

    console.log(editDefectsList);
    setEditRemarks(review.reviewRemark);
    closeCustomizer();
    setUpdateModal(!updateModal);
    setLoading(false);
  };

  const formatElapsedTime = (time) => {
    //convert the elapsed time as hour:minute:second
    // const ms = Math.round(time / 100) % 10
    const secs = String("0" + (Math.floor(time / 1000) % 60)).slice(-2);
    const mins = String("0" + (Math.floor(time / 1000 / 60) % 60)).slice(-2);
    // var hours = String("0" + Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
    const hrs = String("0" + Math.floor((time / (1000 * 60 * 60)) % 24)).slice(
      -2
    );
    const days = String("0" + Math.floor(time / (1000 * 60 * 60 * 24))).slice(
      -2
    );

    if (days > 0) {
      return `${days}:${hrs}:${mins}:${secs}`;
    } else {
      return `${hrs}:${mins}:${secs}`;
    }
  };

  // setInterval(() => {
  //   // for elapsed time - to display on each second
  //   if (state != null) {
  //     const now = moment(Date.now()).toDate();

  //     if (state != null && state.wafer !== undefined && state.wafer !== null) {
  //       let startingTime = moment(state.wafer.review.createAt).toDate();
  //       const updatingTime = now - startingTime;
  //       setUpdateTime(updatingTime);
  //     }
  //   }
  // }, 1000);

  const finishDefectDetails = () => {
    // finish the review state
    setModal(!modal);
    setLoading(true);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&id=${state.wafer._id}&is_finished=true`;
    updateReview(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          const _wafer = res.data.data;
          setLoading(false);
          toast.success("Review session completed successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          history.push(`/wafers-list`);
        } else {
          setLoading(false);
          toast.warning("Review session not completed", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => console.log(err.message));
    setLoading(false);
  };

  const finishWaferReview = (event) => {
    event.preventDefault();
    setModal(true);
  };

  const checkReviewed = (defectLogs) => {
    if (defectLogs != null && loadImageToAnnotate != null) {
      defectLogs.reviewData.forEach((image) => {
        if (image.imageName === loadImageToAnnotate.imageName) {
          setCheckReview(true);
        }
      });
    }
  };

  const updateDefectDetails = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const formData = new FormData();

    let checkedCount = 0;
    for (let i = 0; i < defectsList.length; i++) {
      if (defectsList[i].isChecked) {
        checkedCount = checkedCount + 1;
        formData.append("defectclassification", defectsList[i].defectsType);
      }
    }
    formData.append("checkedCount", checkedCount);
    console.log(JSON.stringify(editAnnotationValue));

    const annotationImageDiv = document.getElementById("editAnnotationImage");

    htmlToImage.toJpeg(annotationImageDiv).then(function (dataUrl) {
      let fileName = folderImages[loadImageToAnnotateCount].imageName;

      const editAnnotationScreenShotfile = dataURLtoFile(dataUrl, fileName);
      console.log(editAnnotationScreenShotfile);

      let findAnnotationEmpty = JSON.stringify(editAnnotationValue);

      console.log(findAnnotationEmpty);

      console.log(JSON.stringify(editAnnotations));

      if (findAnnotationEmpty !== "{}") {
        var filtered = editAnnotationValue.filter(function (el) {
          return el !== [];
        });
        const finalList = JSON.stringify(filtered);
        //formData.append("annotationObject", filtered);
        formData.append("annotationValue", finalList);
      } else {
        formData.append("annotationValue", "");
      }
      formData.append("reviewIndex", editReviewIndex);
      formData.append("remarks", editRemarks);
      formData.append("screenShotImage", editAnnotationScreenShotfile);
      formData.append("waferId", state.wafer._id);

      updateReviewDataWithAnnotation(state.wafer._id, tkn, formData)
        .then((response) => {
          if (response.data.success) {
            toast.success("Review details updated successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setDefectLogs(response.data.data.review);

            setEditReviewData(null);
            setEditAnnotations([]);
            setEditRemarks("");
            setUpdateModal(false);
            resetDefects();
            moveToExactImageCount(
              response.data.data.review.reviewData,
              folderImages
            );
            let curentformRef = editFormRef.current;
            curentformRef.resetValidationState(true);
          } else {
            toast.warning("Problem in updating Review details", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setLoading(false);
        })
        .catch(function (response) {
          setLoading(false);
          console.log(response);
        });
    });
  };

  const resetDefects = () => {
    defectsList.forEach((val) => {
      val.isChecked = false;
    });
    editDefectsList.forEach((val) => {
      val.isChecked = false;
    });

    $(".add_defect_check").prop("checked", false);
    $(".edit_defect_check").prop("checked", false);

    // for (var i = 0; i < defectsList.length; i++) {
    //   defectsList[i].isChecked = false;
    //   editDefectsList[i].isChecked = false;
    // }
    //getDefetctsList();
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const addDefectDetails = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const formData = new FormData();
    // console.log(picture);
    formData.append("lastReviewedImageIndex", loadImageToAnnotateCount);
    formData.append("remarks", remarks);
    formData.append(
      "imageName",
      folderImages[loadImageToAnnotateCount].imageName
    );
    let checkedCount = 0;
    for (let i = 0; i < defectsList.length; i++) {
      if (defectsList[i].isChecked) {
        checkedCount = checkedCount + 1;
        formData.append("defectclassification", defectsList[i].defectsType);
      }
    }
    formData.append("checkedCount", checkedCount);
    formData.append("reviewType", "defect");
    formData.append("fileUrl", folderImages[loadImageToAnnotateCount].imageUrl);
    let findAnnotationEmpty = JSON.stringify(annotationValue);
    // console.log(findAnnotationEmpty);

    //  if (findAnnotationEmpty !== "{}") {
    // console.log("available annotation");
    formData.append("annotationValue", JSON.stringify(annotationValue));
    // const annotationImageDiv = document.getElementById("annotationImage");
    // htmlToImage.toJpeg(annotationImageDiv).then(function (dataUrl) {
      //need to uncomment
      // let fileName = folderImages[loadImageToAnnotateCount].imageName;
      // const annotationScreenShotfile = dataURLtoFile(dataUrl, fileName);
      // console.log(annotationScreenShotfile);
      // formData.append("screenShotImage", annotationScreenShotfile);

      createReviewDataWithImage(state.wafer._id, tkn, formData)
        .then((response) => {
          if (response.data.success) {
            toast.success("Review details added successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setDefectLogs(response.data.data.review);
            resetDefects();
            clearValues();
            if (loadImageToAnnotateCount !== folderImages.length - 1) {
              increaseTheCount();
            }
          } else {
            toast.warning("Problem in adding Review details", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        })
        .catch(function (response) {
          setLoading(false);
          console.log(response);
        });
   //);
    // } else {
    //   console.log("empty annotation");
    //   // formData.append("annotationValue", "");
    //   toast.warning("Kindle create annotation before submit", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //   });
    //   setLoading(false);
    //   return false;
    // }
  };

  const clearValues = () => {
    setLoading(false);
    setDefectError("");
    setAnnotations([]);
    setAnnotationValue({});
    //defectsList && defectsList.filter((x) => (x.isChecked = false));
    setRemarks("");
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  };

  const onChange = (annotation) => {
    //this.setState({ annotation })
    setAnnotation(annotation);
  };

  const onSubmit = (annotation) => {
    const { geometry, data } = annotation;

    let newData = annotations.concat({
      geometry,
      data: {
        ...data,
        id: Math.random(),
      },
    });
    setAnnotation({});
    setAnnotations(newData);
    // setAnnotationValue(newData);
    setAnnotationValue(newData);
    console.log(annotationValue);
  };

  const onUpdate = (annotation) => {
    const editingAnnotations = annotations;
    let oldIndex = editingAnnotations.findIndex((a) => {
      return a.data.id === annotation.data.id;
    });
    if (oldIndex > -1) {
      editingAnnotations[oldIndex] = {
        geometry: { ...annotation.geometry },
        data: {
          ...annotation.data,
          id: annotation.data.id,
        },
      };
    }
    setAnnotation({});
    setAnnotations(editingAnnotations);
    setAnnotationValue(editingAnnotations);
    //this.setState({annotation: {}, annotations: annotations });
  };

  const onDelete = (annotation) => {
    const deleteAnnotations = annotations;
    let keepAnnotations = deleteAnnotations.filter((a) => {
      return a.data.id !== annotation.data.id;
    });
    setAnnotation({});
    setAnnotations(keepAnnotations);
    setAnnotationValue(keepAnnotations);
    //this.setState({annotation: {}, annotations: keepAnnotations });
  };

  const onEditChange = (annotation) => {
    //this.setState({ annotation })
    setEditAnnotation(annotation);
  };

  const onEditSubmit = (annotation) => {
    const { geometry, data } = annotation;

    let newData = editAnnotations.concat({
      geometry,
      data: {
        ...data,
        id: Math.random(),
      },
    });
    setEditAnnotation({});
    setEditAnnotations(newData);
    // setAnnotationValue(newData);
    setEditAnnotationValue(newData);
    console.log(editAnnotationValue);
  };

  const onEditUpdate = (annotation) => {
    let editingAnnotations = editAnnotations;
    let oldIndex = editingAnnotations.findIndex((a) => {
      return a.data.id === annotation.data.id;
    });
    if (oldIndex > -1) {
      editingAnnotations[oldIndex] = {
        geometry: { ...annotation.geometry },
        data: {
          ...annotation.data,
          id: annotation.data.id,
        },
      };
    }
    setEditAnnotation({});
    setEditAnnotations(editingAnnotations);
    setEditAnnotationValue(editingAnnotations);
    //this.setState({annotation: {}, annotations: annotations });
  };

  const onEditDelete = (annotation) => {
    let deleteAnnotations = editAnnotations;
    let keepAnnotations = deleteAnnotations.filter((a) => {
      return a.data.id !== annotation.data.id;
    });
    setEditAnnotation({});
    setEditAnnotations(keepAnnotations);
    setEditAnnotationValue(keepAnnotations);
    //this.setState({annotation: {}, annotations: keepAnnotations });
  };

  const decreaseTheCount = () => {
    // to move previous image to display
    setLoading(true);
    let currentCountindex = loadImageToAnnotateCount;
    let decreaseTheCountVal = currentCountindex - 1;
    setLoadImageToAnnotate(folderImages[decreaseTheCountVal]);
    setLoadImageToAnnotateCount(decreaseTheCountVal);
    setLoadImageToAnnotateId("img_bind_" + decreaseTheCountVal.toString());
    resetDefects();
    setAnnotation({});
    setAnnotations([]);
    setLoading(false);
    clearValues();
  };

  const increaseTheCount = () => {
    // to move to next image to display
    setLoading(true);

    let currentCountindex = loadImageToAnnotateCount;
    let increaseTheCountVal = currentCountindex + 1;
    if (increaseTheCountVal < folderImages.length) {
      // to reach until last image
      setLoadImageToAnnotate(folderImages[increaseTheCountVal]);
      setLoadImageToAnnotateCount(increaseTheCountVal);
      setLoadImageToAnnotateId("img_bind_" + increaseTheCountVal.toString());
      setAnnotation({});
      setAnnotations([]);
      resetDefects();
      clearValues();
    } else {
      // reached last image, if edit the last image, we have to reset the annotation and check box value
      // reached the last count
      setAnnotation({});
      setAnnotations([]);
      resetDefects();
    }
    setLoading(false);
  };

  const handleDefectChange = (e, defect) => {
    //e.preventDefault();
    defect.isChecked = e.target.checked;
    if (defectsList.filter((x) => x.isChecked).length > 0) {
      //setDefectError("false");
    } else {
      //setDefectError("");
    }
  };

  const handleEditDefectChange = (e, defect) => {
    //e.preventDefault();
    defect.isChecked = e.target.checked;
    if (editDefectsList.filter((x) => x.isChecked).length > 0) {
      //setEditDefectError("false");
    } else {
      //setEditDefectError("");
    }
  };

  const zoomToggle = (src) => {
    setZoomMode(!zoomMode);
    setZoomImageSrc(src);
  };

  const removeReviewDefects = (review) => {
    setDeletingReviewData(review);
    setDeleteModal(!deleteModal);
  };

  const deleteReviewInformation = () => {
    if (deletingReviewData != null) {
      setLoading(true);
      console.log(deletingReviewData);
      var tkn = `Bearer ${token}`;
      const body = JSON.stringify({
        company: companyId,
        waferId: state.wafer._id,
        deletingReviewData: deletingReviewData,
      });
      console.log(body);
      deleteReview(body, tkn).then((res) => {
        if (res.data.success) {
          toast.success("Review details deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          clearValues();
          setDefectLogs(res.data.data.review);
          setWafer(res.data.data);
          setDeleteModal(false);
          resetDefects();
          closeCustomizer();
          moveToExactImageCount(res.data.data.review.reviewData, folderImages);
        } else {
          // errro in deleting review data
          clearValues();
          closeCustomizer();
          this.forceUpdate();
          toast.warning("Error in deleting review details.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="InXpection" />
      <Container fluid={true}>
        {wafer != null && (
          <div className="info box-shadow">
            <Row>
              <Col lg="8">
                <div className="inspection-stats">
                  Lot # : <span className="color-light">{wafer.lot}</span>
                </div>
                <div className="inspection-stats">
                  {/* Layer : <span className="color-light">{wafer.layer}</span> */}
                  Layer :{" "}
                  <span className="color-light">
                    {wafer.layer._id != null ? wafer.layer.layerType : "-"}
                  </span>
                </div>
                <div className="inspection-stats">
                  Operator :{" "}
                  <span className="color-light">
                    {wafer.review.user != null ? wafer.review.user.name : "-"}
                  </span>
                </div>
                <div className="inspection-stats">
                  Start Time :{" "}
                  <span className="color-light">
                    {moment(wafer.review.createAt).format("MM-DD-YYYY HH:mm")}
                  </span>
                </div>
                {/* <div className="inspection-stats">
                  Elapsed Time :{" "}
                  <span className="color-light">
                    {formatElapsedTime(updateTime)}
                  </span>
                </div> */}
                <div className="inspection-stats">
                  Picture Name :{" "}
                  <span className="color-light">
                    {folderImages.length > 0 &&
                      folderImages[loadImageToAnnotateCount].imageName}
                  </span>
                </div>
                <div className="clear"></div>
              </Col>
              <Col lg="4">
                <div className="inspection-stats">
                  Inspection # :{" "}
                  <span className="color-light">
                    {loadImageToAnnotateCount + 1} / {folderImages.length}
                  </span>
                </div>

                {defectLogs != null &&
                defectLogs.reviewData.length > 0 &&
                defectLogs.reviewData.filter(
                  (x) => x.lastReviewedImageIndex === loadImageToAnnotateCount
                ).length > 0 ? (
                  <>
                    <div className="inspection-stats">
                      Inspection Remark :{" "}
                      <span className="color-light">
                        {
                          defectLogs.reviewData.filter(
                            (x) =>
                              x.lastReviewedImageIndex ===
                              loadImageToAnnotateCount
                          )[0].reviewRemark
                        }
                      </span>
                    </div>
                    <div className="inspection-stats">
                      <span className="badge badge-success">Reviewed</span>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* {wafer.review &&
                  wafer.review.reviewData.length > loadImageToAnnotateCount &&
                  wafer.review.reviewData[loadImageToAnnotateCount]
                    .lastReviewedImageIndex !== undefined &&
                  wafer.review.reviewData[loadImageToAnnotateCount]
                    .lastReviewedImageIndex === loadImageToAnnotateCount &&
                  wafer.review.reviewData[loadImageToAnnotateCount]
                    .reviewRemark !== undefined &&
                  wafer.review.reviewData[loadImageToAnnotateCount]
                    .reviewRemark !== null && (
                    <>
                      <div className="inspection-stats">
                        Inspection Remark :{" "}
                        <span className="color-light">
                          {
                            wafer.review.reviewData[loadImageToAnnotateCount]
                              .reviewRemark
                          }
                        </span>
                      </div>
                      <div className="inspection-stats">
                        <span className="badge badge-success">Reviewed</span>
                      </div>
                    </>
                  )} */}

                {/*
                {defectLogs.reviewData.length > 0 &&
                  defectLogs.reviewData.length > loadImageToAnnotateCount &&
                  defectLogs.reviewData[loadImageToAnnotateCount]
                    .lastReviewedImageIndex !== undefined &&
                  defectLogs.reviewData[loadImageToAnnotateCount]
                    .lastReviewedImageIndex === loadImageToAnnotateCount &&
                  defectLogs.reviewData[loadImageToAnnotateCount]
                    .reviewRemark !== undefined &&
                  defectLogs.reviewData[loadImageToAnnotateCount]
                    .reviewRemark !== null && (
                    <>
                      <div className="inspection-stats">
                        Inspection Remark :{" "}
                        <span className="color-light">
                          {
                            defectLogs.reviewData[loadImageToAnnotateCount]
                              .reviewRemark
                          }
                        </span>
                      </div>
                      <div className="inspection-stats">
                        <span className="badge badge-success">Reviewed</span>
                      </div>
                    </>
                  )} */}

                {/* {checkReview && (
                  <div className="inspection-stats">
                    <span className="badge badge-success">Reviewed</span>
                  </div>
                )} */}
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col lg="9" sm="12" md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col lg="12" md="12" sm="12" id="annotationImage">
                    <Row className="mr_t_2">
                      <Col sm="6 text-left">
                        {loadImageToAnnotateCount > 0 && (
                          <Button
                            disabled={loadImageToAnnotateCount === 0}
                            onClick={() => decreaseTheCount()}
                            color="light"
                            className="mr-1 btn btn-light btn-sm"
                          >
                            <i className="fa fa-angle-left m-r-10"></i>Prev.
                          </Button>
                        )}
                      </Col>

                      {loadImageToAnnotateCount < folderImages.length - 1 && (
                        <Col sm="6 text-right">
                          <Button
                            disabled={
                              loadImageToAnnotateCount ===
                              folderImages.length - 1
                            }
                            onClick={() => increaseTheCount()}
                            color="light"
                            className="mr-1 btn btn-light btn-sm"
                          >
                            Next<i className="fa fa-angle-right m-l-10"></i>
                          </Button>
                        </Col>
                      )}

                      {loadImageToAnnotateCount === folderImages.length - 1 && (
                        <Col sm="6 text-right">
                          <Button
                            onClick={(e) => finishWaferReview(e)}
                            color="success"
                            className="mr-1 btn-air-*"
                          >
                            Complete & close Inspection
                          </Button>
                        </Col>
                      )}
                    </Row>

                    {folderImages != null && folderImages.length > 0 && (
                      <Button
                        className="mb-2"
                        disabled={zoomMode}
                        onClick={() =>
                          zoomToggle(
                            folderImages[loadImageToAnnotateCount].imageUrl
                          )
                        }
                        color="light"
                      >
                        Zoom Image
                      </Button>
                    )}

                    {folderImages != null && folderImages.length > 0 && (
                      <>
                        <Annotation
                          // src={img}
                          loading={loading}
                          key={folderImages[loadImageToAnnotateCount].imageUrl}
                          src={folderImages[loadImageToAnnotateCount].imageUrl}
                          activeAnnotations={annotations}
                          annotations={annotations}
                          type={RectangleSelector.TYPE}
                          value={annotation}
                          onChange={onChange}
                          onSubmit={onSubmit}
                          // disableZoom={false}
                          //renderContent={renderContent}
                          onUpdate={onUpdate}
                          onDelete={onDelete}
                        />
                        {/* <img
                          key={loadImageToAnnotateId}
                          src={folderImages[loadImageToAnnotateCount].imageUrl}
                          id={loadImageToAnnotateId}
                          hidden={true}
                        /> */}
                      </>
                    )}
                  </Col>
                </Row>
                {loading && (
                  <div className="text-center">
                    <div className="loader-box">
                      <div className="loader-9"></div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" sm="12" md="12">
            <Card>
              <CardBody>
                <div className="instruction-header">Specific Instructions</div>
                <div className="instruction-body">{wafer && wafer.remarks}</div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div>
                  <a className="defectsLogLink" onClick={openCustomizer}>
                    Defects Log
                  </a>
                </div>
              </CardBody>
            </Card>
            <Accordion defaultActiveKey="0">
              <Card>
                <CardBody>
                  <div className="default-according" id="accordion">
                    <Card>
                      <CardHeader>
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link accordion-header"
                          color="default"
                          eventKey="0"
                        >
                          Defects Library
                        </Accordion.Toggle>
                      </CardHeader>
                      <Accordion.Collapse eventKey="0">
                        <CardBody>
                          <ValidationForm
                            onSubmit={addDefectDetails}
                            ref={formRef}
                            className="theme-form"
                          >
                            <FormGroup tag="fieldset">
                              <div>
                                <label className="col-form-label pt-0">
                                  Defect Classification
                                </label>
                              </div>
                              {defectsList &&
                                defectsList.map((defect, index) => (
                                  <>
                                    <div
                                      key={defect._id}
                                      className="defect-check checkbox checkbox-primary ml-2"
                                    >
                                      <Row>
                                        <Col lg="8">
                                          <input
                                            type="checkbox"
                                            className="add_defect_check"
                                            id={defect._id}
                                            //checked={defect.isChecked}
                                            value={defect.isChecked}
                                            onChange={(e) =>
                                              handleDefectChange(e, defect)
                                            }
                                          />
                                          <label
                                            className="pl-2"
                                            htmlFor={defect._id}
                                          >
                                            &nbsp;&nbsp;{defect.defectsType}
                                          </label>
                                        </Col>
                                        <Col lg="4">
                                          <ImageWithModal
                                            title={defect.defectsType}
                                            keyItem={defect.defectsImage}
                                            className="img-thumbnail defect-image"
                                            src={`https://d3lkxr26kk0rq7.cloudfront.net/${defect.defectsImage}`}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                ))}

                              {/* <TextInput
                                required
                                className="d-none"
                                errorMessage={{
                                  required: "Defect Classification Is Required",
                                }}
                                name="defectFirstError"
                                value={defectError}
                              /> */}
                            </FormGroup>
                            <FormGroup>
                              <Label className="col-form-label">Remarks</Label>
                              <TextInput
                                value={remarks}
                                className="form-control"
                                required
                                multiline
                                rows="3"
                                name="waferRemark"
                                type="textarea"
                                placeholder="Enter Remarks"
                                errorMessage={{
                                  required: "Remark Is Required",
                                }}
                                onChange={(e) => setRemarks(e.target.value)}
                              />
                            </FormGroup>
                            <FormGroup className="text-center">
                              <Row className="mr_t_2">
                                {/* {loadImageToAnnotateCount ===
                                  folderImages.length - 1 && (
                                  <Col sm="6 text-left">
                                    <Button
                                      onClick={(e) => finishWaferReview(e)}
                                      color="success"
                                      className="mr-1 btn-air-*"
                                    >
                                      Finish
                                    </Button>
                                  </Col>
                                )} */}
                                <Col sm="12 text-center">
                                  <Button
                                    disabled={loading}
                                    color="info"
                                    className="mr-1 btn-air-* btn-sm"
                                  >
                                    Submit
                                  </Button>
                                </Col>
                              </Row>
                            </FormGroup>
                          </ValidationForm>
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link accordion-header"
                          color="default"
                          eventKey="1"
                        >
                          Lot Specific Library
                        </Accordion.Toggle>
                      </CardHeader>
                      <Accordion.Collapse eventKey="1">
                        <CardBody>
                          {lotSpecificLibrary &&
                            lotSpecificLibrary.map((lot, index) => (
                              <>
                                <div
                                  key={lot._id}
                                  className="defect-check  ml-2"
                                >
                                  <Row>
                                    <Col lg="7">
                                      <label className="pl-2" htmlFor={lot._id}>
                                        {lot.imageDescription}
                                      </label>
                                    </Col>
                                    <Col lg="5">
                                      <ImageWithModal
                                        title={lot.imageDescription}
                                        keyItem={lot.imageUrl}
                                        className="img-thumbnail defect-image"
                                        src={`${lot.imageUrl}`}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <div className="customizer-contain extended-side-container-large">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h5>Defects Log</h5>
        </div>
        <div className="theme-form customizer-body custom-scrollbar">
          {defectLogs != null && defectLogs.reviewData.length > 0 ? (
            <>
              <Table className="dash-status-table" bordered responsive>
                <thead>
                  <tr>
                    {/* <th className="halogenix-column">S.No</th> */}
                    <th className="halogenix-column">Image Name</th>
                    <th className="halogenix-column date-col">Reviewed Time</th>
                    <th className="name-col halogenix-column">Defects</th>
                    <th className="name-col halogenix-column">Remarks</th>
                    <th className="name-col halogenix-column">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {defectLogs.reviewData.map((rd, index) => (
                    <tr key={rd._id}>
                      <td className="text-center mouse-hand">
                        <NavLink onClick={() => editReviewProcess(rd)}>
                          {rd.imageName}
                        </NavLink>
                      </td>
                      <td>
                        {rd.reviewRemarkSubmittedAt !== null
                          ? moment(rd.reviewRemarkSubmittedAt).format(
                              "MM-D-YYYY H:mm"
                            )
                          : "-"}
                      </td>
                      <td>
                        {rd.defectClassification != null &&
                          rd.defectClassification.join(", ")}
                      </td>
                      <td>{rd.reviewRemark}</td>
                      <td className="text-center">
                        <i
                          onClick={() => removeReviewDefects(rd)}
                          className="icon fa fa-trash txt-danger"
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            "No Defects Found"
          )}
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggleModal} size="sm">
        <ModalBody>Are you sure want to end this Review Session?</ModalBody>
        <ModalFooter>
          <Button onClick={() => toggleModal()} className="btn btn-secondary">
            Close
          </Button>
          <button
            onClick={() => finishDefectDetails()}
            className="btn btn-success"
          >
            Continue
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} size="sm">
        <ModalBody>Are you sure want to Delete this Review?</ModalBody>
        <ModalFooter>
          <Button onClick={toggleDeleteModal} className="btn btn-secondary">
            Close
          </Button>
          <button
            disabled={loading}
            onClick={() => deleteReviewInformation()}
            className="btn btn-success"
          >
            Continue
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={updateModal} toggle={toggleReviewModal} size="lg">
        <ModalHeader>
          Update Review
          {editReviewData && " - " + editReviewData.imageName}
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col lg="12" sm="12" md="12">
              {editReviewData && (
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="8" md="8" sm="8" id="editAnnotationImage">
                        <Button
                          className="mb-2"
                          disabled={zoomMode}
                          onClick={() => zoomToggle(editReviewData.imageUrl)}
                          color="light"
                        >
                          Zoom Image
                        </Button>
                        <Annotation
                          loading={loading}
                          key={1}
                          src={editReviewData.imageUrl}
                          annotations={editAnnotations}
                          activeAnnotations={editAnnotations}
                          type={RectangleSelector.TYPE}
                          value={editAnnotation}
                          onChange={onEditChange}
                          onSubmit={onEditSubmit}
                          onUpdate={onEditUpdate}
                          onDelete={onEditDelete}
                        />
                      </Col>
                      <Col lg="4" md="4" sm="4">
                        <ValidationForm
                          onSubmit={updateDefectDetails}
                          ref={editFormRef}
                          className="theme-form"
                        >
                          <FormGroup tag="fieldset">
                            <div>
                              <label className="col-form-label pt-0">
                                Defect Classification
                              </label>
                            </div>
                            {editDefectsList &&
                              editDefectsList.map((updDefect, index) => (
                                <>
                                  <div
                                    key={"upd_" + updDefect._id}
                                    className="defect-check checkbox checkbox-primary ml-2"
                                  >
                                    <Row>
                                      <Col lg="8">
                                        <input
                                          key={"upd_" + updDefect._id}
                                          className="edit_defect_check"
                                          type="checkbox"
                                          id={"upd_" + updDefect._id}
                                          //checked={defect.isChecked}
                                          // value={defect.isChecked}
                                          defaultChecked={updDefect.isChecked}
                                          // onChange={() =>
                                          //   (updDefect.isChecked =
                                          //     !updDefect.isChecked)
                                          // }
                                          onChange={(e) =>
                                            handleEditDefectChange(e, updDefect)
                                          }
                                          // onChange={(value) =>
                                          //   defect.isChecked.onChange(
                                          //     value === true
                                          //   )
                                          // }
                                        />
                                        <label
                                          className="pl-2"
                                          htmlFor={"upd_" + updDefect._id}
                                        >
                                          &nbsp;&nbsp;{updDefect.defectsType}
                                        </label>
                                      </Col>
                                      <Col lg="4">
                                        <ImageWithModal
                                          title={updDefect.defectsType}
                                          keyItem={updDefect.defectsImage}
                                          className="img-thumbnail defect-image"
                                          src={`https://d3lkxr26kk0rq7.cloudfront.net/${updDefect.defectsImage}`}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              ))}

                            {/* <TextInput
                              required
                              className="d-none"
                              errorMessage={{
                                required: "Defect Classification Is Required",
                              }}
                              name="editDefectsList"
                              value={editDefectError}
                            /> */}
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Remarks</Label>
                            <TextInput
                              value={editRemarks}
                              className="form-control"
                              required
                              multiline
                              rows="3"
                              name="waferRemark"
                              type="textarea"
                              placeholder="Enter Remarks"
                              errorMessage={{
                                required: "Remark Is Required",
                              }}
                              onChange={(e) => setEditRemarks(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="text-center">
                            <Row className="mr_t_2">
                              <Col sm="6 text-left">
                                <Button
                                  disabled={loading}
                                  color="success"
                                  className="mr-1 btn-air-*"
                                >
                                  Update
                                </Button>
                              </Col>
                              <Col sm="6 text-right">
                                <Button
                                  type="button"
                                  onClick={(e) => {
                                    setUpdateModal(!updateModal);
                                  }}
                                  className="btn btn-secondary"
                                >
                                  Close
                                </Button>
                              </Col>
                            </Row>
                          </FormGroup>
                        </ValidationForm>
                      </Col>
                    </Row>
                    {loading && (
                      <div className="text-center">
                        <div className="loader-box">
                          <div className="loader-9"></div>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Dialog
        dismissableMask={true}
        modal={zoomMode}
        header="InXpection"
        visible={zoomMode}
        className="imageModal"
        onHide={zoomToggle}
      >
        {zoomMode === true && (
          <Row className="mb-3">
            <Col lg="1" md="1" sm="1"></Col>
            <Col lg="10" md="10" sm="10">
              <InnerImageZoom
                width="600"
                height="600"
                src={zoomImageSrc}
                zoomSrc={zoomImageSrc}
              />
            </Col>
          </Row>
        )}
      </Dialog>
    </Fragment>
  );
};

export default InspectPage;
