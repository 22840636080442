// dashbaord
import Default from "../pages/dashboard";
import Inspection from "../pages/inspection";
import InspectPage from "../pages/inspection/inspectPage";
import Account from "../pages/accounts";

//Admin Pages
import AdminDashboard from "../admin/components/admin-dashboard";
import DefectsMasterPage from "../admin/pages/master/defects";
import StatusMasterPage from "../admin/pages/master/status";
import DispositionValidation from "../admin/pages/disposition/dispositionValidation";


import WaferPage from "../admin/pages/wafer";
import LiverMoreWaferPage from "../admin/pages/livemore/lot";
import NotFoundPage from "../pages/error/notFoundPage";
// starter kits
// import Starterkits from '../components/starter-kits'
// import { Component } from 'react'
import Inspector from "../pages/inspector";
import AreaMasterPage from "../admin/pages/master/area";
import VariableMasterPage from "../admin/pages/master/variable";
import ProductMasterPage from "../admin/pages/master/product";
import LayerMasterPage from "../admin/pages/master/layer";
import UsersPage from "../admin/pages/users";
import DispositionPage from "../admin/pages/disposition";
import ArchivedPage from "../admin/pages/archived";
import ArchivedDisplay from "../admin/pages/archived/archivedDisplay";
import ArchivedReports from "../admin/pages/Reports/archived";
import ReviewCompletedReports from "../admin/pages/Reports/reviewCompleted";
import InxpectionReport from "../admin/pages/Reports/inxpection/";
import InxpectionByDateReports from "../admin/pages/Reports/inxpection/inxpectorItems";
import ReviewCountByHoursReports from "../admin/pages/Reports/reviewCompleted/reviewByHours";
import LMAreaMasterPage from "../admin/pages/master/livermore/lmarea";
import DesignMasterPage from "../admin/pages/master/livermore/design";
import LMLayerMasterPage from "../admin/pages/master/livermore/layer";
import StageMasterPage from "../admin/pages/master/livermore/stage";
import LotCompletedPage from "../admin/pages/livemore/completed";


export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: Default,
    role: ["inxpector", "manager"],
  },
  {
    path: `${process.env.PUBLIC_URL}/wafers-list`,
    Component: Inspection,
    role: ["inxpector", "manager"],
  },
  {
    path: `${process.env.PUBLIC_URL}/inspect/:id`,
    Component: InspectPage,
    role: ["inxpector", "manager"],
  },
  {
    path: `${process.env.PUBLIC_URL}/disposition-inspect/:id`,
    Component: DispositionValidation,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/archive-log/:id`,
    Component: ArchivedDisplay,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/dash`,
    Component: AdminDashboard,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/add-wafer`,
    Component: WaferPage,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/disposition`,
    Component: DispositionPage,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/archived`,
    Component: ArchivedPage,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/manage/add-inspector`,
    Component: Inspector,
    role: ["manager"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/add-users`,
    Component: UsersPage,
    role: ["company-admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/area-master`,
    Component: AreaMasterPage,
    role: ["company-admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/layer-master`,
    Component: VariableMasterPage,
    role: ["company-admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/step-master`,
    Component: ProductMasterPage,
    role: ["company-admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/layer_old5-master`,
    Component: LayerMasterPage,
    role: ["company-admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/status-master`,
    Component: StatusMasterPage,
    role: ["company-admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/defects-master`,
    Component: DefectsMasterPage,
    role: ["company-admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/review-completed-report`,
    Component: ReviewCompletedReports,
    role: ["company-user", "inxpector", "manager"],
  },
  {
    path: `${process.env.PUBLIC_URL}/archived-report`,
    Component: ArchivedReports,
    role: ["company-user", "inxpector", "manager"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/archived-report`,
    Component: ArchivedReports,
    role: ["inxpector", "manager", "company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Inxpection-report`,
    Component: InxpectionReport,
    role: ["manager", "company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Inxpection-completed-report`,
    Component: InxpectionByDateReports,
    role: ["manager", "company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/404`,
    Component: NotFoundPage,
    role: ["inxpector", "manager", "company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/account`,
    Component: Account,
    role: ["company-admin", "company-user", "inxpector", "manager"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/review-count-by-hours-report`,
    Component: ReviewCountByHoursReports,
    role: ["manager", "company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/livermore-wafer`,
    Component: LiverMoreWaferPage,
    role: ["inxpector", "manager", "company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/lm-area-master`,
    Component: LMAreaMasterPage,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/part-id-master`,
    Component: DesignMasterPage,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/lm-layer-master`,
    Component: LMLayerMasterPage,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/livermore-completed-lots`,
    Component: LotCompletedPage,
    role: ["company-admin", "company-user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/stage-master`,
    Component: StageMasterPage,
    role: ["company-admin", "company-user"],
  },
];
