import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  UncontrolledCollapse,
  Label,
  Input,
  Badge,
} from "reactstrap";
import {
  ValidationForm,
  TextInput,
  FileInput,
  Checkbox,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import ImageWithModal from "../../../components/imageWithModal/ImageWithModal";
import $ from "jquery";
import DataTable from "datatables.net";
import validator from "validator";
import {
  getWafers,
  updateWafer,
  addWafer,
  fetchActiveStatus,
  fetchActiveArea,
  fetchActiveVariable,
  fetchActiveProduct,
  fetchActiveLayer,
  addWaferImages,
  deleteWafer
} from "../../../api";
import { toast } from "react-toastify";
import WaferTable from "./WaferTable";
import WaferAssetUpload from "./WaferAssetUpload";
toast.configure();

const WaferPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [wafersList, setWafersList] = useState([]);
  const [lot, setLot] = useState("");
  const [layer, setLayer] = useState("");
  const [remarks, setRemarks] = useState("");
  const [folderUrl, setFolderUrl] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [priority, setPriority] = useState("");
  const [targetTime, setTargetTime] = useState("");
  const [existPictureList, seteExistPictureList] = useState([]);
  const [status, setStatus] = useState("");
  const [areaType, setAreaType] = useState("");
  const [waferId, setWaferId] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [edit, setEdit] = useState(false);
  const [variableType, setVariableType] = useState("");
  const [variableList, setVariableList] = useState([]);
  const [sideLoading, setSideLoading] = useState([]);

  const [productType, setProductType] = useState("");
  const [productList, setProductList] = useState(false);

  const [layerType, setLayerType] = useState("");
  const [layerList, setLayerList] = useState([]);

  // const [picture, setPicture] = useState(null);
  const [pictureList, setPictureList] = useState([]);
  const [maxFilesReached, setMaxFilesReached] = useState(false);

  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const formRef = useRef(null);

  useEffect(() => {
    loadWafers();
    getStatusList();
    getAreaList();
  }, []);

  useEffect(() => {
    if (wafersList.length > 0) {
      if (!$.fn.DataTable.isDataTable("#waferMainTable")) {
        $("#waferMainTable").DataTable({
          ordering: false,
          "pageLength": 100
        });
      }
    }
  }, [wafersList]);

  const loadWafers = () => {
    $("#waferMainTable").dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isDisposed=false&reviewCompleted=false`;
    getWafers(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          setWafersList(res.data.data);
          // console.log(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getAreaList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const area = fetchActiveArea(data, tkn).then((res) => {
      if (res.data.success === true) {
        setAreaList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  };

  const getVariableList = (area) => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&area=${area}`;
    const status = fetchActiveVariable(data, tkn).then((res) => {
      if (res.data.success === true) {
        setVariableList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getProductList = (variable) => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&variable=${variable}`;
    const product = fetchActiveProduct(data, tkn).then((res) => {
      if (res.data.success === true) {
        setProductList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getLayerList = (product) => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&product=${product}`;
    const layers = fetchActiveLayer(data, tkn).then((res) => {
      if (res.data.success === true) {
        setLayerList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getStatusList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const status = fetchActiveStatus(data, tkn).then((res) => {
      if (res.data.success === true) {
        setStatusList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  };

  const addWaferDetails = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      lot: lot,
      area: areaType,
      variable: variableType,
      product: productType,
      layer: layerType,
      targetTime:targetTime,
      remarks: remarks,
      folderUrl: folderUrl,
      highPriority: priority,
      status: "Open",
    });
    addWafer(body, tkn).then((res) => {
      if (pictureList.length > 0) {
        const formData = new FormData();
        // console.log(picture);
        for (let i = 0; i < pictureList.length; i++) {
          formData.append("files", pictureList[i]._id);
          formData.append("imgDescription", pictureList[i].imageDescription);
        }
        //formData.append("files", picture);

        addWaferImages(res.data.data._id, tkn, formData)
          .then((response) => {
            // console.log(formData);
            if (response.data.success) {
              console.log(response);
              // image uploaded successfully
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      }
      clearValues();
      closeCustomizer();
      toast.success("Wafer details added successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    });
  };

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    clearValues();
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const onStatusSelect = (e) => {
    setAreaType(e.target.value);
    setVariableType("");
    setProductType("");
    setLayerType("");
    getVariableList(e.target.value);
    // console.log(variableType);
  };

  const onVariableSelect = (e) => {
    setVariableType(e.target.value);
    setProductType("");
    setLayerType("");
    getProductList(e.target.value);
    // console.log(variableType);
  };

  const onProductSelect = (e) => {
    setProductType(e.target.value);
    setLayerType("");
    getLayerList(e.target.value);
    // console.log(variableType);
  };
  const editWafer = (wafer) => {
    setSideLoading(true);
    openCustomizer();
    setEdit(true);
    console.log(wafer);
    setWaferId(wafer._id);
    if (wafer.status === "Open") {
      setIsDisabled(false);
    } else if (wafer.status === "On Review") {
      setIsDisabled(false);

    } else {
      setIsDisabled(true);
    }
    setLot(wafer.lot);
    if (wafer.area) {
      setAreaType(wafer.area._id);
      getVariableList(wafer.area._id);
    }
    if (wafer.variable) {
      getProductList(wafer.variable._id);
      setVariableType(wafer.variable._id);
    }
    if (wafer.product) {
      getLayerList(wafer.product._id);
      setProductType(wafer.product._id);
    }
    if (wafer.layer) {
      setLayerType(wafer.layer._id);
    }
    setPriority(wafer.highPriority);

    setRemarks(wafer.remarks);
    setSideLoading(false);
    seteExistPictureList(wafer.waferImages);
    setFolderUrl(wafer.folderUrl);
    
  };

  const deleteWaferItem = (wafer) => {
    setSideLoading(true);
    console.log(wafer);
    var tkn = `Bearer ${token}`;
    deleteWafer(wafer, tkn)
      .then((res) => {
        if (res.data.success) {
          toast.success("Wafer deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          clearValues();
        }else {
          toast.warning("Error deleting wafer", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          clearValues();
          setEdit(false);
        }
      }).catch(function (response) {
        clearValues();
        console.log(response);
      });
  };
  
  const updateWaferDetails = (event) => {
    event.preventDefault();
    closeCustomizer();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    console.log(waferId);

    const body = JSON.stringify({
      company: companyId,
      id: waferId,
      lot: lot,
      area: areaType,
      variable: variableType,
      product: productType,
      layer: layerType,
      // layer,
      targetTime:targetTime,
      remarks: remarks,
      folderUrl: folderUrl,
      highPriority: priority,
      //is_updating:true
    });

    updateWafer(body, tkn)
      .then((res) => {
        if (res.data.success) {
          if (pictureList.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < pictureList.length; i++) {
              formData.append("files", pictureList[i]._id);
              formData.append(
                "imgDescription",
                pictureList[i].imageDescription
              );
            }

            addWaferImages(res.data.data._id, tkn, formData)
              .then((response) => {
                if (response.data.success) {
                  console.log(response);
                }
              })
              .catch(function (response) {
                console.log(response);
              });
          }
          setEdit(false);
          toast.success("wafer details updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          clearValues();
        } else {
          toast.warning("wafer in updating Wafer", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          clearValues();
          setEdit(false);
        }
      })
      .catch(function (response) {
        //handle error
        clearValues();
        console.log(response);
      });
  };
  const clearValues = () => {
    loadWafers();
    setLoading(false);
    setIsDisabled(false);
    setLot("");
    setLayer("");
    setEdit(false);
    setFolderUrl("");
    setRemarks("");
    setPriority("");
    setTargetTime("");
    setStatus("");
    setAreaType("");
    seteExistPictureList([]);
    setProductList([]);
    setVariableList([]);
    setLayerList([]);
    setVariableType("");
    setProductType("");
    setLayerType("");
    setPictureList([]);
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Add Wafer Details" />
      <Container className="wafer-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add Wafer
            </Button>
            <div>
              <span className={`badge  text-dark table-legend border`}>
                Low Priority
              </span>
              <span className={`badge badge-warning text-white table-legend`}>
                Medium Priority
              </span>
              <span className={`badge badge-info text-white table-legend`}>
                High Priority
              </span>
              <span className={`badge badge-danger text-white table-legend`}>
               Critical
              </span>
              {/* <span className={`badge badge-primary text-white table-legend`}>
                  Open
                </span>
                <span className={`badge badge-primary text-white table-legend`}>
                  Close
                </span> */}
            </div>
          </CardHeader>
          <CardBody>
            <Table
              id="waferMainTable"
              className="withoutSort dash-status-table"
              bordered
              responsive
            >
              <thead>
                {/* <tr>
                   
                    <th colSpan='5' className='halogenix-column'>Halogenix File Upload</th>
                    <th rowSpan="2"  className='halogenix-column'></th>
                    
                  </tr> */}
                <tr>
                  <th className="halogenix-column">S.No&nbsp;&nbsp;</th>
                  <th className="halogenix-column lot-col">Lot #</th>
                  <th className="halogenix-column">Target (hrs)</th>
                  <th className="halogenix-column">Status</th>
                  {/* <th className="halogenix-column lot-col">Layer</th> */}
                  <th className="date-col halogenix-column">Added Time</th>
                  <th className="name-col halogenix-column">FFI-Tech</th>
                  <th className="name-col halogenix-column">Remarks</th>
                  {<th className="halogenix-column"></th>}
                </tr>
              </thead>
              <tbody>
                {wafersList.length > 0 ? (
                  wafersList.map((wafer, index) => (
                    <WaferTable
                      key={index}
                      wafer={wafer}
                      editWafer={editWafer}
                      deleteWaferItem={deleteWaferItem}
                      index={index}
                    />
                    //   <tr key={wafer._id}>
                    //   <td>{index + 1}</td>
                    //   {/* <td><span className={wafer.highPriority ? `badge badge-danger` : ``}>{wafer.highPriority}</span></td> */}
                    //   <td><span className={wafer.highPriority ? `badge badge-danger` : ``}>{wafer.lot}</span></td>
                    //   <td>{wafer.layer}</td>
                    //   <td className='url-col'>
                    //    <a href={wafer.folderUrl} target="_blank"> {wafer.folderUrl} </a></td>
                    //   <td>{wafer.remarks}</td>
                    //   <td>{wafer.status}</td>
                    //   <td>{moment(wafer.createAt).format('MM-DD-YYYY HH:mm')}</td>
                    //   <td>{wafer.user.name}</td>
                    //   {/* <td className="text-center">{wafer.isDisposed ?  <i className="icon-green fa fa-check-square-o"></i> :  <i className="icon fa fa-minus-circle"></i> }</td> */}
                    //   <td className="text-center">
                    //     <i className="icon fa fa-edit"></i>
                    //   </td>
                    // </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                  </tr>
                )}
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain extended-side-container">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Wafer</h6>
        </div>

        <ValidationForm
          onSubmit={edit ? updateWaferDetails : addWaferDetails}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="waferLot">Lot #</Label>
                <TextInput
                  className="form-control"
                  required
                  name="waferLot"
                  type="text"
                  placeholder="Enter Lot #"
                  errorMessage={{ required: "Lot Is Required" }}
                  disabled={isDisabled ? true : false}
                  value={lot}
                  onChange={(e) => setLot(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="waferArea">Area</Label>
                <SelectGroup
                  type="select"
                  name="waferArea"
                  required
                  errorMessage={{ required: "Area Is Required" }}
                  onChange={(e) => onStatusSelect(e)}
                  disabled={isDisabled ? true : false}
                  className="form-control digits"
                  value={areaType}
                  selected="Select Area"
                >
                  <option value="">{"Select Area"}</option>
                  {areaList &&
                    areaList.map((area, index) => (
                      <option value={area._id} key={area._id}>
                        {area.areaType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>

               <FormGroup>
                <Label htmlFor="waferVariable">Layer</Label>
                <SelectGroup
                  
                  type="select"
                  name="waferVariable"
                  onChange={(e) => onVariableSelect(e)}
                  disabled={isDisabled ? true : false}
                  
                  errorMessage={{ required: "Layer is Required" }}
                  className="form-control digits"
                  selected={variableType}
                  value={variableType}
                >
                  <option value="">{"Select Layer"}</option>
                  {variableList &&
                    variableList.map((variable, index) => (
                      <option value={variable._id} key={variable._id}>
                        {variable.variableType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup> 

              <FormGroup>
                <Label htmlFor="waferProduct">Step</Label>
                <SelectGroup
                  type="select"
                  disabled={isDisabled ? true : false}
                  name="waferProduct"
                  onChange={(e) => onProductSelect(e)}
                  required
                  errorMessage={{ required: "Step is Required" }}
                  className="form-control digits"
                  selected={productType}
                  value={productType}
                >
                  <option value="">{"Select Step"}</option>
                  {productList &&
                    productList.map((product, index) => (
                      <option value={product._id} key={product._id}>
                        {product.productType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>

              {/* <FormGroup>
                <Label htmlFor="waferLayer">Step</Label>
                <SelectGroup
                  type="select"
                  disabled={isDisabled ? true : false}
                  name="waferLayer"
                  onChange={(e) => setLayerType(e.target.value)}
                  required
                  errorMessage={{ required: "Layer Is Required" }}
                  className="form-control digits"
                  selected={layerType}
                  value={layerType}
                >
                  <option value="">{"Select Layer"}</option>
                  {layerList &&
                    layerList.map((layer, index) => (
                      <option value={layer._id} key={layer._id}>
                        {layer.layerType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              */}

              <FormGroup>
                <Label htmlFor="TargetTime">Target Time (hour)</Label>
                <TextInput
                  className="form-control"
                  name="targetTime"
                  type="text"
                  placeholder="Enter Target Time"
                  errorMessage={{ required: "Target Time Is Required" }}
                  disabled={isDisabled ? true : false}
                  value={targetTime}
                  onChange={(e) => setTargetTime(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="Priority">Priority</Label>
                <SelectGroup
                  type="select"
                  name="Priority"
                  onChange={(e) => setPriority(e.target.value)}
                  required
                  errorMessage={{ required: "Priority Is Required" }}
                  className="form-control priority"
                  selected={priority}
                  value={priority}
                >
                  <option value="">{"Select Priority"}</option>
                  <option value="Low">{"Low"}</option>
                  <option value="Medium">{"Medium"}</option>
                  <option value="High">{"High"}</option>
                  <option value="Critical">{"Critical"}</option>
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="waferURL">Folder URL</Label>
                <TextInput
                  value={folderUrl}
                  className="form-control"
                  required
                  disabled={isDisabled ? true : false}
                  errorMessage={{ required: "URL Is Required" }}
                  type="text"
                  name="waferURL"
                  placeholder="Enter Folder URL"
                  onChange={(e) => setFolderUrl(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {existPictureList && existPictureList.length > 0 && (
                <div className="card-sm border rounded mb-2">
                  <CardHeader inverse color="light" id="toggler">
                    Existing Images
                  </CardHeader>
                  <UncontrolledCollapse defaultOpen={true} toggler="#toggler">
                    <CardBody>
                      {existPictureList &&
                        existPictureList.length > 0 &&
                        existPictureList.map((pic, index) => (
                          <div key={pic._id} className="defect-check mb-2 ml-2">
                            <Row>
                              <Col lg="10">
                                <label className="pl-2" htmlFor={pic._id}>
                                  {pic.imageDescription}
                                </label>
                              </Col>
                              <Col lg="2" className="text-right">
                                <ImageWithModal
                                  title={pic.imageDescription}
                                  keyItem={pic.imageUrl}
                                  className="img-thumbnail defect-image"
                                  src={`${pic.imageUrl}`}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </CardBody>
                  </UncontrolledCollapse>
                </div>
              )}
              <div>
                <WaferAssetUpload
                  setPictureList={setPictureList}
                  isDisabled={isDisabled}
                  pictureList={pictureList}
                />
              </div>
              {pictureList && pictureList.length > 0 && (
                <div className="card-sm border rounded mb-2">
                  <CardHeader inverse color="light" id="newToggler">
                    Added Images
                  </CardHeader>
                  <UncontrolledCollapse
                    defaultOpen={true}
                    toggler="#newToggler"
                  >
                    <CardBody>
                      {pictureList &&
                        pictureList.length > 0 &&
                        pictureList.map((pic, index) => (
                          <div key={pic._id} className="defect-check mb-2 ml-2">
                            <Row>
                              <Col lg="10">
                                <label className="pl-2" htmlFor={pic._id}>
                                  {pic.imageDescription}
                                </label>
                              </Col>
                              <Col lg="2" className="text-right">
                                <ImageWithModal
                                  title={pic.imageDescription}
                                  keyItem={pic.imageUrl}
                                  className="img-thumbnail defect-image"
                                  src={`${pic.imageUrl}`}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </CardBody>
                  </UncontrolledCollapse>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="waferRemark">Remarks</Label>
                <TextInput
                  value={remarks}
                  className="form-control"
                  multiline
                  rows="3"
                  name="waferRemark"
                  disabled={isDisabled ? true : false}
                  placeholder="remarks"
                  errorMessage={{ required: "Remark Is Required" }}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </FormGroup>
              {/* <FormGroup>
                  <Label htmlFor="status-type">Status</Label>
                  <Input
                    type="select"
                    name="select"
                    onChange={(e) => setStatus(e.target.value)}
                    className="form-control digits"
                    selected="Select Status"
                  >
                    <option value="">{"Select Status"}</option>
                    {statusList &&
                      statusList.map((status, index) => (
                        <option key={status._id}>{status.statusType}</option>
                      ))}
                  </Input>
                </FormGroup> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                <Button className="success">
                  {edit ? `Update` : `Add`} Wafer
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default WaferPage;
