import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";
import {
  fetchActiveStatus,
  updateStatus,
  createNewStatus,
} from "../../../../api";
import {
  ValidationForm,
  FileInput,
  Checkbox,
  TextInput,
} from "react-bootstrap4-form-validation";
import { toast } from "react-toastify";
import $ from "jquery";
import DataTable from "datatables.net";
toast.configure();

const StatusMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [statusId, setStatusId] = useState([]);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [statusType, setStatusType] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [statusList, setStausList] = useState([]);
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const formRef = useRef(null);

  useEffect(() => {
    getStatusList();
  }, []);
  useEffect(() => {
    if (statusList.length > 0) {
      $("#statusTable").dataTable({
        columnDefs: [
          {
            targets: [3], // column index (start from 0)
            orderable: false, // set orderable false for selected columns
          },
        ],
      });
    }
  }, [statusList]);

  const getStatusList = () => {
    $("#statusTable").dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const status = fetchActiveStatus(data, tkn).then((res) => {
      if (res.data.success === true) {
        setStausList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const addStatus = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      statusType: statusType,
      isActive: isActive,
    });
    createNewStatus(body, tkn).then((res) => {
      if (res.data.success) {
        // console.log(res.data.data);
        getStatusList();
        clearValues();
        closeCustomizer();
        toast.success("Status details added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    });
  };

  const clearValues = () => {
    setLoading(false);
    setStatusType("");
    setIsActive(true);
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  };

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    clearValues();
    setIsActive(true);
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editStatus = (status) => {
    setEdit(true);
    setStatusId(status._id);
    setStatusType(status.statusType);
    setIsActive(status.isActive);
    openCustomizer();
  };

  const updateStatusProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      statusType: statusType,
      isActive: isActive,
    });
    updateStatus(statusId, body, tkn).then((res) => {
      if (res.data.success) {
        getStatusList();
        toast.success("Status details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        clearValues();
        setEdit(false);
        closeCustomizer();
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Status Master" />
      <Container className="master-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add
            </Button>
          </CardHeader>
          <CardBody>
            <Table
              id="statusTable"
              className="master-table"
              bordered
              responsive
            >
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  <th className="name-col">Status Type</th>
                  {/* <th className="name-col">Created On</th> */}
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {statusList.length>0?statusList.map((status, index) => (
                  <tr key={status._id}>
                    <td>{index + 1}</td>
                    <td>{status.statusType}</td>
                    {/* <td>
                      {moment(status.createAt).format("MM-DD-YYYY")}{" "}
                      {moment(status.createAt).format("hh:mm")}
                    </td> */}
                    <td data-order={status.isActive} className="text-center">
                      {status.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i
                        onClick={() => editStatus(status)}
                        className="icon fa fa-pencil-square"
                      ></i>
                    </td>
                  </tr>
                )):<tr>
                <td colSpan="12" className="text-center">
                  {" "}
                  No data found.{" "}
                </td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
              </tr>}
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Status Type</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateStatusProcess : addStatus}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="status-type">Status Type</Label>
                <TextInput
                  required
                  className="form-control"
                  type="text"
                  placeholder="Enter Status Type"
                  value={statusType}
                  name="status-type"
                  errorMessage={{ required: "Status Type Is Required" }}
                  onChange={(e) => setStatusType(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0">
                <Checkbox
                  name="status-isActive"
                  className="master-checkbox"
                  label="Active?"
                  id="status-isActive"
                  value={isActive}
                  onChange={(e) => setIsActive(!isActive)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update Status
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add Status
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default StatusMasterPage;
