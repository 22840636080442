import React, { Fragment, useEffect, useState } from "react";
import Chart from 'react-apexcharts'
import dayjs from "dayjs";
import { fetchChart } from "../../api";
import { Colors } from "../../constant/index";

const SVQCInspectionChart = (props) => {

    const [options, setOptions] = useState({});
    const [series, setSeries] = useState({});
    const [companyId, setCompanyId] = useState(
        localStorage.getItem('cid')
    );
    const [token, setToken] = useState(
        localStorage.getItem('token')
    );

    useEffect(() => {
        loadWaferInspectionChart();
    }, [])

    const loadWaferInspectionChart = () => {
        var tkn = `Bearer ${token}`;
        let data = `companyId=${companyId}`;
        fetchChart(data, tkn).then(res => {
            if (res.data && res.data.success === true) {
                if (res.data.result && res.data.result.length > 0) {

                    const reportData = res.data.result;
                    let opened = [], disposition = [], date = [];
                    let openedCount = 0, dispositionCount = 0;


                    let sortedWafer = reportData.sort((a, b) => new Date(b.createAt) - new Date(a.createAt))
                    let sortedWaferAscendingByDate = sortedWafer.length > 0 ? sortedWafer.reverse() : []

                    sortedWaferAscendingByDate && sortedWaferAscendingByDate.map(d => {
                        let day = d.day;
                        let month = d.month;
                        let year = d.year
                        const orderDate = dayjs(Date.UTC(year, (month - 1), day));
                        const dateTimeStamp = orderDate.valueOf()
                        var isDate = date.filter(x => x == dateTimeStamp);
                        if ((isDate && isDate.length == 0) || date && date.length == 0) {
                            date.push(dateTimeStamp)
                            let valueByDate = reportData.filter(x => x.day == day && x.month == month && x.year == year)
                            let openedData = valueByDate.filter(x => x.opened);
                            let dispositionData = valueByDate.filter(x => x.dispositionCount)
                            let openCount = openedData.length > 0 ? openedData[0].opened : 0;
                            let dispositionCount = dispositionData.length > 0 ? dispositionData[0].dispositionCount : 0;
                            opened.push(openCount);
                            disposition.push(dispositionCount);
                        }
                    })

                    openedCount = opened.reduce((total, data) => total = total + data, 0)
                    dispositionCount = disposition.reduce((total, data) => total = total + data, 0)

                    let seriesData = [
                        {
                            name: "Opened: " + openedCount,
                            data: opened
                        },
                        {
                            name: " Disposition: " + dispositionCount,
                            data: disposition
                        },
                    ]

                    let optionsData = {
                        chart: {
                            id: 'WaferInspectionChart',
                            type: "bar",
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        grid: {
                            row: {
                                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                opacity: 0.5
                            },
                        },
                        stroke: {
                            show: true,
                            width: 2
                        },
                        colors: [Colors.open, Colors.disposition],
                        xaxis: {
                            type: "category",
                            categories: date,
                            labels: {
                                hideOverlappingLabels: true,
                                format: undefined,
                                formatter: function (val) {
                                    return dayjs(val).format("MMM D 'YY")
                                }
                            },
                            tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                            tickPlacement: 'on',
                        },
                        yaxis: {
                            labels: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        },
                        legend: {
                            position: 'top',
                            itemMargin: {
                                vertical: 10,
                            }
                        },
                        fill: {
                            opacity: 1
                        },
                        tooltip: {
                            x: {
                                format: 'MMM d \'yy',
                            },
                            y: {
                                formatter: function (val) {
                                    return ": " + val
                                },
                                title: {
                                    formatter: function (value) {
                                        return value.split(":")[0]
                                    },
                                },
                            }
                        },
                    };

                    setSeries(seriesData)
                    setOptions(optionsData)
                }
            }
        }).catch(err => console.log(err.message))
    }

    return (
        <div>
            {
                series && series.length > 0 &&
                <Chart options={options} series={series} type="bar" width="100%" height={400} />
            }
        </div>
    );
};

export default SVQCInspectionChart;
