import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  ValidationForm,
  FileInput,
  TextInput,
  Checkbox,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import moment from "moment";
import { fetchActiveDesign, fetchActiveStage, createNewStage, fetchActiveLMLayer, updateStageDetail, fetchActiveLMArea } from "../../../../../api";
import $ from "jquery";
import DataTable from "datatables.net";
import { toast } from 'react-toastify'
toast.configure();

const StageMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [areaType, setAreaType] = useState("");
  const [designType, setDesignType] = useState("");
  const [stageType, setStageType] = useState("");
  const [layerType, setLayerType] = useState("");
  const [stageId, setStageId] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [filterDesignList, setFilterDesignList] = useState([]);
  const [filterAreaType, setFilterAreaType] = useState([]);
  const [filterDesignType, setFilterDesignType] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [layerList, setLayerList] = useState([]);
  const [filterLayerList, setFilterLayerList] = useState([]);
  const [filterLayerType, setFilterLayerType] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [designList, setDesignList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );
  const formRef = useRef(null);
  const filterFormRef = useRef(null);

  useEffect(() => {
    getDesignList(true);
    getStageList();
    getDesignList();
  }, []);

 

  const getLayerList = (design, filter) => {
    setLayerList([])
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&design=${design}`;
    const status = fetchActiveLMLayer(data, tkn).then((res) => {
      if (res.data.success === true) {
        if (filter === true) {
          setFilterLayerList(res.data.data)
        }
        else {
          setLayerList(res.data.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };


  const getDesignList = (filter) => {
    setDesignList([])
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const status = fetchActiveDesign(data, tkn).then((res) => {
      if (res.data.success === true) {
        if (filter === true) {
          setFilterDesignList(res.data.data)
        }
        else {
          setDesignList(res.data.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };


  const getStageList = () => {
    clearFilter()
    $('#layerTable').dataTable().fnDestroy();
    setStageList([]);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const stage = fetchActiveStage(data, tkn).then((res) => {
      if (res.data.success === true) {
        setStageList(res.data.data); setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (stageList.length > 0) {
      $("#layerTable").dataTable({
        'columnDefs': [{
          'targets': [4], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }]
      });
    }
  }, [stageList]);

  const addStage = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      design: designType,
      layer: layerType,
      stageType: stageType,
      isActive: isActive,
    });
    createNewStage(body, tkn).then((res) => {
      //   // console.log(res.data.data);
      //   clearValues();
      //   toast.success("Stage details added successfully.", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      //   });
      // });
      if (res.data.success) {
        clearValues();
        toast.success("Stage details added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    });
  }

  const clearValues = () => {
    getStageList();
    setLoading(false);
    setIsActive(true);
    setDesignType('');
    setDesignList([]);
    setLayerList([]);
    setLayerType('');
    setStageType('');
    closeCustomizer();
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setEdit(false);
    setIsActive(true);
    setDesignType('');
    setDesignList([]);
    setLayerList([]);
    setLayerType('');
    setStageType('');
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editStage = (stage) => {
    setEdit(true);
    setStageId(stage._id);
    setStageType(stage.stageType)
    getDesignList();

    if (stage.design) {
      setDesignType(stage.design._id)
      getLayerList(stage.design._id);
    }
    if (stage.layer) {
      setLayerType(stage.layer._id);
    }
    setIsActive(stage.isActive);
    openCustomizer();
  };

  const updateStageProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      design: designType,
      layer: layerType,
      stageType: stageType,
      isActive: isActive,
    });
    updateStageDetail(stageId, body, tkn).then((res) => {

      if (res.data.success) {
        setLoading(false);
        clearValues();
        toast.success("Stage details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });

      }
      else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    }).catch(function (response) {
      //handle error
      clearValues();
      console.log(response);
    });
  };

  // const onAreaSelect = (e) => {
  //   getDesignList(e.target.value);
  //   setAreaType(e.target.value);
  //   setDesignType("");
  // };

  const onDesignSelect = (e) => {
    setDesignType(e.target.value);
    getLayerList(e.target.value);
    setLayerType("");
  };
  const onLayerSelect = (e) => {
    setLayerType(e.target.value);
  };

  const loadStageByFilter = (event) => {

    event.preventDefault();

    var table = $("#layerTable").DataTable();
  
    if (filterDesignType.value !== "") {
      table.columns(1).search(filterDesignType.text).draw();
    }
    else {
      table
        .search('')
        .columns(2).search('')
        .draw();
    }
    // if (filterLayerType.value !== "") {
    //   table.columns(2).search(filterLayerType.text).draw();
    // }
    // else {
    //   table
    //     .search('')
    //     .columns(2).search('')
    //     .draw();
    // }
  };

  // const onFilterAreaSelect = (e) => {
  //   getDesignList(e.target.value, true);
  //   setFilterDesignList([]);
  //   setFilterLayerList([])
  //   setFilterDesignType({ value: "", text: "" });
  //   setFilterLayerType({ value: "", text: "" });
  //   setFilterAreaType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  // };

  const onFilterDesignSelect = (e) => {
    getLayerList(e.target.value, true);
    setFilterLayerList([])
    setFilterLayerType({ value: "", text: "" });
    setFilterDesignType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  };
  const onFilterLayerTypeSelect = (e) => {
    setFilterLayerType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  };

  const clearFilter = (e) => {
    var table = $("#layerTable").DataTable();
    setFilterDesignType({ value: "", text: "" });
    setFilterLayerType({ value: "", text: "" });
    setFilterDesignList([]);
    setFilterLayerList([]);
    table
      .search('')
      .columns().search('')
      .draw();
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Stage Master" />
      <Container className="master-container layer-conatiner" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add Stage
            </Button>
          </CardHeader>
        </Card>
        <Card>
        {stageList.length > 0 &&( <CardHeader>
            <ValidationForm onSubmit={loadStageByFilter} ref={filterFormRef}>
              <Row>
                {/* <Col lg="3" className="text-left">
                  <Label>Select Area</Label>
                  <SelectGroup
                    type="select"
                    name="waferArea"
                    value={filterAreaType.value}
                    errorMessage={{ required: "Area Is Required" }}
                    onChange={(e) => onFilterAreaSelect(e)}
                    className="form-control digits"
                    selected={filterAreaType.value}
                  >
                    <option value="">{"All"}</option>
                    {areaList &&
                      areaList.map((area, index) => (
                        <option text={area.areaType} value={area._id} key={area._id}>
                          {area.areaType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col> */}
                <Col lg="4" className="text-left">
                  <Label htmlFor="filterDesignType">Select Design</Label>
                  <SelectGroup
                    type="select"
                    name="filterDesignType"
                    onChange={(e) => onFilterDesignSelect(e)}
                    errorMessage={{ required: "Design Required" }}
                    className="form-control digits"
                    selected={filterDesignType.value}
                    value={filterDesignType.value}
                  >
                    <option value="">{"All"}</option>
                    {filterDesignList &&
                      filterDesignList.map((design, index) => (
                        <option value={design._id} key={design._id}>
                          {design.designType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col>
                {/* <Col lg="4" className="text-left">
                  <Label htmlFor="filterLayerType">Layer Type</Label>
                  <SelectGroup
                    type="select"
                    name="filterLayerType"
                    value={filterLayerType.value}
                    errorMessage={{ required: "Layer Type Is Required" }}
                    onChange={(e) => onFilterLayerTypeSelect(e)}
                    className="form-control digits"
                    selected="Select Layer"
                  >
                    <option value="">{"All"}</option>
                    {filterLayerList &&
                      filterLayerList.map((layer, index) => (
                        <option value={layer._id} key={layer._id}>
                          {layer.layerType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col> */}
                <Col lg="8" className="text-left">
                  <Button title="Add Filter" className="filter-btn" color="success" size="md">
                    <i className="fa fa-filter"></i>
                  </Button>
                  <Button title="Clear Filter" onClick={clearFilter} className="filter-btn ml-2" color="danger" size="md">
                    <i className="fa fa-close"></i>
                  </Button>
                </Col>
              </Row>
            </ValidationForm>
          </CardHeader>)}


          <CardBody>
            <Table id="layerTable"
              className="layer-table"
              bordered
              responsive>
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  {/* <th className="name-col">Layer</th> */}
                  <th className="name-col">Stage</th>
                  <th className="name-col">Design</th>

                  {/* <th className="name-col">Created On</th> */}
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {stageList.length > 0?stageList.map((stage, index) => (
                  <tr key={stage._id}>
                    <td>{index + 1}</td>
                    {/* <td>{stage.layer.layerType}</td> */}
                    <td>{stage.stageType}</td>
                    <td>{stage.design.designType}</td>
                  
                    <td data-order={stage.isActive} className="text-center">
                      {stage.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i
                        onClick={() => editStage(stage)}
                        className="icon fa fa-pencil-square"
                      ></i>
                    </td>
                  </tr>
                )):
                <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    {/* <td className="d-none"></td> */}
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                  </tr>
                }
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Stage Type</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateStageProcess : addStage}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              {/* <FormGroup>
                <Label htmlFor="waferArea">Area</Label>
                <SelectGroup
                  type="select"
                  name="waferArea"
                  required
                  value={areaType}
                  errorMessage={{ required: "Area Is Required" }}
                  onChange={(e) => onAreaSelect(e)}
                  className="form-control digits"
                  selected="Select Area"
                >
                  <option value="">{"Select Area"}</option>
                  {areaList &&
                    areaList.map((area, index) => (
                      <option value={area._id} key={area._id}>
                        {area.areaType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup> */}
              <FormGroup>
                <Label htmlFor="waferDesign">Part ID</Label>
                <SelectGroup
                  type="select"
                  name="waferDesign"
                  onChange={(e) => onDesignSelect(e)}
                  required
                  errorMessage={{ required: "Part ID Required" }}
                  className="form-control digits"
                  selected={designType}
                  value={designType}
                >
                  <option value="">{"Select Part ID"}</option>
                  {designList &&
                    designList.map((design, index) => (
                      <option value={design._id} key={design._id}>
                        {design.designType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              {/* <FormGroup>
                <Label htmlFor="layerType">Layer Type</Label>
                <SelectGroup
                  type="select"
                  name="layerType"
                  required
                  value={layerType}
                  errorMessage={{ required: "Layer Type Is Required" }}
                  onChange={(e) => onLayerSelect(e)}
                  className="form-control digits"
                  selected="Select Layer"
                >
                  <option value="">{"Select Layer Type"}</option>
                  {layerList &&
                    layerList.map((layer, index) => (
                      <option value={layer._id} key={layer._id}>
                        {layer.layerType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup> */}
              <FormGroup>
                <Label htmlFor="stage-type">Stage Type</Label>
                <TextInput
                  required
                  className="form-control"
                  type="text"
                  name="stage-type"
                  errorMessage={{ required: "Stage Type Is Required" }}
                  placeholder="Enter Stage Type"
                  value={stageType}
                  onChange={(e) => setStageType(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0">
                <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={e => setIsActive(!isActive)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update Stage Type
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add Stage Type
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default StageMasterPage;
