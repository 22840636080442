import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  NavLink ,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";
import {
  ValidationForm,
  TextInput,
  FileInput,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import validator from "validator";

function WaferAssetUpload({ setPictureList, isDisabled, pictureList,setEditImage,editImage }) {
  const formRef = useRef(null);
  const [pictureName, setPictureName] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");
  const [pictureDescription, setpictureDescription] = useState("");

  const addWaferImage = (event) => {
    event.preventDefault();
    let existList = pictureList.concat({
      _id:  pictureName,
      imageUrl: pictureUrl,
      imageDescription:pictureDescription
    });
    setPictureList(existList);

    setPictureName("");
    setPictureUrl("");
    setpictureDescription("");
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }
  useEffect(() => {
    setPictureName("");
    setPictureUrl("");
    setpictureDescription("");
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }, [pictureList]);

  const addPictureToWafers = (e) => {

 
    if (e.target.files[0]) {
      setPictureName( e.target.files[0]);
      setPictureUrl(URL.createObjectURL(e.target.files[0]));
   }

  };
 

  return (
    <>
    <ValidationForm
      ref={formRef}
      className="theme-form"
    >
      <Label htmlFor="images">
      Additional Defect Library Specific to this Lot
      </Label>
      <FormGroup>
        <FileInput
          className="form-control"
          required
          type="file"
          disabled={isDisabled ? true : false}
          placeholder="defect image"
          onChange={(e) => addPictureToWafers(e)}
        />
      </FormGroup>
      <FormGroup className="mb-1">
        <TextInput
          value={pictureDescription}
          className="form-control"
          type="text"
          required
          disabled={isDisabled ? true : false}
          name="pictureDescription"
          errorMessage={{ required: "Defect Description Is Required" }}
          placeholder="Defect Description"
          onChange={(e) => setpictureDescription(e.target.value)}
        />
      </FormGroup>
      <FormGroup className="mb-1">
      <div style={{cursor:pictureName&&pictureUrl&&pictureDescription?"pointer":"not-allowed"}} title={pictureName&&pictureUrl&&pictureDescription?"Add":"Please Upload Image And Enter Description"}><NavLink   disabled={pictureName&&pictureUrl&&pictureDescription?false:true} onClick={addWaferImage} className="text-right pr-0 text-primary"> <u>Add Defect Image</u></NavLink  ></div>
     </FormGroup>
    </ValidationForm>
    </>
  );
}

export default WaferAssetUpload;
