export const Api_url = "/api";

export const Colors = {
  open: "#09a4e0",
  onreview: "#f8d62b",
  reviewCompleted: "#51bb25",
  disposition: "#fb740d",
  archive: "#f10542",

  rejected: "#f10542",
  furtherReview: "#f8d62b",
  acceptable: "#51bb25",
};

//Chart Title
export const WaferDailyChartTitle = "Wafer Status - Today";
export const WaferLast30DaysChartTitle =
  "Wafer Inspection Status - Last 30 Days";

export const WaferWeeklyChartTitle = "Wafer Status - Weekly";

export const ReviewChartTitle= "Review Status - Hours";
