import React, { useEffect, useState } from "react";
import moment from "moment";
import { TablePriorityStyle, GetStatusColor } from "../../../helpers";
import { Colors } from "../../../constant/index";
import { SecondsToDHMS } from "../../../helpers";

function WaferTable({ wafer, index, editWafer, deleteWaferItem }) {
  const [isExpandable, setIsExpandable] = useState(false);
  const [isDisExpandable, setIsDisExpandable] = useState(false);
  const [waferItem, setWaferItem] = useState(null);

  const expandable = () => {
    if (isExpandable) setIsDisExpandable(false);
    setIsExpandable(!isExpandable);
  };

  const disExpandable = () => {
    setIsDisExpandable(!isDisExpandable);
  };


  useEffect(() => {
    // setWaferItem(wafer);
    // console.log(wafer);
  }, []);

  const waferStatusBadger = (status) => {
    // status = status.toLower;
    if (status === "Open") {
      return "badge badge-danger";
    } else if (status === "On Review") {
      return "badge badge-warning";
    } else {
      return "badge badge-success";
    }
  };

  const waferPriorityBadger = (priority) => {
    // status = status.toLower;
    if (priority === "Critical") {
      return "badge badge-danger";
    }
    else if (priority === "High") {
      return "badge badge-info";
    } else if (priority === "Medium") {
      return "badge badge-warning";
    } else {
      return "";
    }
  };

  return [
    <tr key={wafer.lot}>
      <td style={{ borderLeft: TablePriorityStyle(wafer.highPriority) }} className="text-center">
        <span className="text-primary hand-cursor" onClick={() => expandable()}>
          {index + 1}  {!isExpandable ? <i className={`fa fa-angle-down`}></i> : <i className={`fa fa-angle-up`}></i>}
        </span>
      </td>
      {/* <td >
       
        </td> */}

      <td className="text-center">
        <span className={waferPriorityBadger(wafer.highPriority)}>
          {wafer.lot}
        </span>
      </td>
      <td className="text-center">{wafer.targetTime}</td>

      <td style={{ width: "175px" }} className="text-center">
        {/* <span className={waferStatusBadger(wafer.status)}>{wafer.status}</span>
         */}

        <span
          style={{ backgroundColor: wafer.status === "Open" ? Colors.open : Colors.onreview }}
          className="badge badge-danger"
        >
          {" "}
          {wafer.status}
        </span>
        {/* {
                          wafer.review.reviewData != null &&
                          <span  className="badge">(Reviewed - {wafer.review.reviewData.length})</span>
                        } */}
      </td>

      <td>{moment(wafer.createAt).format("MM-DD-YYYY HH:mm")}</td>
      <td>{wafer.user.name}</td>
      <td>{wafer.remarks}</td>
      <td className="text-center">
        <i
          onClick={() => editWafer(wafer)}
          className="icon fa fa-pencil-square"
        ></i>
         {/* <i
          onClick={() => deleteWaferItem(wafer)}
          className="icon fa fa-trash"
        ></i> */}
      </td>
    </tr>,
    isExpandable && (
      <tr key={wafer._id + '1'}>
        <td colSpan="8">
          <table>
            <thead>
              <tr>
                <th>Reviewed At</th>
                <th>Reviewed count</th>
                <th>Reviewed Time</th>
                <th>Area</th>
                <th>Layer</th>
                <th>Step</th>
                <th>Folder Url</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{wafer.reviewStartDate !== undefined ? moment(wafer.reviewStartDate).format("MM-DD-YYYY HH:mm") : "-"}</td>
                <td>{wafer.inspectionData.length > 0 ? wafer.inspectionData.reduce((total, data) => total + data.reviewCountByUser, 0) : "-"}</td>
                <td>{wafer.inspectionData.length > 0 ? SecondsToDHMS(wafer.inspectionData.reduce((total, data) => total + data.reviewTimeByUser, 0)) : "-"}</td>
                <td>{wafer.area !== null ? wafer.area.areaType : ""}</td>
                <td>{wafer.variable !== null ? wafer.variable.variableType : ""}</td>
                <td>{wafer.product !== null ? wafer.product.productType : ""}</td>
                <td className='url-col'>
                  <a href={wafer.folderUrl} target="_blank"> {wafer.folderUrl} </a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    )
  ];
}

export default WaferTable;
