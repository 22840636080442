import axios from "axios";
import { Api_url } from "../constant";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

//dash
export const fetchDash = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/dash?${data}`, config);
};

export const fetchChart = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/dash/chart?${data}`, [], config);
};

export const fetchDailyChart = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/dash/dailyChart?${data}`, [], config);
};

export const fetchWeeklyChart = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/dash/weeklyChart?${data}`, [], config);
};


// Add Area

export const createNewArea = (status, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/area`, status, config);
};

//  Get Area by Company
export const fetchActiveArea = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/area?${data}`, config);
};
//  Update user for company
export const UpdateInspector = (userId, user, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/users/edit/account`, user, config);
};

export const updateUser = (userId, user, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/users/edit/${userId}`, user, config);
};

export const updateArea = (areaId, area, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/area/edit/${areaId}`, area, config);
};
// Add Variable

export const createNewVariable = (variable, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/variable`, variable, config);
};
export const updateVariableDetail = (variableId, variables, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/variable/edit/${variableId}`, variables, config);
};

//  Get Variable by Company
export const fetchActiveVariable = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/variable?${data}`, config);
};

//  Get Product by Company and Variable
export const fetchActiveProduct = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/product?${data}`, config);
};
//  Get Layer by Company and Product
export const fetchActiveLayer = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/layer?${data}`, config);
};
// Add Status
export const createNewStatus = (status, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/status`, status, config);
};

//  Get Status by Company
export const fetchActiveStatus = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/status?${data}`, config);
};

//  Update Status Type by Company
export const updateStatus = (statusId, status, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/status/edit/${statusId}`, status, config);
};

//  Create new Defect Type by Company
export const createNewDefect = (defects, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/defects`, defects, config);
};

//  Update Defect Type by Company
export const updateDefect = (defectId, defects, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/defects/${defectId}`, defects, config);
};

// Get Defects by Company
export const fetchActiveDefetcts = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/defects?${data}`, config);
};

//Add Defect Images
export const addDefectImage = (defectId, token, formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  };
  return axios.put(`${Api_url}/defects/${defectId}/image`, formData, config);
};

//  Create New Inspectors for a Company
export const createNewInspector = (body, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/auth/register`, body, config);
};

//  Get All Inspectors by Company
export const getUsers = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/users?${data}`, config);
};

//  Get All Inspectors by Company
export const getInspectors = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/users?${data}`, config);
};


export const getReviewCountByHours = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/report/getReviewsCount?${data}`, config);
};


//  Login Inspectors by Company
export const loginInspectors = (users) => {
  return axios.post(`${Api_url}/auth/login`, users, config);
};

// Login for Company Admin
export const logInAdmin = (admin) => {
  return axios.post(`${Api_url}/auth/admin`, admin, config);
};

//  Add Wafer
export const addWafer = (wafer, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/`, wafer, config);
};

//remove review from the review list

export const deleteReview = (review, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/remove/review`, review, config);
};

//  Get All Wafers by Company and not Disposed

export const getWafers = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/wafer/getWafer?${data}`, [], config);
};

export const getWaferswithCount = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/wafer/wafer-count?${data}`, [], config);
};
//  Get All images for the Wafer by Company

export const getwaferInxpectionImages = (waferId, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/wafer/${waferId}/imagelist`, [], config);
};

export const updateReviewDataWithAnnotation = (waferId, token, formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  };
  return axios.put(
    `${Api_url}/wafer/${waferId}/update/review`,
    formData,
    config
  );
};

export const updateWafer = (wafer, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/updateExistingWafer`, wafer, config);
};

export const deleteWafer = (wafer, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/remove-wafer`, wafer, config);
};

//  Add Review based on wafer
export const updateReview = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/update?${data}`, [], config);
};

export const updateDisposition = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/update?${data}`, [], config);
};

// to pass disposition remarks for the each review items
export const updateDispositionRemarks = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/update?${data}`, [], config);
};

// to pass disposition remarks for the each review items
export const updateDispositionToArchive = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/wafer/update?${data}`, [], config);
};

// Get review items by wafer
export const getReviewsByWafer = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/review/all?${data}`, [], config);
};

//Add Review based on wafer with Image
export const createReviewDataWithImage = (waferId, token, formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  };
  return axios.put(
    `${Api_url}/wafer/${waferId}/annotationImage`,
    formData,
    config
  );
};

// Add Wafer Images if Available
export const addWaferImages = (waferId, token, formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  };
  return axios.put(`${Api_url}/wafer/${waferId}/waferImage`, formData, config);
};

// PRODUCT
export const createNewProduct = (product, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/product`, product, config);
};

// export const fetchActiveProduct = (data, token) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//   };
//   return axios.get(`${Api_url}/product?${data}`, config);
// };

export const updateProductDetail = (productId, product, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/product/edit/${productId}`, product, config);
};
//Layer

export const createNewLayer = (layer, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/layer`, layer, config);
};

// export const fetchActiveLayer = (data, token) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//   };
//   return axios.get(`${Api_url}/layer?${data}`, config);
// };

export const updateLayerDetail = (layerId, layer, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/layer/edit/${layerId}`, layer, config);
};

/**WAFER REPORTS */

export const fetchArchive = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/report/archive?${data}`, [], config);
};

export const fetchInxpector = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/report/inspectionByUser?${data}`,[], config);
  console.log(Api_url);
};

export const fetchInxpectorByDate = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/report/inspectionByCompany?${data}`, [], config);
};

export const fetchReviewed = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/report/reviewed?${data}`, [], config);
};

//Livermore Masters

// Add LMArea

export const createNewLMArea = (status, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/lm-area`, status, config);
};

//  Get LMArea by Company
export const fetchActiveLMArea = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/lm-area?${data}`, config);
};

export const updateLMArea = (areaId, area, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/lm-area/edit/${areaId}`, area, config);
};

// Add Design

export const createNewDesign = (design, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
   console.log(design);
  return axios.post(`${Api_url}/design`, design, config);
};
export const updateDesignDetail = (designId, designs, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/design/edit/${designId}`, designs, config);
};

//  Get Design by Company
export const fetchActiveDesign = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/design?${data}`, config);
};

//  Get LM Layer by Company and Product
export const fetchActiveLMLayer = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/lm-layer?${data}`, config);
};

export const createNewLMLayer = (lmlayer, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/lm-layer`, lmlayer, config);
};


export const updateLMLayerDetail = (layerId, lmlayer, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/lm-layer/edit/${layerId}`, lmlayer, config);
};


export const createNewStage = (stage, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.post(`${Api_url}/stage`, stage, config);
};

//  Get Layer by Company and Product
export const fetchActiveStage = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/stage?${data}`, config);
};

export const updateStageDetail = (stageid, stage, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  // console.log(defects);
  return axios.put(`${Api_url}/stage/edit/${stageid}`, stage, config);
};

//  Get All LM Lots by Company and not Disposed

export const getLMLots = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/lot/getLot?${data}`, [], config);
};

//  Add Wafer
export const addLot = (lot, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/lot/`, lot, config);
};

export const updateLotStatus = (lot, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/lot/update-status`, lot, config);
};

export const updateLot = (lot, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.post(`${Api_url}/lot/update-lot`, lot, config);
};

export const fetchCompletedLotsByDate = (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return axios.get(`${Api_url}/lot/lot-completed?${data}`, [], config);
};