import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { TablePriorityStyle, GetStatusColor } from "../../../helpers";

function DispositionTable({ wafer, index }) {
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isExpandable, setIsExpandable] = useState(false);
  const [isDisExpandable, setIsDisExpandable] = useState(false);
  const [waferData, setWaferData] = useState(null);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  // const [waferItem, setWaferItem] = useState(null);

  const history = useHistory();

  const expandable = () => {
    if (isExpandable) setIsDisExpandable(false);
    setIsExpandable(!isExpandable);
  };

  const disExpandable = () => {
    setIsDisExpandable(!isDisExpandable);
  };

  const onSelectWafer = (wafer) => {
    setWaferData(wafer);
    setModal(!modal);
    //
  };

  useEffect(() => {
    // setWaferItem(wafer);
    // console.log(wafer);
  }, []);

  const waferStatusBadger = (dispositionInprocess) => {
    // status = status.toLower;
    if (dispositionInprocess === false) {
      return "badge badge-success";
    } else if (dispositionInprocess === true) {
      return "badge badge-warning";
    }
    // else {
    //   return "badge badge-success";
    // }
  };

  const goToDispositionDetail = () => {
    setModal(!modal);
    history.push(`/disposition-inspect/${wafer._id}`, { waferId: wafer._id, wafer: wafer });
  };

  const timeDifference = (fromDate, toDate) => {
    const updatingTime = moment(toDate) - moment(fromDate);
    const secs = String("0" + (Math.floor(updatingTime / 1000) % 60)).slice(-2);
    const mins = String("0" + (Math.floor(updatingTime / 1000 / 60) % 60)).slice(-2);
    // var hours = String("0" + Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
    const hrs = String("0" + Math.floor((updatingTime / (1000 * 60 * 60)) % 24)).slice(
      -2
    );
    const days = String("0" + Math.floor(updatingTime / (1000 * 60 * 60 * 24))).slice(
      -1
    );

    if (days > 0) {
      return `${days} D:${hrs} H:${mins} M: ${secs} S`;
    } else {
      return `${hrs} H:${mins} M:${secs} S`;
    }
  }
  const waferPriorityBadger = (priority) => {
    // status = status.toLower;
    if (priority === "Critical") {
      return "badge badge-danger";
    }
    else if (priority === "High") {
      return "badge badge-info";
    } else if (priority === "Medium") {
      return "badge badge-warning";
    } else {
      return "";
    }
  };
  return [

    <tr key={wafer._id}>
      <td style={{ borderLeft: TablePriorityStyle(wafer.highPriority) }} className="text-center">
        <span className="text-primary hand-cursor" onClick={() => expandable()}>
          {index + 1}  {!isExpandable ? <i className={`fa fa-angle-down`}></i> : <i className={`fa fa-angle-up`}></i>}
        </span>
      </td>
      <td className="text-center mouse-hand" onClick={() => onSelectWafer(wafer)}>
        <span className={waferPriorityBadger(wafer.highPriority)} >
          {wafer.lot}
        </span>
        {/* <span className={waferPriorityBadger(wafer.highPriority)}>
          {wafer.lot}
        </span> */}
      </td>
      {/* <td className="text-center">
       {wafer.review.reviewData != null && 
       <span className="badge badge-info">
          {wafer.review.reviewData.length}
          </span>
        }
      </td> */}
      <td className="text-center">
        <span className={waferStatusBadger(wafer.dispositionInprocess)}>
          {wafer.reviewCompleted === true ? wafer.dispositionInprocess === true ? "Disposition Started" : "Review Submitted" : "Under Review"}
        </span>
        {/* <a className={waferStatusBadger(wafer.dispositionInprocess)} onClick={() => onSelectWafer(wafer)}>
          {wafer.reviewCompleted === true ? wafer.dispositionInprocess === true ? "Disposition Started" : "Review Submitted" : "Under Review"}
        </a> */}
      </td>

      <td>{wafer.area._id != null ? wafer.area.areaType : "-"}</td>
      <td>{moment(wafer.createAt).format("MM-DD-YYYY HH:mm")}</td>
      <td>{wafer.user.name}</td>
      <td>{wafer.remarks}</td>

    </tr>,
    isExpandable && (
      <tr key={wafer._id + '1'}>
        <td colSpan="8">
          <table>
            <thead>
              <tr>
                <th>Area</th>
                <th>Assy. No.</th>
                <th>Folder Url</th>
                <th className="date-col">Review Start Time</th>
                <th className="date-col">Review Completed Time</th>
                <th className="lot-col">Total Inspection Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{wafer.area.areaType}</td>
                <td>{wafer.variable.variableType}</td>
                <td className='url-col'>
                  <a href={wafer.folderUrl} target="_blank" rel="noopener noreferrer"> {wafer.folderUrl} </a>
                </td>
                <td>{moment(wafer.reviewStartDate).format("MM-DD-YYYY HH:mm")}</td>
                <td>{moment(wafer.reviewCompleteDate).format("MM-DD-YYYY HH:mm")}</td>
                <td>

                  {timeDifference(moment(wafer.reviewStartDate).format("MM-DD-YYYY HH:mm"), moment(wafer.reviewCompleteDate).format("MM-DD-YYYY HH:mm"))}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    ),
    
    < Modal isOpen={modal} toggle={toggleModal} size="sm" >
      <ModalBody>Are you Sure want to process this wafer?</ModalBody>
      <ModalFooter>
        <Button onClick={toggleModal} className="btn btn-secondary">
          Close
      </Button>
        <button onClick={goToDispositionDetail} className="btn btn-success">
          Continue
      </button>
      </ModalFooter>
    </Modal >
  ];
}

export default DispositionTable;
