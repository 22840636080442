import React,{useState,useRef,useEffect} from 'react';
// import man from '../assets/images/dashboard/profile.jpg';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,NavItem, NavLink, Nav,TabContent,TabPane} from 'reactstrap'
// import { Jwt_token } from '../data/config'
// import { handleResponse } from '../services/fack.backend.jsx'
// import { toast } from 'react-toastify';
import {withRouter} from 'react-router-dom'
import { logInAdmin } from '../api'
import { toast } from 'react-toastify'
import { ValidationForm ,TextInput} from 'react-bootstrap4-form-validation';
import validator from 'validator';
toast.configure();

const Logins = (props) => {
  
    // const {loginWithRedirect} = useAuth0()
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selected, setSelected] = useState("firebase");
    const [togglePassword,setTogglePassword] = useState(false)
    const formRef = useRef(null);

    const [value, setValue] = useState(
        // localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
        localStorage.getItem('Name')
    );

    const [userType, setUserType] = useState(
      localStorage.getItem('UserType')
  );

    useEffect(() => {
      
    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
    
    localStorage.setItem('UserType', userType);
    }, [value,name, userType]);
  
    const loginWithJwt = (event) => {
      event.preventDefault();
      setLoading(true);
      let admin= JSON.stringify({ email, password});
      logInAdmin(admin).then((res) => {
        if (res.data.success) {
          const user = res.data.user;
          setName(user.name);
          // console.log(user.company._id);
          setUserType(user.role.toUpperCase());
          localStorage.setItem('cid', user.company._id);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", user.role);
          localStorage.setItem("authenticated", true);
          localStorage.setItem("currentUser", JSON.stringify(user));
          clearValues();
          // localStorage.setItem("currentUser", JSON.stringify(user));
          window.location.href = `${process.env.PUBLIC_URL}/admin/dash`
        }else{
          toast.error("Error logging in.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          clearValues();
        }
      });
      // if (email === 'admin@gmail.com' && password === 'test123' ) {
      //   // setValue(man);
      //   setName("Admin");
      //   setUserType("Company Admin");
      //   localStorage.setItem('token', user);
      //   localStorage.setItem('authenticated', true);
      //   localStorage.setItem('role', 2);
      //   window.location.href = `${process.env.PUBLIC_URL}/admin/dash`
      //   return user;
      // }else{
        
      //   console.log("error");
      //   toast.error("Invalid credentials !", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 10000
      //     });
      // }

      // return fetch('/users/authenticate', requestOptions)
      // .then(handleResponse)
      // .then(user => {
      //   // store user details and jwt token in local storage to keep user logged in between page refreshes
      //   setValue(man);
      //   setName("Vinoth Kumar");
      //   localStorage.setItem('token', user);
      //   localStorage.setItem('authenticated', true);
      //   window.location.href = `${process.env.PUBLIC_URL}/dashboard`
      //   return user;
      // });
    };
    const clearValues = () => {
      setLoading(false);
    
      // let curentRormRef = formRef.current;
      // curentRormRef.resetValidationState(true);
    };


    return (
        <Container fluid={true} className="p-0">
        <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light" src={require("../assets/images/logo/login.png")} alt=""/>
                  <img className="img-fluid for-dark" src={require("../assets/images/logo/logo_dark.png")} alt=""/>
                </a>
              </div>
              <div className="login-main login-tab"> 
                {/* <Nav className="border-tab flex-column" tabs>
                  <NavItem>
                    <NavLink className={selected === 'firebase' ? 'active' : ''} onClick={() => setSelected('firebase')}>
                      <img src={require("../assets/images/firebase.svg")} alt="" />
                      <span>{FIREBASE}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={selected === 'jwt' ? 'active' : ''} onClick={() => setSelected('jwt')}>
                    <img src={require("../assets/images/jwt.svg")} alt="" />
                    <span>{JWT}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={selected === 'auth0' ? 'active' : ''} onClick={() => setSelected('auth0')}>
                    <img src={require("../assets/images/auth0.svg")} alt="" />
                    <span>{AUTH0}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={selected} className="content-login">
                  <TabPane  className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}> */}
                    <ValidationForm className="theme-form" onSubmit={loginWithJwt}  ref={formRef}>
                      <h4>Sign In as Company</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <FormGroup>
                        <Label htmlFor="signinEmail" className="col-form-label">{"Email Address"}</Label>
                        <TextInput 
                        required 
                        name="signinEmail"
                        className="form-control" 
                        type="email"  
                        validator={validator.isEmail}
                        errorMessage={{required:"Email Is Required",validator: "Invalid Email"}}
                        onChange={e => setEmail(e.target.value)} 
                        placeholder="email id" 
                        defaultValue={email} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="signinPassword" className="col-form-label">{"Password"}</Label>
                        <TextInput 
                       // required 
                        name="signinPassword"
                        className="form-control" 
                        type={togglePassword ?  "text" : "password"}
                        placeholder="password"
                        validator={validator.isStrongPassword}
                        errorMessage={{required:"Password Is Required", validator: "Use 8 or more characters with a mix of letters, numbers & symbols"}}      
                        onChange={e => setPassword(e.target.value)}
                        defaultValue={password} />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                          <Input id="checkbox1" type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{"Remember Password"}</Label>
                        </div><a style={{display:'none'}} className="link" href="#javascript">{"Forgot Password"}</a>
                       
                        <Button color="primary" disabled={loading} className="btn-block" >Sign in</Button>
                      </div>
                      {loading && (
                        <div className="text-center">
                          <div className="loader-box">
                            <div className="loader-9"></div>
                          </div>
                        </div>
                      )}
                      <p></p>
                     <div className="text-center"><a href="/login">Login as Inspector</a></div>
                    </ValidationForm>
                 
                  {/* <TabPane  className="fade show" tabId="auth0">
                    <div className="auth-content">
                        <img src={require("../assets/images/auth-img.svg")} alt="" />
                        <h4>{"Welcome to login with Auth0"}</h4>
                        <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                        <Button color="info" onClick={loginWithRedirect}>{AUTH0}</Button> 
                    </div>
                  </TabPane> */}
              </div>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
    );
}

export default withRouter(Logins);