import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  ValidationForm,
  FileInput,
  TextInput,
  Checkbox,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import moment from "moment";
import { fetchActiveLMArea, fetchActiveLMLayer, createNewLMLayer, updateLMLayerDetail, fetchActiveDesign } from "../../../../../api";
import $ from "jquery";
import DataTable from "datatables.net";
import { toast } from 'react-toastify'
toast.configure();

const LMLayerMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [areaType, setAreaType] = useState("");
  const [designType, setDesignType] = useState("");
  const [layerType, setLayerType] = useState("");
  const [layerId, setLayerId] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [areaList, setAreaList] = useState([]);
  const [designList, setDesignList] = useState([]);
  const [layerList, setLayerList] = useState([]);
  const [filterDesignList, setFilterDesignList] = useState([]);
  const [filterDesignType, setFilterDesignType] = useState([]);
  const [edit, setEdit] = useState(false);
  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );
  const formRef = useRef(null);
  const filterFormRef = useRef(null);

  useEffect(() => {
    getLayerList();
    getDesignList();
    getDesignList(true);
    // getAreaList();
  }, []);

  // const getAreaList = () => {

  //   var tkn = `Bearer ${token}`;
  //   let data = `company=${companyId}&isActive=true`;
  //   const area = fetchActiveLMArea(data, tkn).then((res) => {
  //     if (res.data.success === true) {
  //       setAreaList(res.data.data);
  //       // setLoading(false);
  //     } else {
  //       // setLoading(false);
  //     }
  //   });
  // };

  const getDesignList = (filter) => {
    setDesignList([])
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const status = fetchActiveDesign(data, tkn).then((res) => {
      if (res.data.success === true) {
        if (filter === true) {
          setFilterDesignList(res.data.data)
        }
        else {
          setDesignList(res.data.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getLayerList = () => {
    clearFilter()
    $('#productTable').dataTable().fnDestroy();
    setLayerList([]);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const product = fetchActiveLMLayer(data, tkn).then((res) => {
      if (res.data.success === true) {
        setLayerList(res.data.data); setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (layerList.length > 0) {
      $("#productTable").dataTable({
        'columnDefs': [{
          'targets': [4], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }]
      });
    }
  }, [layerList]);

  const addLMLayer = (event) => {
    event.preventDefault();
    setLoading(true);
    if (layerType !== "") {
      var tkn = `Bearer ${token}`;
      const body = JSON.stringify({
        company: companyId,
        // area: areaType,
        design: designType,
        layerType: layerType,
        isActive: isActive,
      });
      createNewLMLayer(body, tkn).then((res) => {
        if (res.data.success) {
          clearValues();
          toast.success("LM Layer details added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
          closeCustomizer();
        } else {
          toast.warning(res.data.data, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
        }
      });
    } else {
      clearValues();
      toast.error("Please enter all values", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const clearValues = () => {
    getLayerList();
    setLoading(false);
    setIsActive(true);
    // setAreaType('');
    setDesignType('');
    setDesignList([]);
    setLayerType('');
    closeCustomizer();
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setEdit(false);
    setLoading(false);
    setIsActive(true);
    // setAreaType('');
    setDesignType('');
    setDesignList([]);
    setLayerType('');
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editLayer = (layer) => {
    setEdit(true);
    setLayerId(layer._id);
    setLayerType(layer.layerType);
    getDesignList(false);
    // if (layer.area) {
    //   setAreaType(layer.area._id);
    //   getDesignList(false);
    // }
    if (layer.design) {
      setDesignType(layer.design._id)
    }

    setIsActive(layer.isActive);
    openCustomizer();
  };

  const updateLayerProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      // area: areaType,
      designType: designType,
      layerType: layerType,
      isActive: isActive,
    });
   
    updateLMLayerDetail(layerId, body, tkn).then((res) => {

      if (res.data.success) {
        setEdit(false);
        toast.success("Step details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
        clearValues();
      }
      else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    }).catch(function (response) {
      //handle error
      clearValues();
      console.log(response);
    });
  };

  // const onAreaSelect = (e) => {
  //   getDesignList(e.target.value);
  //   setDesignType("");
  //   setAreaType(e.target.value);
  // };
  // const onVariableSelect = (e) => {
  //   setDesignType(e.target.value);
  // };

  const loadAssyByFilter = (event) => {

    event.preventDefault();
    var table = $("#productTable").DataTable();
    if (filterDesignType.value !== "") {
      table.columns(1).search(filterDesignType.text).draw();
    }
    else {
      table
        .search('')
        .columns(1).search('')
        .draw();
    }
  };

  // const onFilterAreaSelect = (e) => {
  
  //   getDesignList(true);
  //   setFilterDesignList([]);
  //   setFilterDesignList({ value: "", text: "" });
  //   setFilterAreaType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
  // };

  const onFilterDesignSelect = (e) => {
    // console.log(e.target.value);
    // console.log(e.target.options[e.target.selectedIndex].text);
    setFilterDesignType({ value: e.target.value, text: e.target.options[e.target.selectedIndex].text });
    // setFilterDesignType(e.target.value);
  };

  const clearFilter = (e) => {
    var table = $("#productTable").DataTable();
    // setFilterAreaType({ value: "", text: "" });
    setFilterDesignType({ value: "", text: "" });
    setFilterDesignList([]);
    table
      .search('')
      .columns().search('')
      .draw();
  };
  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Step Master" />
      <Container className="master-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add LM Layer
            </Button>
          </CardHeader>
        </Card>
        <Card>
        {layerList.length>0&&(<CardHeader>
            <ValidationForm onSubmit={loadAssyByFilter} ref={filterFormRef}>
              <Row>
                <Col lg="6" className="text-left">
                  <Label htmlFor="filterDesignType">Select Part ID</Label>
                  <SelectGroup
                    type="select"
                    name="filterDesignType"
                    onChange={(e) => onFilterDesignSelect(e)}
                    errorMessage={{ required: "Part ID Type Required" }}
                    className="form-control digits"
                    selected={filterDesignType.value}
                    value={filterDesignType.value}
                  >
                    <option value="">{"All"}</option>
                    
                    {filterDesignList &&
                      filterDesignList.map((design, index) => (
                        <option value={design._id} key={design._id}>
                          {design.designType}
                        </option>
                      ))}
                  </SelectGroup>

                </Col>
                <Col lg="6" className="text-left">
                  <Button title="Add Filter" className="filter-btn" color="success" size="md">
                    <i className="fa fa-filter"></i>
                  </Button>
                  <Button title="Clear Filter" onClick={clearFilter} className="filter-btn ml-2" color="danger" size="md">
                    <i className="fa fa-close"></i>
                  </Button>
                </Col>
              </Row>
            </ValidationForm>
          </CardHeader>)}
          <CardBody>
            <Table id="productTable"
              className="master-table"
              bordered
              responsive>
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  <th className="name-col">Part ID</th>
                  <th className="name-col">Layer</th>
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {layerList.length>0?layerList.map((layer, index) => (
                  <tr key={layer._id}>
                    <td>{index + 1}</td>
                    <td>{layer.design.designType}</td>
                    <td>{layer.layerType}</td>
                   
                    <td data-order={layer.isActive} className="text-center">
                      {layer.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i
                        onClick={() => editLayer(layer)}
                        className="icon fa fa-pencil-square"
                      ></i>
                    </td>
                  </tr>
                )):
                <tr>
                <td colSpan="12" className="text-center">
                  {" "}
                  No data found.{" "}
                </td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
                <td className="d-none"></td>
              </tr>}
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Step</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateLayerProcess : addLMLayer}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              {/* <FormGroup>
                <Label htmlFor="waferArea">Area</Label>
                <SelectGroup
                  type="select"
                  name="waferArea"
                  required
                  value={areaType}
                  errorMessage={{ required: "Area Is Required" }}
                  onChange={(e) => onAreaSelect(e)}
                  className="form-control digits"
                  selected="Select Area"
                >
                  <option value="">{"Select Area"}</option>
                  {areaList &&
                    areaList.map((area, index) => (
                      <option value={area._id} key={area._id}>
                        {area.areaType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup> */}
              <FormGroup>
                <Label htmlFor="waferVariable">Part ID</Label>
                <SelectGroup
                  type="select"
                  name="waferVariable"
                  onChange={(e) => setDesignType(e.target.value)}
                  required
                  errorMessage={{ required: "Part ID Type Required" }}
                  className="form-control digits"
                  selected={designType}
                  value={designType}
                >
                  <option value="">{"Select Part ID Type"}</option>
                  {designList &&
                    designList.map((design, index) => (
                      <option value={design._id} key={design._id}>
                        {design.designType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="product-type">Layer Type</Label>
                <TextInput
                  required
                  className="form-control"
                  type="text"
                  name="product-type"
                  errorMessage={{ required: "Layer Is Required" }}
                  placeholder="Enter Layer"
                  value={layerType}
                  onChange={(e) => setLayerType(e.target.value)}
                />
              </FormGroup>

            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0">
                <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={e => setIsActive(!isActive)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update LM Layer
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add LM Layer
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default LMLayerMasterPage;
