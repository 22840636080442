import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { ValidationForm, Checkbox, TextInput } from 'react-bootstrap4-form-validation';
import moment from "moment";
import { fetchActiveArea, updateArea, createNewArea } from "../../../../api";
import { toast } from 'react-toastify'
import $ from 'jquery';
import DataTable from 'datatables.net';

toast.configure();

const AreaMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [edit, setEdit] = useState(false);
  const [areaId, setAreaId] = useState([]);
  const [areaType, setAreaType] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [areaList, setAreaList] = useState([]);
  const [description, setDescription] = useState("");
  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );
  const formRef = useRef(null);
  useEffect(() => {
    getAreaList();
  }, []);

  useEffect(() => {
    if (areaList.length > 0) {
      $("#areaTable").dataTable({
        'columnDefs': [{
          'targets': [4], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }]
      });
    }
  }, [areaList]);

  const getAreaList = () => {
    $('#areaTable').dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const area = fetchActiveArea(data, tkn).then((res) => {
      if (res.data.success === true) {
        setAreaList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  const addArea = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      areaType: areaType,
      description: description,
      isActive: isActive,
    });
    createNewArea(body, tkn).then((res) => {
      if (res.data.success) {
        getAreaList();
        clearValues();
        toast.success("Area details added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setLoading(false);
        closeCustomizer();
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setLoading(false);
      }
    });

  };

  const clearValues = () => {
    setLoading(false);
    setAreaType('');
    setDescription('')
    setIsActive(true);
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }

  };

  const closeCustomizer = () => {
    setRightSidebar(!rightSidebar);
    clearValues();
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editArea = (status) => {
    setEdit(true);
    setAreaId(status._id);
    setAreaType(status.areaType);
    setDescription(status.description)
    setIsActive(status.isActive);
    openCustomizer();
  };


  const updateAreaProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      areaType: areaType,
      description: description,
      isActive: isActive,
    });
    updateArea(areaId, body, tkn).then((res) => {

      if (res.data.success) {
        getAreaList();
        toast.success("Area details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        clearValues();
        setEdit(false);
        setLoading(false);
        closeCustomizer();
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Area Master" />
      <Container className="master-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add
            </Button>
          </CardHeader>
          <CardBody>
            <Table id="areaTable" className="master-table" bordered responsive>
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  <th className="name-col">Area Type</th>
                  <th className="name-col">Description</th>
                  {/* <th className="name-col">Created On</th> */}
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {areaList.length>0?areaList.map((area, index) => (
                  <tr key={area._id}>
                    <td>{index + 1}</td>
                    <td>{area.areaType}</td>
                    <td>{area.description}</td>
                    {/* <td>
                      {moment(area.createAt).format("MM-DD-YYYY")} {moment(area.createAt).format("hh:mm")}
                    </td> */}
                    <td data-order={area.isActive} className="text-center">
                      {area.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i onClick={() => editArea(area)} className="icon fa fa-pencil-square"></i>
                    </td>
                  </tr>
                )):
                <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                  </tr>
                }
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Area</h6>
        </div>
        <ValidationForm onSubmit={edit ? updateAreaProcess : addArea} ref={formRef} className='theme-form customizer-body custom-scrollbar'>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="area-type">Area</Label>
                <TextInput
                  className="form-control"
                  type="text"
                  required
                  placeholder="Enter Area"
                  errorMessage={{ required: "Area Is Required" }}
                  value={areaType}
                  name="area-type"
                  onChange={e => setAreaType(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <TextInput
                  value={description}
                  className="form-control"
                  required
                  multiline
                  rows="3"
                  name="description"
                  placeholder="Enter Description"
                  errorMessage={{ required: "Description Is Required" }}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0" >
                <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={e => setIsActive(!isActive)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update Area
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add Area
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
};

export default AreaMasterPage;
