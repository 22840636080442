import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
} from "reactstrap";
import CountUp from "react-countup";
import {
  Database,
  Search,
  ShoppingBag,
  Archive,
  Clock,
  User,
} from "react-feather";
import { getWafers, fetchDash, fetchChart } from "../../api";

import { toast } from "react-toastify";
import WaferItem from "./WaferItem";
import $ from "jquery";
import DataTable from "datatables.net";
import SVQCDailyChart from "../chart/dailyChart";
import SVQCInspectionChart from "../chart/inspectionChart";
import {
  WaferDailyChartTitle,
  WaferLast30DaysChartTitle,
} from "../../constant/index";

toast.configure();

const Dashboard = (props) => {
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [wafersList, setWafersList] = useState([]);
  const [opencount, setOpencount] = useState([]);
  const [reviewcount, setReviewcount] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [usercount, setUsercount] = useState([]);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState({});
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    loadWafers();
    loadDash();
  }, []);

  useEffect(() => {
    if (wafersList.length > 0 && loading === false) {
      if (!$.fn.DataTable.isDataTable("#fileUploadTable")) {
        $("#fileUploadTable").DataTable({
          ordering: false,
        });
      }
    }
  }, [wafersList, loading]);

  const loadWafers = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isDisposed=false&reviewCompleted=false`;
    getWafers(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          setWafersList(res.data.data);
          // console.log(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const loadDash = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isDisposed=false&reviewCompleted=false`;
    fetchDash(data, tkn)
      .then((res) => {
        console.log(res);
        if (res.data.success === true) {
          // for (let i = 0; i < res.data.UserData.length; i++) {
          //   let user = res.data.UserData[i];
          //   if (user._id === true) {
          //     setUsercount(user.count);
          //   }
          // }

          if (res.data.inxpectorUserData.length > 0) {
            setUsercount(res.data.inxpectorUserData[0].inspectorUser);
          }

          for (let i = 0; i < res.data.Waferdata.length; i++) {
            let open = res.data.Waferdata[i];
            if (open._id === "Open") {
              setOpencount(open.count);
            }

            let review = res.data.Waferdata[i];
            if (review._id === "On Review") {
              setReviewcount(review.count);
            }

            let completed = res.data.Waferdata[i];
            if (completed._id === "On Disposition") {
              setCompleted(completed.count);
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Dashboard" />
      <Container fluid={true}>
        <div className="dashboard">
          <Row>
            {userRole === "manager" && (
              <Col sm="6" xl="4" lg="6">
                <Card className="o-hidden">
                  <div className="bg-secondary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <User />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Active Inxpectors</span>
                        <h4 className="mb-0 counter">
                          <CountUp end={usercount} />
                        </h4>
                        <User className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            )}
            <Col sm="6" xl="4" lg="6">
              <Card className="o-hidden">
                <CardBody className="bg-warning b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Clock />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Wafers in Queue</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={opencount} />
                      </h4>
                      <Clock className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="4" lg="6">
              <Card className="o-hidden">
                <CardBody className="bg-primary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Search />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Under Inspection</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={reviewcount} />
                      </h4>
                      <Search className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="4" lg="6">
              <Card className="o-hidden">
                <CardBody className="bg-success b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Database />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Inspection Completed</span>
                      <h4 className="mb-0 counter">
                        <CountUp end={completed} />
                      </h4>
                      <Database className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {/*
       
        <div className="dash-status">
          <Card>
            <CardBody>
              {loading && (
                <div className="text-center">
                  <div className="loader-box">
                    <div className="loader-9"></div>
                  </div>
                </div>
              )}
              {!loading && (
                <Table id="fileUploadTable" className="withoutSort dash-status-table" bordered responsive>
                  <thead>
                    <tr>
                      <th rowSpan="2" className="halogenix-column">
                        Status
                      </th>
                      <th colSpan="7" className="halogenix-column">
                        Halogenix File Upload
                      </th>
                      <th rowSpan="2" className="halogenix-column"></th>
                    </tr>
                    <tr>
                      <th className="halogenix-column lot-col">Lot #</th>
                      <th className="halogenix-column lot-col">Layer</th>
                      <th className="halogenix-column lot-col">Area</th>
                      <th className="halogenix-column lot-col">Variable</th>
                      <th className="date-col halogenix-column">Added Time</th>
                      <th className="name-col halogenix-column">FFI-Tech</th>
                      <th className="name-col halogenix-column">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wafersList.map((wafer, index) =>
                      userRole === "inxpector" &&
                        wafer.inxpectionFolderUrl !== undefined ? (
                        <WaferItem key={index} wafer={wafer} />
                      ) : (
                        userRole === "manager" && (
                          <WaferItem key={index} wafer={wafer} />
                        )
                      )
                    )}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </div>
      */}
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>{WaferDailyChartTitle} </h5>
              </CardHeader>
              <CardBody>
                <div id="waferDailyChart">
                  <SVQCDailyChart />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>{WaferLast30DaysChartTitle} </h5>
              </CardHeader>
              <CardBody>
                <div id="waferDailyChart">
                  <SVQCInspectionChart />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;

// {
//   wafersList.map((wafer, index) =>(
//     <tr key={wafer._id}>
//     {/* <td>{index + 1}</td> */}
//     <td><span className={waferStatusBadger(wafer.status)}>{wafer.status}</span></td>
//     <td><span className={wafer.highPriority ? `badge badge-danger` : ``}>{wafer.lot}</span></td>
//     <td>{wafer.layer}</td>
//     <td>{moment(wafer.createAt).format('MM-DD-YYYY HH:mm')}</td>
//     <td>{wafer.user.name}</td>
//     <td>{wafer.remarks}</td>
//     <td>
//       <span className='text-primary' onClick={expandable()}>Inspection Details</span>
//     </td>
//   </tr>

//   ))
// }
