import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    Label,
    CardHeader,
    Button,
    CardBody,
    Table,
} from "reactstrap";
import { fetchCompletedLotsByDate, fetchInxpectorByDate } from "../../../../api";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DataTable from "datatables.net";
import dayjs from "dayjs";
import { ValidationForm } from "react-bootstrap4-form-validation";
import $ from 'jquery';

import { toast } from "react-toastify";
import moment from "moment";
import { TablePriorityStyle } from "../../../../helpers";
import { Colors } from "../../../../constant";
toast.configure()

const LotCompletedPage = (props) => {
    const today = dayjs(new Date());
    var defaultToDate = new Date(dayjs(today).format("YYYY-MM-DD"));
    const defaultFromDate = new Date(
        dayjs(new Date()).subtract(30, "day").format("YYYY-MM-DD")
    );

    const [loading, setLoading] = useState(true);
    const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [waferList, setWaferList] = useState([]);
    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState(defaultToDate);

    useEffect(() => {
        getCompletedLots();
    }, []);

    useEffect(() => {
        if (waferList.length > 0) {
            if (!$.fn.DataTable.isDataTable("#companyInxpectorTable")) {
                $("#companyInxpectorTable").DataTable({
                    ordering: false,
                    destroy: true,
                    export: true,
                    sortable: true
                });
            }
        }
    }, [waferList]);

    const updateFilter = () => {
        if (fromDate && toDate) {
            $('#companyInxpectorTable').dataTable().fnDestroy();
            var tkn = `Bearer ${token}`;
            let data = `company=${companyId}&fromDate=${fromDate}&toDate=${toDate}`;

            setLoading(true);

            fetchCompletedLotsByDate(data, tkn).then((res) => {
                if (res.data.success === true) {
                    if (res.data.data.length > 0) {
                        setWaferList(res.data.data);
                    } else {
                        setWaferList([]);
                    }
                    setLoading(false);
                } else {
                    setWaferList([]);
                }
            })
                .catch((err) => console.log(err.message));
        }
        setLoading(false);
    }


    const getCompletedLots = () =>{
        if (fromDate && toDate) {
            $('#companyInxpectorTable').dataTable().fnDestroy();
            var tkn = `Bearer ${token}`;
            let data = `company=${companyId}&fromDate=${fromDate}&toDate=${toDate}`;

            setWaferList([]);
            setLoading(true);

            fetchCompletedLotsByDate(data, tkn).then((res) => {
                if (res.data.success === true) {
                    if (res.data.data.length > 0) {
                        setWaferList(res.data.data);
                    } else {
                        setWaferList([]);
                    }
                    setLoading(false);
                } else {
                    setWaferList([]);
                }
            })
                .catch((err) => console.log(err.message));
        }
        setLoading(false);
    }

    const clearFilter = (event) => {
        event.preventDefault();
        setFromDate(defaultFromDate);
        setToDate(defaultToDate);

    };

    const formatDateTime = (dTime) => {
        // console.log(dTime);
        var newDt;
        if(dTime != undefined){
            newDt = moment(dTime).format('MM-D-YYYY, h:mm a');
        }
        return newDt;
    }

    const setFilter = (event) => {
        event.preventDefault();
    };

    const toHoursAndMinutes = (dt2, dt1) => {

        var t1 = new Date(dt2);
        var t2 = new Date(dt1);
        var seconds = 0;
       
            var dif = t1.getTime() - t2.getTime();
            seconds = dif / 1000;
       return secondsToDhms(seconds);
     }
   

    const secondsToDhms = (seconds) => {
        seconds = Number(seconds);
        if (seconds > 0) {
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor(seconds % (3600 * 24) / 3600);
            var m = Math.floor(seconds % 3600 / 60);
            var s = Math.floor(seconds % 60);

            var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
            var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";

            return (dDisplay + hDisplay + mDisplay + sDisplay);
        }
        else {
            let empty = "-";
            return (empty);
        }
    }

    return (
        <Fragment>
          <Breadcrumb parent="InXpector" title="Livermore Completed Lot Details" />
          <Container className="wafer-container" fluid={true}>
          <Card>
                    <CardHeader>
                        <Row>
                            <Col lg="10">
                                <ValidationForm onSubmit={setFilter}>
                                    <Row>
                                        <Col lg="4" className="text-left">
                                            <Label htmlFor="filterVariableType">From Date</Label>
                                            <DatePicker
                                                required
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Click to select a from date"
                                                maxDate={toDate === "" ? new Date() : toDate}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                className="form-control"
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                errorMessage={{ required: "From Date Is Required" }}
                                            />
                                        </Col>
                                        <Col lg="4" className="text-left">
                                            <Label htmlFor="filterVariableType">To Date</Label>
                                            <DatePicker
                                                required
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Click to select a to date"
                                                maxDate={new Date()}
                                                minDate={fromDate}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                className="form-control"
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                errorMessage={{ required: "To Date Is Required" }}

                                            />
                                        </Col>

                                        <Col lg="4" className="text-left">
                                            <Button
                                                title="Add Filter"
                                                className="filter-btn"
                                                color="success"
                                                size="md"
                                                onClick={() => updateFilter()}
                                            >
                                                <i className="fa fa-filter"></i>
                                            </Button>
                                            <Button
                                                title="Clear Filter"
                                                className="filter-btn ml-2"
                                                onClick={clearFilter}
                                                color="danger"
                                                size="md"
                                            >
                                                <i className="fa fa-close"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ValidationForm>
                            </Col>
                        </Row>
                    </CardHeader>
                </Card>
                <div className="dash-status">
                    <Card>
                        <CardBody>
                            <Table
                                id="companyInxpectorTable"
                                className="withoutSort dash-status-table"
                                bordered
                                responsive
                            >
                                <thead>
                                    <tr>
                                        <th className="halogenix-column">S.No #</th>
                                        <th className="halogenix-column lot-col">Lot.</th>
                                        <th className="halogenix-column lot-col">Priority</th>
                                        <th className="halogenix-column lot-col">Part Id</th>
                                        <th className="halogenix-column lot-col">Total Images Inspected</th>
                                        <th className="halogenix-column lot-col">Total Images Accepted</th>
                                        <th className="halogenix-column lot-col">Deployment Time</th>
                                        <th className="halogenix-column lot-col">Inxpection Start Time</th>
                                        <th className="halogenix-column lot-col">Completion Time</th>
                                        <th className="halogenix-column lot-col">Inspection Time</th>
                                        <th className="halogenix-column lot-col">Total Time</th>
                                        <th className="halogenix-column lot-col">Review Completed By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {waferList.length > 0 ? waferList.map((wafer, index) => (
                                        // {wafer.partId[0].designType} 
                                        <tr key={wafer._id}>
                                            <td>{index + 1}</td>
                                            <td>{wafer.lot}</td>
                                            <td>{wafer.highPriority}</td>
                                            <td>{wafer.partId.length >= 1 ? wafer.partId[0].designType : "-"} </td>
                                            <td>{wafer.nInspectedImages}</td>
                                            <td>{wafer.nAcceptedImages}</td>
                                            <td>{formatDateTime(wafer.createAt)}</td>
                                            <td>{formatDateTime(wafer.reviewStartDate)}</td>
                                            <td>{formatDateTime(wafer.reviewCompleteDate)}</td>
                                            <td>{toHoursAndMinutes(wafer.reviewCompleteDate, wafer.reviewStartDate)}</td>
                                            <td>
                                                {toHoursAndMinutes(wafer.disposeCompleteDate, wafer.createAt)}
                                            </td>
                                            <td>{wafer.userInfo[0].name}</td>
                                        </tr>
                                    ))
                                        : (
                                            <tr>
                                                <td colSpan="12" className="text-center">
                                                    {" "}
                                                    No data found.{" "}
                                                </td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                                <td className="d-none"></td>
                                            </tr>
                                        )}


                                </tbody>
                            </Table>
                            {loading && (
                                <div className="text-center">
                                    <div className="loader-box">
                                        <div className="loader-9"></div>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>
          </Container>
          </Fragment>
  );
}

export default LotCompletedPage;