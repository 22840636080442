import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    CardHeader,
    Button,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ValidationForm, FileInput, TextInput } from 'react-bootstrap4-form-validation';
import { updateDispositionRemarks, updateDispositionToArchive } from "../../../api";
import { toast } from 'react-toastify'
import { useParams, useLocation } from "react-router-dom";
import Annotation from "react-image-annotation";
import {
    PointSelector,
    RectangleSelector,
    OvalSelector,
} from "react-image-annotation/lib/selectors";
import { CSVLink } from "react-csv";
import { Dialog } from "primereact/dialog";
import InnerImageZoom from "react-inner-image-zoom";
import $ from "jquery";
import DataTable from "datatables.net";


toast.configure();

const ArchivedDisplay = (props) => {
    const [loading, setLoading] = useState(false);
    const [rightSidebar, setRightSidebar] = useState(true);
    const [waferReview, setwaferReview] = useState([]);
    const [waferData, setWaferData] = useState([]);
    const [waferReviewJSON, setWaferReviewJSON] = useState("");
    const [waferId, setWaferId] = useState("");
    const [reviewData, setReviewData] = useState([]);
    const [reviewIndex, setReviewIndex] = useState(0);
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const [archiveModal, setArchiveModal] = useState(false);
    const archiveToggleModal = () => setArchiveModal(!archiveModal);
    const [zoomImageSrc, setZoomImageSrc] = useState("");
    const [zoomMode, setZoomMode] = useState(false);
    const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [annotation, setAnnotation] = useState({});
    const [annotations, setAnnotations] = useState([]);
    const [dispositionRemarks, setDispositionRemarks] = useState("");
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("currentUser"))
    );
    const { id } = useParams();
    const { state } = useLocation();
    const history = useHistory();
    const formRef = useRef(null);

    useEffect(() => {
        const waferForReview = state.waferReview.filter(x=> x.reviewType === "defects");
        setLoading(true);
        setWaferData(state.wafer);
        setwaferReview(waferForReview);
        setWaferReviewJSON(waferForReview);

        setWaferId(state.waferId);
        // console.log(user);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (waferReview.length > 0) {
    
          if (!$.fn.DataTable.isDataTable('#waferReviewTable')) {
            $("#waferReviewTable").DataTable({
              "ordering": false,
            });
          }
        }
      }, [waferReview]);

    const bindDefectClassification = (defectClassificationList) => {

        let defectStr = "";
        defectClassificationList.map((dc, index) => (
            defectStr = defectStr + dc + ","
        ));
        let returnString = defectStr.replace(/,\s*$/, "");
        if (returnString === "null") {
            return "-";
        } else {
            return returnString;
        }
    }
    const dispositionStatusBadger = (dispositionRemark) => {
        // status = status.toLower;
        if (dispositionRemark === true) {
            return "badge badge-success";
        } else if (dispositionRemark === false) {
            return "badge badge-warning";
        }
    };
    const onSelectReview = (review, index) => {
        setReviewData(review);
        setReviewIndex(index);
        if (review.annotationValue.length > 0) {
            let bindData = [];
            for (let i = 0; i < review.annotationValue.length; i++) {
                //const { geometry, data } = review.annotationValue[i];
                let newData = [];
                newData.geometry = review.annotationValue[i][0].geometry;
                newData.data = review.annotationValue[i][0].data;

                bindData.push(newData);
            }
            setAnnotations(bindData);
        }
        setModal(!modal);
    };

    const addDispositionDetails = (event) => {
        $('#waferReviewTable').dataTable().fnDestroy();
        event.preventDefault();
        setLoading(true);
        var tkn = `Bearer ${token}`;
        const data = `company=${companyId}&id=${waferId}&reviewDataId=${reviewData._id}&reviewIndex=${reviewIndex}&dispositionRemark=${dispositionRemarks}`
        updateDispositionRemarks(data, tkn)
            .then((res) => {
                if (res.data.success === true) {
                    toast.success("Disposition remark added successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                    });
                    setLoading(false);
                    setModal(!modal);
                    //setwaferReview(state.waferReview);
                    setwaferReview(res.data.data.review.reviewData);
                    // const waferReviews = res.data.data.review.reviewData;
                    // if (waferReviews.length > 0) {
                    //     for (let i = 0; i < waferReviews.length; i++) {
                    //         waferReviews[i].annotationValue = "";
                    //         setWaferReviewJSON[i] = waferReviews[i];
                    //     }
                    // }
                    // setWaferReviewJSON(waferReviews);
                    setDispositionRemarks("");

                    // let curentFormRef = formRef.current;
                    // curentFormRef.resetValidationState(true);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err.message));


        setLoading(false);
    };

    const completeDispositionDetails = () => {
        setLoading(true);
        var tkn = `Bearer ${token}`;
        const data = `company=${companyId}&id=${waferId}&archived=true`
        updateDispositionToArchive(data, tkn)
            .then((res) => {
                if (res.data.success === true) {
                    toast.success("Disposition Completed successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                    });
                    history.push(`/admin/disposition`);

                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err.message));
        setLoading(false);
    };

    const convertJSON = () => {
        //setwaferReview(state.waferReview);

        const waferReviewsList = waferReview;
        if (waferReviewsList.length > 0) {
            for (let i = 0; i < waferReviewsList.length; i++) {
                waferReviewsList[i].annotationValue = JSON.stringify();
                setWaferReviewJSON[i] = waferReviewsList[i];
            }
        }
        setWaferReviewJSON(waferReviewsList);
    };

    const zoomToggle = (src) => {
        setZoomMode(!zoomMode);
        setZoomImageSrc(src);
    };

    return (
        <Fragment>
            <Breadcrumb parent="InXpector" title="Manage Archived Details" />
            <Container className="wafer-container" fluid={true}>
                <Row>
                    <Col>
                        {/* <CSVLink data={waferReviewJSON} filename="DispositionList.csv" >
                            <button onClick={() => convertJSON} className="badge badge-success">Export as CSV</button>
                        </CSVLink> */}
                        <CSVLink data={waferData && waferReviewJSON && waferReviewJSON.map((wafer, index) => ({
                            "Image Name": wafer.imageName,
                            "Image Url": wafer.screenshotUrl,
                            "Last Reviewed Image": wafer.lastReviewedImage,
                            "Review Remark": wafer.reviewRemark,
                            "Review Start Date": moment(waferData.reviewStartDate).format("MM-DD-YYYY HH:mm"),
                            "Review Completed Date": moment(waferData.reviewCompleteDate).format("MM-DD-YYYY HH:mm"),
                            "Disposition Remark": wafer.dispositionRemark,
                            "Disposition Submitted Time": moment(wafer.dispositionSubmittedAt).format("MM-DD-YYYY HH:mm"),
                            // "submitted By": wafer.submittedBy,                            
                            "Defect Classification": wafer.defectClassification,
                            //"Annotation Value": JSON.stringify(wafer.annotationValue),
                            // "Annotation Value": wafer.annotationValue && wafer.annotationValue.map((annotation, index) => ({
                            //     "geometry": JSON.stringify(annotation.get[0].geometry),
                            //     "data": JSON.stringify(annotation[0].data),
                            // }))
                        }
                        ))} filename="ArchivedList.csv" >
                            <button className="badge badge-success">Export Log</button>
                        </CSVLink>
                    </Col>
                </Row>
                <Card>

                    <CardBody>
                      
                            <Table id="waferReviewTable" className="dash-status-table" bordered responsive>
                                <thead>
                                    <tr>
                                        <th className="halogenix-column">S.No</th>
                                        <th className="halogenix-column">Defect Classification</th>
                                        <th className="halogenix-column lot-col">Review Remarks</th>
                                        <th className="halogenix-column lot-col">Image Name</th>
                                        <th className="date-col halogenix-column">Image</th>
                                        <th className="date-col halogenix-column">Disposition Submitted Time</th>
                                        <th className="date-col halogenix-column">Disposition Review</th>

                                        {/* <th className="name-col halogenix-column">Add Remarks</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {waferReview.length>0?waferReview.map((review, index) => (

                                        <tr key={review._id}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">
                                                {bindDefectClassification(review.defectClassification)}
                                            </td >
                                            <td className="text-center">{review.reviewRemark}</td>
                                            <td className="text-center">{review.imageName}</td>
                                            <td className="text-center"> 
                                            {/* <div className="mouse-hand"><img onClick={() =>
                                                zoomToggle(
                                                    review.screenshotUrl
                                                )
                                            } className="img-thumbnail defect-image" src={review.screenshotUrl} />
                                            </div> */}
                                            <a href={`https://inxpector.sharepoint.com/${review.imageUrl}`} target="_blank">Show Image</a>
                                            </td>
                                            {/* <td className="text-center">
                                                <a className={dispositionStatusBadger(review.dispositionRemark === undefined ? false : true)} onClick={() => onSelectReview(review, index)}>
                                                    {review.dispositionRemark === undefined ? "Not validated" : "Validated"}
                                                </a></td> */}
                                            <td className="text-center">
                                                {moment(review.dispositionSubmittedAt).format("MM-DD-YYYY HH:mm")}
                                            </td>
                                            <td className="text-center">
                                                {review.dispositionRemark}
                                            </td>
                                        </tr>
                                    )):<tr>
                                    <td colSpan="12" className="text-center">
                                      {" "}
                                      No data found.{" "}
                                    </td>
                                    <td className="d-none"></td>
                                    <td className="d-none"></td>
                                    <td className="d-none"></td>
                                    <td className="d-none"></td>
                                    <td className="d-none"></td>
                                    <td className="d-none"></td>
                                  </tr>}
                                </tbody>
                            </Table>
                            {loading && (
                            <div className="text-center">
                                <div className="loader-box">
                                    <div className="loader-9"></div>
                                </div>
                            </div>
                        )}
                        {/* <Row>
                            <Col lg="6">
                                {""}
                            </Col>
                            <Col lg="6 text-right">
                                <Button
                                    onClick={() => setArchiveModal(!archiveModal)}
                                    color="success"
                                    className="mr-1 btn-air-*"
                                >
                                    Complete Disposition And Archive
                          </Button>
                            </Col>
                        </Row> */}
                    </CardBody>
                </Card>

            </Container>
            < Modal isOpen={modal} toggle={toggleModal} size="lg" >
                <ModalHeader>
                    Disposition Review
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12" sm="12" md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="8" md="8" sm="8">

                                            <Annotation
                                                // src={img}
                                                loading={loading}
                                                key={1}
                                                src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                                                annotations={annotations}
                                                type={RectangleSelector.TYPE}
                                                value={annotation}
                                                disableSelector={true}
                                                // onChange={onChange}
                                                // onSubmit={onSubmit}
                                                disableZoom={false}
                                            />

                                        </Col>
                                        <Col lg="4" md="4" sm="4">

                                            <ValidationForm onSubmit={addDispositionDetails} ref={formRef} className='theme-form customizer-body custom-scrollbar'>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label htmlFor="destroy()">Disposition Remarks</Label>
                                                            <TextInput
                                                                required
                                                                className="form-control"
                                                                type="textarea"
                                                                name="dispositionRemark"
                                                                errorMessage={{ required: "Disposition Remark Is Required" }}
                                                                placeholder="Disposition Remark"
                                                                value={dispositionRemarks}
                                                                onChange={e => setDispositionRemarks(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <FormGroup className='text-center'>
                                                            <Button disabled={loading} className='success'>Submit</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </ValidationForm>


                                            {/* <FormGroup>
                                                <Label className="col-form-label">Disposition Remarks</Label>
                                                <Input
                                                    value={dispositionRemarks}
                                                    className="form-control"
                                                    type="textarea"
                                                    placeholder="enter disposition remarks"
                                                    onChange={(e) => setDispositionRemarks(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <Row className="mr_t_2">
                                                    <Col sm="6 text-right">
                                                        <Button
                                                            onClick={() => addDispositionDetails()}
                                                            color="success"
                                                            className="mr-1 btn-air-*"
                                                        >
                                                            Submit
                          </Button>
                                                    </Col>
                                                </Row>
                                            </FormGroup> */}
                                        </Col>
                                    </Row>
                                    {loading && (
                                        <div className="text-center">
                                            <div className="loader-box">
                                                <div className="loader-9"></div>
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleModal} className="btn btn-secondary">
                        Close
      </Button>
                    {/* <button onClick={goToDispositionDetail} className="btn btn-success">
                        Continue
      </button> */}
                </ModalFooter>
            </Modal >

            < Modal isOpen={archiveModal} toggle={archiveToggleModal} size="sm" >
                <ModalBody>Are you Sure want to complete this disposition and Make them Archive?</ModalBody>
                <ModalFooter>
                    <Button onClick={archiveToggleModal} className="btn btn-secondary">
                        Close
      </Button>
                    <button onClick={completeDispositionDetails} className="btn btn-success">
                        Continue
      </button>
                </ModalFooter>
            </Modal >
            <Dialog
                dismissableMask={true}
                modal={zoomMode}
                header="Review Image"
                visible={zoomMode}
                className="imageModal"
                onHide={zoomToggle}
            >
                {zoomMode === true && (
                    <Row className="mb-3">
                        <Col lg="1" md="1" sm="1"></Col>
                        <Col lg="10" md="10" sm="10">
                            <InnerImageZoom
                                width="600"
                                height="600"
                                src={zoomImageSrc}
                                zoomSrc={zoomImageSrc}
                            />
                        </Col>
                    </Row>
                )}
            </Dialog>
        </Fragment >
    );
};

export default ArchivedDisplay;