import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Container, Row, Col, Card, Label, CardHeader, Button, CardBody, Table } from "reactstrap";
import { fetchReviewed } from "../../../../api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import moment from "moment";
import {
  ValidationForm} from "react-bootstrap4-form-validation";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import WaferItem from "./WaferItem";
import $ from "jquery";
import DataTable from "datatables.net";

toast.configure();

const ReviewCompletedReports = (props) => {
  const today = dayjs(new Date())
  var defaultToDate = new Date(dayjs(today).format("YYYY-MM-DD"));
  const defaultFromDate = new Date(dayjs(new Date()).subtract(30, 'day').format("YYYY-MM-DD"))

  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [wafersList, setWafersList] = useState([]);
  const [waferReviewJSON, setWaferReviewJSON] = useState([]);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    loadReviewedWafers();

  }, []);

  useEffect(() => {
    if (wafersList.length > 0) {

      if (!$.fn.DataTable.isDataTable('#fileUploadTable')) {
        $("#fileUploadTable").DataTable({
          "ordering": false,
        });
      }
    }
  }, [wafersList]);

  const loadReviewedWafers = () => {
    $('#fileUploadTable').dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&fromDate=${fromDate}&toDate=${toDate}`;
    fetchReviewed(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            setWafersList(res.data.data);
            setWaferReviewJSON(res.data.data);
          }
          else {
            setWafersList([]);
            setWaferReviewJSON([]);
          }
          // console.log(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          setWafersList([]);
          setWaferReviewJSON([]);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const setFilter = (event) => {
    event.preventDefault();
  };

  const clearFilter = (event) => {
    event.preventDefault();
    setFromDate(defaultFromDate);
    setToDate(defaultToDate);
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Review Completed Report" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Row>
              <Col lg="10">
                <ValidationForm onSubmit={setFilter} >
                  <Row>
                    <Col lg="4" className="text-left">
                      <Label htmlFor="filterVariableType">From Date</Label>
                      <DatePicker dateFormat="MM-dd-yyyy" placeholderText="Click to select a from date" maxDate={toDate === "" ? new Date() : toDate} startDate={fromDate} endDate={toDate} endDate={toDate} className="form-control" selected={fromDate} onChange={date => setFromDate(date)} />
                    </Col>
                    <Col lg="4" className="text-left">
                      <Label htmlFor="filterVariableType">To Date</Label>
                      <DatePicker dateFormat="MM-dd-yyyy" placeholderText="Click to select a to date" maxDate={new Date()} minDate={fromDate} startDate={fromDate} endDate={toDate} className="form-control" selected={toDate} onChange={date => setToDate(date)} />

                    </Col>
                    <Col lg="4" className="text-left">
                      <Button title="Add Filter" className="filter-btn" color="success" size="md" onClick={loadReviewedWafers}>
                        <i className="fa fa-filter"></i>
                      </Button>
                      <Button title="Clear Filter" onClick={clearFilter} className="filter-btn ml-2" color="danger" size="md">
                        <i className="fa fa-close"></i>
                      </Button>
                    </Col>
                  </Row>
                </ValidationForm>
              </Col>
              <Col lg="2" className="report-export">
                <Label>&nbsp; </Label>
                <CSVLink
                  data={
                    wafersList &&
                    waferReviewJSON &&
                    waferReviewJSON.map((wafer, index) => ({
                      "Lot Name": wafer.lot,
                      "Layer Name": wafer.layer.layerType,
                      "Review Start Date": moment(wafer.reviewStartDate).format(
                        "MM-DD-YYYY HH:mm"
                      ),
                      "Review Completed Date": moment(
                        wafer.reviewCompleteDate
                      ).format("MM-DD-YYYY HH:mm"),
                      "Disposition Start Date": moment(
                        wafer.disposeStartDate
                      ).format("MM-DD-YYYY HH:mm"),
                      "Disposition Completed Date": moment(
                        wafer.disposeCompleteDate
                      ).format("MM-DD-YYYY HH:mm"),
                      "Disposition Remark": moment(
                        wafer.dispositionRemark
                      ).format("MM-DD-YYYY HH:mm"),
                      // "submitted By": wafer.submittedBy,
                      "Defect Classification": wafer.defectClassification,
                    }))
                  }
                  filename="ReviewCompletedReport.csv"
                >
                  <button className="btn btn-info">
                    <i className="fa fa-download"></i>
                  </button>
                </CSVLink>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <div className="dash-status">
          <Card>
            <CardBody>

              <Table id="fileUploadTable" className="withoutSort dash-status-table" bordered responsive>
                <thead>
                  <tr>
                    <th className="halogenix-column">
                      Lot #
                      </th>
                    <th className="halogenix-column lot-col">Status</th>
                    <th className="halogenix-column lot-col">Layer</th>
                    <th className="halogenix-column lot-col">Area</th>
                    <th className="halogenix-column lot-col">Variable</th>
                    <th className="date-col halogenix-column">Added Time</th>
                    <th className="name-col halogenix-column">Wafer Created By</th>
                    <th className="name-col halogenix-column">Remarks</th>
                    <th className="halogenix-column"></th>
                  </tr>
                </thead>
                <tbody>
                  {wafersList.length > 0 ? wafersList.map((wafer, index) =>
                    <WaferItem key={index} wafer={wafer} />
                  ) :
                    <tr>
                      <td colSpan="12" className="text-center">
                        {" "}
                      No data found.{" "}
                      </td>
                      <td className="d-none"></td>
                      <td className="d-none"></td>
                      <td className="d-none"></td>
                      <td className="d-none"></td>
                      <td className="d-none"></td>
                      <td className="d-none"></td>
                      <td className="d-none"></td>
                      <td className="d-none"></td>
                    </tr>
                  }
                </tbody>
              </Table>
              {loading && (
                <div className="text-center">
                  <div className="loader-box">
                    <div className="loader-9"></div>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </Container>
    </Fragment>
  );
};

export default ReviewCompletedReports;

