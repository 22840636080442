import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import moment from "moment";
import { getWafers, addWafer, fetchActiveStatus, fetchActiveArea, fetchActiveVariable } from "../../../api";
import { toast } from 'react-toastify'
import ArchivedTable from "./ArchivedTable";
import $ from "jquery";
import DataTable from "datatables.net";

toast.configure();

const ArchivedPage = (props) => {

    const [loading, setLoading] = useState(true);
    const [wafersList, setWafersList] = useState([]);

    const [companyId, setCompanyId] = useState(
        localStorage.getItem('cid')
    );
    const [token, setToken] = useState(
        localStorage.getItem('token')
    );

    useEffect(() => {
        if (wafersList.length > 0) {

            if (!$.fn.DataTable.isDataTable('#archivedMainTable')) {
                $("#archivedMainTable").DataTable({
                    "ordering": false,
                });
            }

        }
    }, [wafersList]);

    useEffect(() => {
        loadWafers();

    }, [])

    const loadWafers = () => {
        $('#archivedMainTable').dataTable().fnDestroy();
        var tkn = `Bearer ${token}`;
        let data = `company=${companyId}&archived=true`;
        getWafers(data, tkn).then(res => {
            if (res.data.success === true) {
                //setWafersList(res.data.data);
                //.filter(x=> x.reviewType === "defects");
                const archivedWafer = res.data.data.filter(x => x.isDispositionCompleted === true && x.archived === true).sort((item1, item2) => item2.disposeCompleteDate - item1.disposeCompleteDate);
                console.log(archivedWafer);
                setWafersList(archivedWafer);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch(err => console.log(err.message))
    }


    return (
        <Fragment>
            <Breadcrumb parent="InXpector" title="Archived" />
            <Container className="wafer-container" fluid={true}>
                {/* <h6>Archived</h6> */}
                <Card>
                    <CardBody>

                        <Table id="archivedMainTable" className="withoutSort dash-status-table" bordered responsive>
                            <thead>

                                <tr>
                                    <th className="halogenix-column">S.No</th>
                                    <th className="halogenix-column lot-col">Lot #</th>
                                    <th className="halogenix-column">Status</th>
                                    <th className="halogenix-column lot-col">Layer</th>
                                    <th className="date-col halogenix-column">Wafer Created Time</th>
                                    <th className="name-col halogenix-column">FFI-Tech</th>
                                    <th className="name-col halogenix-column">Remarks</th>
                                    <th className="name-col halogenix-column">Area</th>
                                    <th className="name-col halogenix-column">Variable</th>
                                    {/* <th rowSpan="2" className="halogenix-column"></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {wafersList.length > 0 ? wafersList.map((wafer, index) => (
                                    <ArchivedTable key={index} wafer={wafer} index={index} />
                                ))
                                    :
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            {" "}
                      No data found.{" "}
                                        </td>
                                        <td className="d-none"></td>
                                        <td className="d-none"></td>
                                        <td className="d-none"></td>
                                        <td className="d-none"></td>
                                        <td className="d-none"></td>
                                        <td className="d-none"></td>
                                        <td className="d-none"></td>
                                        <td className="d-none"></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {loading && (
                            <div className="text-center">
                                <div className="loader-box">
                                    <div className="loader-9"></div>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
}

export default ArchivedPage;