import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import Chart from 'react-apexcharts'
import { getReviewCountByHours } from "../../../../api";
import { Colors } from "../../../../constant/index";
import { Container, Row, Col, Card, Label, CardHeader, Button, CardBody, Table, } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { ReviewChartTitle } from "../../../../constant/index";
import { ValidationForm } from "react-bootstrap4-form-validation";
import $ from 'jquery';


const ReviewCountByHours_Reports = (props) => {

    const today = dayjs(new Date());
    var defaultToDate = new Date(dayjs(today).format("YYYY-MM-DD"));
    const defaultFromDate = new Date(
        dayjs(new Date()).subtract(30, "day").format("YYYY-MM-DD")
    );

    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState(defaultToDate);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState({});
    const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
    const [token, setToken] = useState(
        localStorage.getItem('token')
    );

    useEffect(() => {
        loadReviewChart();
    }, [])


    const clearFilter = (event) => {
        event.preventDefault();
        setFromDate(defaultFromDate);
        setToDate(defaultToDate);
    };


    const setFilter = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        loadReviewChart();
    }, [])


    const loadReviewChart = () => {
        var tkn = `Bearer ${token}`;
        let data = `company=${companyId}&fromDate=${fromDate}&toDate=${toDate}`;
        getReviewCountByHours(data, tkn).then(res => {

            if (res.data && res.data.success === true) {
                const reportData = res.data.data;
                let countData = [], timeData = [];
                if (reportData && reportData.reviewsCountByHour.length > 0) {
                    let data = reportData.reviewsCountByHour;
                    data && data.map(d => {
                        countData.push(d.count);
                        timeData.push(d.time)
                    })

                } else {
                    countData.push(0);
                    timeData.push(0)
                }

                let seriesData = [
                    {
                        name: "Review: " + countData,
                        data: countData
                    },
                ]

                let optionsData = {
                    chart: {
                        id: 'reviewChart',
                        type: "bar",
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    stroke: {
                        show: true,
                        width: 2
                    },
                    colors: [Colors.open],
                    xaxis: {
                        type: "category",
                        categories: timeData,
                        labels: {
                            hideOverlappingLabels: true,
                            format: undefined,
                            formatter: function (timeData) {
                                return timeData
                            }
                        },

                    },
                    yaxis:
                    {
                        labels: {
                            formatter: function (countData) {
                                return countData;
                            }
                        }
                    },
                    legend: {
                        position: 'top',
                        itemMargin: {
                            vertical: 10,
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        x: {
                            format: timeData,
                        },
                        y: {
                            formatter: function (countData) {
                                return ": " + countData
                            },
                            title: {
                                formatter: function (value) {
                                    return value.split(":")[0]
                                },
                            },
                        }
                    },
                };

                setSeries(seriesData)
                setOptions(optionsData)
            }
        }).catch(err => console.log(err.message))
    }
    return (
        <Fragment>
            <Breadcrumb title="Reviews By Hours Report" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col lg="10">
                                <ValidationForm onClick={loadReviewChart}>
                                    <Row>

                                        <Col lg="4" className="text-left">
                                            <Label htmlFor="filterVariableType">From Date</Label>
                                            <DatePicker
                                                required
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Click to select a from date"
                                                maxDate={toDate === "" ? new Date() : toDate}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                className="form-control"
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                errorMessage={{ required: "From Date Is Required" }}
                                            />
                                        </Col>
                                        <Col lg="4" className="text-left">
                                            <Label htmlFor="filterVariableType">To Date</Label>
                                            <DatePicker
                                                required
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Click to select a to date"
                                                maxDate={new Date()}
                                                minDate={fromDate}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                className="form-control"
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                errorMessage={{ required: "To Date Is Required" }}

                                            />
                                        </Col>

                                        <Col lg="3" className="text-left">
                                            <Button
                                                title="Add Filter"
                                                className="filter-btn"
                                                color="success"
                                                size="md"
                                                onClick={setFilter}
                                            >
                                                <i className="fa fa-filter"></i>
                                            </Button>
                                            <Button
                                                title="Clear Filter"
                                                className="filter-btn ml-2"
                                                color="danger"
                                                onClick={clearFilter}
                                                size="md"
                                            >
                                                <i className="fa fa-close"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ValidationForm>
                            </Col>
                        </Row>
                    </CardHeader>
                </Card>
                <div className="dash-status">
                    <Card>
                        <CardBody>
                            <div id="ReviewChartTitle">
                                {
                                    series && series.length > 0 &&
                                    <Chart options={options} series={series} type="bar" width="100%" height={400} />
                                }
                            </div>
                            {loading && (
                                <div className="text-center">
                                    <div className="loader-box">
                                        <div className="loader-9"></div>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>

            </Container>
        </Fragment>

    );
};

export default ReviewCountByHours_Reports;