import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import CountUp from 'react-countup';
import { Database, ShoppingBag, Clock, User, Search, Archive } from 'react-feather';
import { fetchDash } from "../../../api";
import SVQCDailyChart from '../../../pages/chart/dailyChart'
import SVQCInspectionChart from '../../../pages/chart/inspectionChart'
import SVQCWeeklyChart from '../../../pages/chart/weeklyChart'
import { WaferDailyChartTitle, WaferLast30DaysChartTitle, WaferWeeklyChartTitle } from "../../../constant/index";

const AdminDashboard = (props) => {

  const [loading, setLoading] = useState(true);

  const [opencount, setOpencount] = useState([]);
  const [reviewcount, setReviewcount] = useState([]);
  const [archivedcount, setArchivedcount] = useState([]);
  const [usercount, setUsercount] = useState([]);
  const [completed, setCompleted] = useState([]);

  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );
  useEffect(() => {
    loadDash();
  }, [])

  const loadDash = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isDisposed=false&reviewCompleted=false`;
    fetchDash(data, tkn).then(res => {
      if (res.data.success === true) {

        // for (let i = 0; i < res.data.companyUserData.length; i++) {
        //   let user = res.data.companyUserData[i];
        //   if (user._id === true) {
        //     setUsercount(user.count);
        //   }
        // }

        if (res.data.companyUserData.length > 0) {
          setUsercount(res.data.companyUserData[0].companyUser);
        }
        for (let i = 0; i < res.data.Waferdata.length; i++) {
          let open = res.data.Waferdata[i];
          if (open._id === "Open") {
            setOpencount(open.count);
          }

          let review = res.data.Waferdata[i];
          if (review._id === "On Review") {
            setReviewcount(review.count);
          }

          let completed = res.data.Waferdata[i];
          if (completed._id === "Review Completed") {
            setCompleted(completed.count);
          }

          let archived = res.data.Waferdata[i];
          if (archived._id === "Archived") {
            setArchivedcount(archived.count);
          }
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(err => console.log(err.message))
  }


  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Admin Dashboard" />
      <Container fluid={true}>
        <Row>
          <Col sm="6" xl="4" lg="6">
            <Card className="o-hidden">
              <CardBody className="bg-primary b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><User /></div>
                  <div className="media-body"><span className="m-0">Active Users</span>
                    <h4 className="mb-0 counter"><CountUp end={usercount} /></h4><User className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" xl="4" lg="6">
            <Card className="o-hidden">
              <CardBody className="bg-secondary b-r-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><Clock /></div>
                  <div className="media-body"><span className="m-0">Wafers in Queue</span>
                    <h4 className="mb-0 counter"><CountUp end={opencount} /></h4><Clock className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <Card className="o-hidden">
              <CardBody className="bg-primary b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><Search /></div>

                  <div className="media-body"><span className="m-0">Under Inspection</span>
                    <h4 className="mb-0 counter"><CountUp end={reviewcount} /></h4><Search className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <Card className="o-hidden">
              <div className="bg-warning b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><ShoppingBag /></div>
                  <div className="media-body">
                    <span className="m-0">Inspection Completed</span>
                    <a href="/admin/disposition" style={{ color: "white" }}><h4 className="mb-0 counter"><CountUp end={completed} /></h4></a><ShoppingBag className="icon-bg" />
                  </div>
                </div>
              </div>
            </Card>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <Card className="o-hidden">
              <CardBody className="bg-success b-r-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><Archive /></div>
                  <div className="media-body"><span className="m-0">Archived</span>
                    <a href="/admin/archived" style={{ color: "white" }}> <h4 className="mb-0 counter"><CountUp end={archivedcount} /></h4></a><Archive className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>{WaferDailyChartTitle} </h5>
              </CardHeader>
              <CardBody>
                <div id="waferDailyChart">
                  <SVQCDailyChart />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>{WaferLast30DaysChartTitle} </h5>
              </CardHeader>
              <CardBody>
                <div id="waferDailyChart">
                  <SVQCInspectionChart />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>{WaferWeeklyChartTitle} </h5>
              </CardHeader>
              <CardBody>
                <div id="waferWeeklyChart">
                  <SVQCWeeklyChart />
                </div>
              </CardBody>
            </Card>
          </Col>
          
        </Row>
      </Container>
    </Fragment>
  );
}

export default AdminDashboard;