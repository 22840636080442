import React, { useEffect, useState } from "react";
import moment from "moment";

function WaferItem({ wafer }) {
  const [isExpandable, setIsExpandable] = useState(false);
  const [isDisExpandable, setIsDisExpandable] = useState(false);
  // const [waferItem, setWaferItem] = useState(null);

  const expandable = () => {
    if (isExpandable) setIsDisExpandable(false);
    setIsExpandable(!isExpandable);
  };

  const disExpandable = () => {
    setIsDisExpandable(!isDisExpandable);
  };


  useEffect(() => {
    // setWaferItem(wafer);
    // console.log(wafer);
  }, []);

  const waferStatusBadger = (status) => {
    // status = status.toLower;
    if (status === "Open") {
      return "badge badge-danger";
    } else if (status === "On Review") {
      return "badge badge-warning";
    } else {
      return "badge badge-success";
    }
  };
  const waferPriorityBadger = (priority) => {
    // status = status.toLower;
    if (priority === "Critical") {
      return "badge badge-danger";
    }else if (priority === "High") {
      return "badge badge-info";
    } else if (priority === "Medium") {
      return "badge badge-warning";
    } else {
      return "";
    }
  };

  return [
    <tr key={wafer._id}>
      <td className="text-center">
        <span className={waferStatusBadger(wafer.status)}>{wafer.status}</span>
      </td>
      <td className="text-center">
        <span className={waferPriorityBadger(wafer.highPriority)}>
          {wafer.lot}
        </span>
      </td>
      <td> {wafer.layer._id != null
        ? wafer.layer.layerType
        : wafer.layer}</td>
      <td>{wafer.area.areaType}</td>
      <td>{wafer.variable.variableType}</td>
      <td>{moment(wafer.createAt).format("MM-DD-YYYY HH:mm")}</td>
      <td>{wafer.user.name}</td>
      <td>{wafer.remarks}</td>
      <td>
        <span className="text-primary hand-cursor" onClick={() => expandable()}>
          Inspection Details  {!isExpandable ? <i className={`fa fa-angle-down`}></i> : <i className={`fa fa-angle-up`}></i>}
        </span>
      </td>
    </tr>,
    isExpandable && (
      <tr key={wafer._id + '1'}>
        <td colSpan="9">
          <table>
            <thead>
              <tr>
                <th className="date-col">Inspect Start Time</th>
                {/* <th className="name-col">BPO-Tech</th>
                <th className="lot-col">Log File</th> */}
                <th className="date-col">Completed Time</th>
                <th className="lot-col">Total Inspection Time</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{wafer.reviewInProcess && wafer.reviewCompleted === false ? moment(wafer.review.reviewStartDate).format("MM-DD-YYYY HH:mm") : '-'}</td>
                {/*<td>{wafer.review.user != null ? wafer.review.user.name : '-'}</td>
                <td>-</td> */}
                <td>{wafer.reviewCompleted ? moment(wafer.review.reviewStartDate).format("MM-DD-YYYY HH:mm") : '-'}</td>
                <td>-</td>
                {/* <td className="text-danger hand-cursor" onClick={() => disExpandable()}>
                  Disposition Details {!isDisExpandable ? <i className={`fa fa-angle-down`}></i> : <i className={`fa fa-angle-up`}></i>}
                </td> */}
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    ),
    // isDisExpandable && (
    //   <tr key={wafer._id + 'isD'}>
    //     <td colSpan="6">
    //       <table>
    //         <thead>
    //           <tr>
    //             <th className='date-col dispostion-column'>Downloaded Time</th>
    //             <th className='name-col dispostion-column'>FFI-Tech</th>
    //             <th className='date-col dispostion-column'>Completed Time</th>
    //             <th className='date-col dispostion-column'>Total Disposition Time</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           <tr>
    //             <td>-</td>
    //             <td>-</td>
    //             <td>-</td>
    //             <td>-</td>
    //           </tr>
    //         </tbody>
    //       </table>
    //     </td>
    //   </tr>
    // )
  ];
}

export default WaferItem;
