import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  UncontrolledCollapse,
  Label,
  Input,
  Badge,
} from "reactstrap";
import {
  ValidationForm,
  TextInput,
  FileInput,
  Checkbox,
  SelectGroup,
} from "react-bootstrap4-form-validation";
import ImageWithModal from "../../../../components/imageWithModal/ImageWithModal";
import $ from "jquery";
import DataTable from "datatables.net";
import validator from "validator";
import {
  getLMLots,
  addLot,
  fetchActiveStatus,
  fetchActiveLMArea,
  fetchActiveDesign,
  fetchActiveStage,
  fetchActiveLMLayer,
  updateLotStatus,
  updateLot
} from "../../../../api";
import { toast } from "react-toastify";
import WaferTable from "./WaferTable";
import WaferAssetUpload from "./WaferAssetUpload";
import moment from "moment";
import { TablePriorityStyle } from "../../../../helpers";
import { Colors } from "../../../../constant";
import StatusUpdate from "./statusUpdate";
toast.configure();

const LotPage = (props) => {
  const userrole = localStorage.getItem("role");
  const [showAddLot, setShowAddLot] = useState(false);
  
  const [statusModal, setStatusModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [lotsList, setLotsList] = useState([]);
  const [lot, setLot] = useState("");
  const [layer, setLayer] = useState("");
  const [email, setEmail] = useState("");
  const [remarks, setRemarks] = useState("");
  const [folderUrl, setFolderUrl] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [priority, setPriority] = useState("");
  const [inspectedImages, setInspectedImages] = useState(0);
  const [acceptedImages, setAcceptedImages] = useState(0);
  const [targetTime, setTargetTime] = useState("");
  const [existPictureList, seteExistPictureList] = useState([]);
  const [status, setStatus] = useState("");
  const [areaType, setAreaType] = useState("");
  const [lotId, setLotId] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [edit, setEdit] = useState(false);


  const [sideLoading, setSideLoading] = useState([]);

  const [designType, setDesignType] = useState("");
  const [designList, setDesignList] = useState([]);

  const [stageType, setStageType] = useState("");
  const [stageList, setStageList] = useState(false);

  // const [layerType, setLayerType] = useState("");
  // const [layerList, setLayerList] = useState([]);

  // const [picture, setPicture] = useState(null);
  const [pictureList, setPictureList] = useState([]);
  const [maxFilesReached, setMaxFilesReached] = useState(false);

  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const formRef = useRef(null);
  const filterFormRef = useRef(null);

  useEffect(() => {
    loadLots();
    getStatusList();
    getDesignList();
   
    if(userrole == "company-admin" || userrole == "company-user") { setShowAddLot(() => true)};
    const interval = setInterval(() => {
      loadLots();
    }, 10000);
  
    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    if (lotsList.length > 0) {
      if (!$.fn.DataTable.isDataTable("#waferMainTable")) {
        $("#waferMainTable").DataTable({
          ordering: false,
          "pageLength": 100
        });
      }
    }
  }, [lotsList]);


  const loadLots = () => {
    $("#waferMainTable").dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isDispositionCompleted=false`;
    getLMLots(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          setLotsList(res.data.data);
          // console.log(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getAreaList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const area = fetchActiveLMArea(data, tkn).then((res) => {
      if (res.data.success === true) {
        setAreaList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  };

  const getDesignList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}`;
    const status = fetchActiveDesign(data, tkn).then((res) => {
      if (res.data.success === true) {
        // console.log(res.data.data);
        setDesignList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  //  const getLayerList = (design) => {
  //   var tkn = `Bearer ${token}`;
  //   let data = `company=${companyId}&design=${design}`;
  //   const layers = fetchActiveLMLayer(data, tkn).then((res) => {
  //     if (res.data.success === true) {
  //       setLayerList(res.data.data);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  const getStageList = (design) => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&design=${design}`;
    const product = fetchActiveStage(data, tkn).then((res) => {
      if (res.data.success === true) {
        setStageList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getStatusList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const status = fetchActiveStatus(data, tkn).then((res) => {
      if (res.data.success === true) {
        setStatusList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  };

  const addLotDetails = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      lot: lot,
      area: areaType,
      stage: stageType,
      design: designType,
      // layer: layerType,
      targetTime:targetTime,
      remarks: remarks,
      highPriority: priority,
      status: "Open",
      email: email
    });
    addLot(body, tkn).then((res) => {
     
      clearValues();
      closeCustomizer();
      toast.success("Lot details added successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    });
  };

  const openCustomizer = () => {
    clearValues();
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    clearValues();
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  // const onAreaSelect = (e) => {
  //   setAreaType(e.target.value);
  //   setStageType("");
  //   setDesignType("");
  //   setLayerType("");
  //   getDesignList(e.target.value);
  //   // console.log(variableType);
  // };

  const onDesignSelect = (e) => {
    setDesignType(e.target.value);
    setStageType("");
    // setLayerType("");
    getStageList(e.target.value);
    // getLayerList(e.target.value);
    // console.log(variableType);
  };

  // const onLayerSelect = (e) => {
  //   setLayerType(e.target.value);
  //   setStageType("");
  //   getStageList(e.target.value);
  //   // console.log(variableType);
  // };
  const editLot = (lot) => {
    setSideLoading(true);
    openCustomizer();
    setEdit(true);
    // console.log(lot);
    setTargetTime(lot.targetTime);
    setStatus(lot.status);
    setLotId(lot._id);
    setEmail(lot.email);
    if (lot.status === "Open") {
      setIsDisabled(false);
    } else if (lot.status === "On Review") {
      setIsDisabled(false);

    } else {
      setIsDisabled(true);
    }
    setLot(lot.lot);
    getDesignList();
    // if (lot.area) {
    //   setAreaType(lot.area._id);
    //   getDesignList(lot.area._id);
    // }
    if (lot.design) {
      setDesignType(lot.design._id);
      getStageList(lot.design._id);
    }
    // if (lot.layer) {
    //   getStageList(lot.layer._id);
    //   setLayerType(lot.layer._id);
    // }
    if (lot.stage) {
      setStageType(lot.stage._id);
    }
    setPriority(lot.highPriority);

    setRemarks(lot.remarks);
    setSideLoading(false);
    
  };

  const deleteLotItem = (lot) => {
    setSideLoading(true);
    console.log(lot);
    var tkn = `Bearer ${token}`;
    // deleteLot(lot, tkn)
    //   .then((res) => {
    //     if (res.data.success) {
    //       toast.success("Lot deleted successfully.", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 5000,
    //       });
    //       clearValues();
    //     }else {
    //       toast.warning("Error deleting lot", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 5000,
    //       });
    //       clearValues();
    //       setEdit(false);
    //     }
    //   }).catch(function (response) {
    //     clearValues();
    //     console.log(response);
    //   });
  };
  
  const updateLotDetails = (event) => {
   
    event.preventDefault();
    closeCustomizer();
    setLoading(true);
    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      id: lotId,
      lot: lot,
      area: areaType,
      design: designType,
      stage: stageType,
      // layer: layerType,
      targetTime:targetTime,
      remarks: remarks,
      email: email,
      highPriority: priority,
    });

    console.log(body);
    updateLot(body, tkn)
      .then((res) => {
        if (res.data.success) {
          setEdit(false);
          toast.success("Livemore Lot details updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          clearValues();
        } else {
          toast.warning("Error in updating Livemore Lot", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          clearValues();
          setEdit(false);
        }
      })
      .catch(function (response) {
        //handle error
        clearValues();
        console.log(response);
      });
  };

  const ToggleStatusUpdateModal = (lot) => {
    setStatusModal(!statusModal);
    
    if (statusModal == false) {
      setStatus(lot.status);
      setTargetTime(lot.targetTime);
      setLotId(lot._id);
      setDesignType(lot.design._id);
      setIsDisabled(false);
      setInspectedImages(lot.nInspectedImages);
      setAcceptedImages(lot.nAcceptedImages);
      // setAreaType(lot.area._id);
      // setLayerType(lot.layer._id);
      setStageType(lot.stage._id);
      setPriority(lot.highPriority);
      setLot(lot.lot);
      setEmail(lot.email);
      setRemarks(lot.remarks);
      setSideLoading(false);
    }
  }

  const updateLotStatusLocal = (event) => {

     setLoading(true);
    event.preventDefault();
    var tkn = `Bearer ${token}`;
    if(status == "Review Completed"){
      // setInspectedImages(0);
      // setAcceptedImages(0);
      if (parseInt(inspectedImages) >= parseInt(acceptedImages)) {
      } else {
        toast.error(
          "Accepted images should not be more than inspected images.",
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          }
        );
        return;
      }
    }
    const body = JSON.stringify({
      company: companyId,
      id: lotId,
      lot: lot,
      // area: areaType,
      design: designType,
      stage: stageType,
      // layer: layerType,
      targetTime:targetTime,
      remarks: remarks,
      highPriority: priority,
      status: status,
      email: email,
      nInspectedImages: inspectedImages,
      nAcceptedImages: acceptedImages 
    });
    
    // if(parseInt(inspectedImages) >= parseInt(acceptedImages) || parseInt(inspectedImages) == NaN){
    updateLotStatus(body, tkn)
      .then((res) => {
        if (res.data.success) {
          loadLots();
          toast.success("Livemore Lot details updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          
        } else {
          toast.warning("Error in updating Livemore Lot", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setStatusModal(true);
        }
      })
      .catch(function (response) {
        setStatusModal(true);
      });
      setStatusModal(false);
      clearValues();
    // }else{
    //   toast.error("Accepted images should not be more than inspected images.", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //     autoClose: 5000,
    //   });
    // }
  }

  const clearValues = () => {
    loadLots();
    setLoading(false);
    setIsDisabled(false);
    setLot("");
    setLayer("");
    setEdit(false);
    setFolderUrl("");
    setRemarks("");
    setPriority("");
    setTargetTime("");
    setStatus("");
    setStageList([]);
    seteExistPictureList([]);
    setStageList([]);
    setDesignList([]);
    // setLayerList([]);
    setDesignType("");
    setStageType("");
    // setLayerType("");
    setEmail("");
    setAcceptedImages(0);
    setInspectedImages(0);
    setPictureList([]);
    getDesignList();
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  };

  const waferPriorityBadger = (priority) => {
    // status = status.toLower;
    if (priority === "Critical") {
      return "badge badge-danger";
    }
    else if (priority === "High") {
      return "badge badge-info";
    } else if (priority === "Medium") {
      return "badge badge-warning";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Livermore Lot Details" />
      <Container className="wafer-container" fluid={true}>
        <Card>
          <CardHeader>
            {showAddLot &&
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add Livermore Lot
            </Button>
            }
            <div>
              <span className={`badge  text-dark table-legend border`}>
                Low Priority
              </span>
              <span className={`badge badge-warning text-white table-legend`}>
                Medium Priority
              </span>
              <span className={`badge badge-info text-white table-legend`}>
                High Priority
              </span>
              <span className={`badge badge-danger text-white table-legend`}>
                Critical
              </span>
              {/* <span className={`badge badge-primary text-white table-legend`}>
                  Open
                </span>
                <span className={`badge badge-primary text-white table-legend`}>
                  Close
                </span> */}
            </div>
          </CardHeader>
          <CardBody>
            <Table
              id="waferMainTable"
              className="withoutSort dash-status-table"
              bordered
              responsive
            >
              <thead>
                {/* <tr>
                   
                    <th colSpan='5' className='halogenix-column'>Halogenix File Upload</th>
                    <th rowSpan="2"  className='halogenix-column'></th>
                    
                  </tr> */}
                <tr>
                  <th className="halogenix-column">S.No&nbsp;&nbsp;</th>
                  <th className="halogenix-column lot-col">Lot #</th>
                  <th className="halogenix-column">Target (hrs)</th>
                  <th className="halogenix-column">Status</th>
                  {/* <th className="halogenix-column">Area</th>
                   <th className="halogenix-column">Design</th>*/}
                  <th className="halogenix-column">Part ID</th>
                  <th className="halogenix-column">Stage</th> 
                  <th className="date-col halogenix-column">Added Time</th>
                  <th className="name-col halogenix-column">Added By</th>
                  <th className="name-col halogenix-column">Remarks</th>
                  <th className="halogenix-column"></th>
                </tr>
              </thead>
              <tbody>
                {lotsList.length > 0 ? (
                  lotsList.map((lot, index) => (
                    <tr key={lot._id}>
                      {/* <td>{index + 1}</td> */}
                      <td
                        style={{
                          borderLeft: TablePriorityStyle(lot.highPriority),
                        }}
                        className="text-center"
                      >
                        <span className="text-primary">{index + 1} </span>
                      </td>

                      <td
                        onClick={() => ToggleStatusUpdateModal(lot)}
                        className="text-center"
                      >
                        <span className={waferPriorityBadger(lot.highPriority)}>
                          {lot.lot}
                        </span>
                      </td>
                      <td className="text-center">{lot.targetTime}</td>
                      <td
                        onClick={() => ToggleStatusUpdateModal(lot)}
                        className="text-center"
                      >
                        <span
                          style={{
                            backgroundColor:
                              lot.status === "Open"
                                ? Colors.open
                                : Colors.onreview,
                          }}
                          className="badge badge-danger"
                        >
                          {" "}
                          {lot.status}
                        </span>
                      </td>

                      {/* <td>{lot.area.areaType}</td>
                      <td>{lot.design.designType}</td>*/}
                      <td>{lot.design.designType}</td>
                      <td>{lot.stage.stageType}</td> 

                      <td>{moment(lot.createAt).format("MM-DD-YYYY HH:mm")}</td>
                      <td>{lot.user.name}</td>
                      <td>{lot.remarks}</td>
                      <td className="text-center">
                        <i
                          onClick={() => editLot(lot)}
                          className="icon fa fa-pencil-square"
                        ></i>
                      </td>
                    </tr>
                    // <WaferTable
                    //   key={index}
                    //   lot={lot}
                    //   editLot={editLot}
                    //   deleteLotItem={deleteLotItem}
                    //   index={index}
                    // />
                    //   <tr key={lot._id}>
                    //   <td>{index + 1}</td>
                    //   <td><span className={lot.highPriority ? `badge badge-danger` : ``}>{lot.lot}</span></td>

                    //   <td>{lot.status}</td>
                    //   <td>{moment(lot.createAt).format('MM-DD-YYYY HH:mm')}</td>
                    //   <td>{lot.user.name}</td>
                    //   <td>{lot.remarks}</td>
                    //   <td className="text-center">
                    //     <i className="icon fa fa-edit"></i>
                    //   </td>

                    // </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                  </tr>
                )}
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain extended-side-container">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} LiverMore Lot</h6>
        </div>

        <ValidationForm
          onSubmit={edit ? updateLotDetails : addLotDetails}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="lotLot">Lot #</Label>
                <TextInput
                  className="form-control"
                  required
                  name="lotLot"
                  type="text"
                  placeholder="Enter Lot #"
                  errorMessage={{ required: "Lot Is Required" }}
                  disabled={isDisabled ? true : false}
                  value={lot}
                  onChange={(e) => setLot(e.target.value)}
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="lotDesign">Part ID</Label>
                <SelectGroup
                  type="select"
                  name="lotDesign"
                  onChange={(e) => onDesignSelect(e)}
                  disabled={isDisabled ? true : false}
                  errorMessage={{ required: "Part ID is Required" }}
                  className="form-control digits"
                  selected={designType}
                  value={designType}
                >
                  <option value="">{"Select Part ID"}</option>
                  {designList &&
                    designList.map((design, index) => (
                      <option value={design._id} key={design._id}>
                        {design.designType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>

            

              <FormGroup>
                <Label htmlFor="lotStage">Stage</Label>
                <SelectGroup
                  type="select"
                  disabled={isDisabled ? true : false}
                  name="lotStage"
                  onChange={(e) => setStageType(e.target.value)}
                  required
                  errorMessage={{ required: "Stage Is Required" }}
                  className="form-control digits"
                  selected={stageType}
                  value={stageType}
                >
                  <option value="">{"Select Stage"}</option>
                  {stageList &&
                    stageList.map((stage, index) => (
                      <option value={stage._id} key={stage._id}>
                        {stage.stageType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="TargetTime">Target Time (hour)</Label>
                <TextInput
                  className="form-control"
                  name="targetTime"
                  type="text"
                  placeholder="Enter Target Time"
                  errorMessage={{ required: "Target Time Is Required" }}
                  disabled={isDisabled ? true : false}
                  value={targetTime}
                  onChange={(e) => setTargetTime(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="Priority">Priority</Label>
                <SelectGroup
                  type="select"
                  name="Priority"
                  onChange={(e) => setPriority(e.target.value)}
                  required
                  errorMessage={{ required: "Priority Is Required" }}
                  className="form-control priority"
                  selected={priority}
                  value={priority}
                >
                  <option value="">{"Select Priority"}</option>
                  <option value="Low">{"Low"}</option>
                  <option value="Medium">{"Medium"}</option>
                  <option value="High">{"High"}</option>
                  <option value="Critical">{"Critical"}</option>
                </SelectGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="lotEmail">Email</Label>
                <TextInput
                  value={email}
                  className="form-control"
                  multiline
                  rows="3"
                  name="lotEmail"
                  disabled={isDisabled ? true : false}
                  placeholder="email"
                  errorMessage={{ required: "Email Is Required" }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="lotRemark">Remarks</Label>
                <TextInput
                  value={remarks}
                  className="form-control"
                  multiline
                  rows="3"
                  name="lotRemark"
                  disabled={isDisabled ? true : false}
                  placeholder="remarks"
                  errorMessage={{ required: "Remark Is Required" }}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </FormGroup>
              
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                <Button className="success">
                  {edit ? `Update` : `Add`} Lot
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>

        <StatusUpdate
          isOpen={statusModal}
          title={"Update Status"}
          toggler={ToggleStatusUpdateModal}
        >
             <ValidationForm onSubmit={(e) => updateLotStatusLocal(e)} ref={filterFormRef}>
          <FormGroup>
            
          <Row>
            <Col>
            <Label htmlFor="status-type">Status - <span className="text-primary"> {lot}</span></Label>
            <Input
              type="select"
              name="select"
              onChange={(e) => setStatus(e.target.value)}
              className="form-control digits"
              selected={status}
              value={status}
            >
              <option value="">{"Select Status"}</option>
              <option value="Open">Open</option>
              <option value="On Review">On Review</option>
              <option value="Review Completed">Review Completed</option>
              <option value="Closed">Closed</option>
              {/* {statusList &&
                statusList.map((status, index) => (
                  <option key={status._id}>{status.statusType}</option>
                ))} */}
            </Input>
            </Col>
          </Row>
          {status == "Review Completed" && 
          <>
            <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="nInspectedImgs">Number of Inspected Images</Label>
                <TextInput
                  value={inspectedImages}
                  className="form-control"
                  type="number"
                  name="nInspectedImgs"
                  required
                  placeholder="no. of inspected images"
                  errorMessage={{ required: "Number of Inspected Images is Required" }}
                  onChange={(e) => setInspectedImages(e.target.value)}
                />
              </FormGroup>
              
            </Col>
          </Row>
           <Row>
           <Col>
             <FormGroup>
               <Label htmlFor="nAcceptedImgs">Number of Accepted Images</Label>
               <TextInput
                  required
                 value={acceptedImages}
                 type="number"
                 className="form-control"
                 name="nAcceptedImgs"
                 placeholder="no. of accepted images"
                 errorMessage={{ required: "Number of Accepted Images is Required" }}
                 onChange={(e) => setAcceptedImages(e.target.value)}
               />
             </FormGroup>
             
           </Col>
         </Row>
         </>
    }
          </FormGroup>
          <Row>
            <Col>
              <FormGroup className="text-center">
                <Button className="success">
                Update Lot Status
                </Button>
              </FormGroup>
            </Col>
          </Row>
          </ValidationForm>
        </StatusUpdate>
      </div>
    </Fragment>
  );
};

export default LotPage;
