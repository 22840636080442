import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import {
  ValidationForm,
  FileInput,
  TextInput,
  SelectGroup,
  Checkbox,
} from "react-bootstrap4-form-validation";
import {
  fetchActiveDefetcts,
  fetchActiveArea,
  createNewDefect,
  updateDefect,
  addDefectImage,
} from "../../../../api";
import { toast } from "react-toastify";
import $ from "jquery";
import DataTable from "datatables.net";
toast.configure();

const DefectsMasterPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState({
    isOpen: false,
    imageName: "",
    title: "",
  });
  const [rightSidebar, setRightSidebar] = useState(true);
  const [defectsList, setDefectsList] = useState([]);
  const [defectId, setDefectId] = useState([]);
  const [defectsType, setDefectsType] = useState("");
  const [editImgUrl, setEditImgUrl] = useState("");
  const [filterAreaType, setFilterAreaType] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [picture, setPicture] = useState(null);
  const [areaType, setAreaType] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [edit, setEdit] = useState(false);

  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const formRef = useRef(null);
  const filterFormRef = useRef(null);

  const toggle = () =>
    setImagePreview({ isOpen: !imagePreview.isOpen, imageName: "", title: "" });

  useEffect(() => {
    getAreaList();
    getDefetctsList();
  }, []);

  useEffect(() => {
    if (defectsList.length > 0) {
      $("#defectsTable").dataTable({
        columnDefs: [
          {
            targets: [1, 5], // column index (start from 0)
            orderable: false, // set orderable false for selected columns
          },
        ],
      });
    }
  }, [defectsList]);

  const getAreaList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const area = fetchActiveArea(data, tkn).then((res) => {
      if (res.data.success === true) {
        setAreaList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  };

  const getDefetctsList = () => {
    clearFilter();
    $("#defectsTable").dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    const data = `company=${companyId}`;
    fetchActiveDefetcts(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          setDefectsList(res.data.data);
          // console.log(res.data)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const addDefect = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      area: areaType,
      defectsType: defectsType,
      isActive: isActive,
    });
    createNewDefect(body, tkn).then((res) => {
      if (res.data.success) {
        // console.log(res.data.data);
        const formData = new FormData();
        // console.log(picture);
        formData.append("file", picture);
        formData.append("name", defectsType);

        addDefectImage(res.data.data._id, tkn, formData)
          .then((response) => {
            // console.log(formData);
            if (response.data.success) {
              getDefetctsList();
              toast.success("Defect details added successfully.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              clearValues();
              setLoading(false);
              closeCustomizer();
            } else {
              console.log("defect image adding error");
              //error in adding image
              closeCustomizer();
            }
          })
          .catch(function (response) {
            //handle error
            clearValues();
            console.log(response);
            closeCustomizer();
          });
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    });
  };

  const clearValues = () => {
    setLoading(false);
    setDefectsType("");
    setPicture(null);
    setAreaType("");
    setIsActive(true);
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  };

  const openDefectSideBar = () => {
    //setEdit(false);
    clearValues();
    openCustomizer();
  };
  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    clearValues();
    setEdit(false);
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editDefects = (defect) => {
    setEdit(true);
    setDefectId(defect._id);
    setDefectsType(defect.defectsType);
    setEditImgUrl(defect.defectsImage);
    if (defect.area) {
      setAreaType(defect.area._id);
    }
    setIsActive(defect.isActive);
    openCustomizer();
  };

  const updateDefectProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      area: areaType,
      defectsType: defectsType,
      isActive: isActive,
    });
    updateDefect(defectId, body, tkn).then((res) => {
      if (res.data.success) {
        if (picture !== null) {
          const formData = new FormData();
          // console.log(picture);
          formData.append("file", picture);
          formData.append("name", defectsType);

          addDefectImage(res.data.data._id, tkn, formData)
            .then((response) => {
              // console.log(formData);
              if (response.data.success) {
                getDefetctsList();
                setEdit(false);
                closeCustomizer();
                toast.success("Defect details updated successfully.", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                clearValues();
                setLoading(false);
              } else {
                toast.warning(res.data.data, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                setLoading(false);
              }
            })
            .catch(function (response) {
              //handle error
              toast.warning(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              });
              closeCustomizer();
              clearValues();
              setLoading(false);
              console.log(response);
            });
        } else {
          if (res.data.success) {
            //getDefetctsList();
            toast.success("Defect details updated successfully.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            closeCustomizer();
            getDefetctsList();
            clearValues();
            setEdit(false);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } else {
        toast.warning(res.data.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(false);
      }
    });
    // closeCustomizer();
    //getDefetctsList();

    //setEdit(false);
  };
  const assignImage = (event) => {
    if (event.target.files[0]) {
      //const imgSrc = URL.createObjectURL(event.target.files[0]);
      //setPicture(imgSrc);
      setPicture(event.target.files[0]);
    }
  };

  const onAreaSelect = (e) => {
    setAreaType(e.target.value);
  };

  const onFilterAreaSelect = (e) => {
    setFilterAreaType({
      value: e.target.value,
      text: e.target.options[e.target.selectedIndex].text,
    });
  };
  const loadAssyByFilter = (event) => {
    event.preventDefault();
    var table = $("#defectsTable").DataTable();
    if (filterAreaType.value !== "") {
      table.columns(2).search(filterAreaType.text).draw();
    } else {
      table.search("").columns().search("").draw();
    }
  };

  const clearFilter = (e) => {
    var table = $("#defectsTable").DataTable();
    setFilterAreaType({ value: "", text: "" });
    table.search("").columns().search("").draw();
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Defects Master" />
      <Container className="master-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openDefectSideBar} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add Defects
            </Button>
          </CardHeader>
        </Card>
        <Card>
        {defectsList.length>0&&( <CardHeader>
            <ValidationForm onSubmit={loadAssyByFilter} ref={filterFormRef}>
              <Row>
                <Col lg="4" className="text-left">
                  <Label>Select Area</Label>
                  <SelectGroup
                    type="select"
                    name="waferArea"
                    value={filterAreaType.value}
                    errorMessage={{ required: "Area Is Required" }}
                    onChange={(e) => onFilterAreaSelect(e)}
                    className="form-control digits"
                    selected={filterAreaType.value}
                  >
                    <option value="">{"All"}</option>
                    {areaList &&
                      areaList.map((area, index) => (
                        <option
                          text={area.areaType}
                          value={area._id}
                          key={area._id}
                        >
                          {area.areaType}
                        </option>
                      ))}
                  </SelectGroup>
                </Col>
                <Col lg="6" className="text-left mr-t-2">
                  <Button
                    title="Add Filter"
                    className="filter-btn"
                    color="success"
                    size="md"
                  >
                    <i className="fa fa-filter"></i>
                  </Button>
                  <Button
                    title="Clear Filter"
                    onClick={clearFilter}
                    className="filter-btn ml-2"
                    color="danger"
                    size="md"
                  >
                    <i className="fa fa-close"></i>
                  </Button>
                </Col>
              </Row>
            </ValidationForm>
          </CardHeader>)}
          <CardBody>
            <Table
              id="defectsTable"
              className="master-table"
              bordered
              responsive
            >
              <thead>
                <tr>
                  <th>S.No&nbsp;&nbsp;</th>
                  <th className="name-col">Sample Image</th>
                  <th className="name-col">Area Type</th>
                  <th className="name-col">Defects Type</th>
                  {/* <th>Created On</th> */}
                  <th>Active?&nbsp;&nbsp;</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {defectsList.length>0?defectsList.map((defect, index) => (
                  <tr key={defect._id}>
                    <td>{index + 1}</td>
                    <td className="text-center">
                    <div className="mouse-hand"> 
                      <img
                        className="img-thumbnail defect-image"
                        alt={defect.defectsType}
                        // src={`/images/${defect.defectsImage}`}
                        src={`https://d3lkxr26kk0rq7.cloudfront.net/${defect.defectsImage}`}
                        onClick={() =>
                          setImagePreview({
                            isOpen: true,
                            imageName: defect.defectsImage,
                            title: defect.defectsType,
                          })
                        }
                      />
                      </div>
                    </td>
                    <td>{defect.area ? defect.area.areaType : ""}</td>
                    <td>{defect.defectsType}</td>
                    {/* <td>
                      {moment(defect.createAt).format("MM-DD-YYYY")}{" "}
                      {moment(defect.createAt).format("hh:mm")}
                    </td> */}
                    <td data-order={defect.isActive} className="text-center">
                      {defect.isActive ? (
                        <i className="icon-green fa fa-check-square-o"></i>
                      ) : (
                        <i className="icon fa fa-minus-circle"></i>
                      )}
                    </td>
                    <td className="text-center">
                      <i
                        onClick={() => editDefects(defect)}
                        className="icon fa fa-pencil-square"
                      ></i>
                    </td>
                  </tr>
                )):
                <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                  </tr>
                }
              </tbody>
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add`} Defects Type</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateDefectProcess : addDefect}
          ref={formRef}
          className="theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="waferArea">Area</Label>
                <SelectGroup
                  type="select"
                  name="waferArea"
                  value={areaType}
                  required
                  errorMessage={{ required: "Area Is Required" }}
                  onChange={(e) => onAreaSelect(e)}
                  className="form-control digits"
                >
                  <option value="">{"Select Area"}</option>
                  {areaList &&
                    areaList.map((area, index) => (
                      <option value={area._id} key={area._id}>
                        {area.areaType}
                      </option>
                    ))}
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="defect-type">Defects Type</Label>
                <TextInput
                  required
                  className="form-control"
                  type="text"
                  name="defect-type"
                  errorMessage={{ required: "Defects Type Is Required" }}
                  placeholder="Enter Defect Type"
                  value={defectsType}
                  onChange={(e) => setDefectsType(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="defect-image">Defects Sample Image</Label>
                {edit ? (
                  <FileInput
                    //required
                    className="form-control"
                    //errorMessage={{ required: "Defects Image Is Required" }}
                    type="file"
                    name="defect-image"
                    placeholder="defect image"
                    onChange={(e) => assignImage(e)}
                  />
                ) : (
                  <FileInput
                    required
                    className="form-control"
                    errorMessage={{ required: "Defects Image Is Required" }}
                    type="file"
                    name="defect-image"
                    placeholder="defect image"
                    onChange={(e) => assignImage(e)}
                  />
                )}

                {/* <FileInput
                  required
                  className="form-control"
                  errorMessage={{ required: "Defects Image Is Required" }}
                  type="file"
                  name="defect-image"
                  placeholder="defect image"
                  onChange={(e) => assignImage(e)}
                /> */}
                {picture != null && (
                  <img
                    className="img-thumbnail defect-image"
                    src={URL.createObjectURL(picture)}
                  />
                )}
                {edit && picture === null ? (
                  <img
                    className="img-thumbnail defect-image"
                    src={`https://d3lkxr26kk0rq7.cloudfront.net/${editImgUrl}`}
                  />
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="master-pl-0">
                <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={(e) => setIsActive(!isActive)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update Defects
                  </Button>
                ) : (
                  <Button disabled={loading} className="success">
                    Add Defects
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
      <Modal isOpen={imagePreview.isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{imagePreview.title}</ModalHeader>
        <ModalBody>
          <img
            className="img-thumbnail"
            style={{ width: "100%" }}
            src={`https://d3lkxr26kk0rq7.cloudfront.net/${imagePreview.imageName}`}
            // src={`/iamges/${imagePreview.imageName}`}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default DefectsMasterPage;
