import React, { useEffect, useState } from "react";


function DisplayRemarks({ reviewDataList, currentSelectedIndex }) {

    const [isExpandable, setIsExpandable] = useState(false);
    const [isDisExpandable, setIsDisExpandable] = useState(false);
    const [waferItem, setWaferItem] = useState(null);



    useEffect(() => {
        // setWaferItem(wafer);
        // console.log(wafer);
    }, []);



    return [

        // reviewDataList &&
        // reviewDataList.review.reviewData &&
        // reviewDataList.review.reviewData.length > 0 &&
        // reviewDataList.review.reviewData.forEach((rev) => {
        //     if (
        //         rev.lastReviewedImageIndex === currentSelectedIndex
        //     ) {
        //         // return (

        //                 <div className="inspection-stats">
        //             Inspection Remark :{" "}
        //                     <span className="color-light">
        //                         {rev.reviewRemark}
        //                     </span>
        //                 </div>
        //                 <div className="inspection-stats">
        //                     <span className="badge badge-success">
        //                     Reviewed
        //                 </span>
        //                 </div>

        //         // );
        //     } else {
        //         <></>
        //     }
        // })
    ];
}

export default DisplayRemarks;
