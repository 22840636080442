import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";
import { getWafers, addWafer, fetchActiveStatus, fetchActiveArea, fetchActiveVariable } from "../../../api";
import { toast } from 'react-toastify'
import DispositionTable from "./DispositionTable";
import $ from "jquery";
import DataTable from "datatables.net";

toast.configure();

const DispositionPage = (props) => {

  const [loading, setLoading] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [wafersList, setWafersList] = useState([]);
  const [processingDispositionList, setProcessingDispositionList] = useState([]);
  const [newDispositionList, setNewDispositionList] = useState([]);
  const [lot, setLot] = useState("");
  const [layer, setLayer] = useState("");
  const [remarks, setRemarks] = useState("");
  const [folderUrl, setFolderUrl] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [highPriority, setHighPriority] = useState(false);
  const [status, setStatus] = useState('');
  const [areaType, setAreaType] = useState("");
  const [areaList, setAreaList] = useState([]);

  const [variableType, setVariableType] = useState("");
  const [variableList, setVariableList] = useState([]);


  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );

  useEffect(() => {
    loadWafers();
    getStatusList();
    getAreaList();

  }, [])

  useEffect(() => {
    if (newDispositionList.length > 0) {

      if (!$.fn.DataTable.isDataTable('#dispositionStartTable')) {
        $("#dispositionStartTable").DataTable({
          "ordering": false,
          "pageLength": 100
        });
      }

    }
  }, [newDispositionList]);

  useEffect(() => {
    if (processingDispositionList.length > 0) {

      if (!$.fn.DataTable.isDataTable('#underDispositionTable')) {
        $("#underDispositionTable").DataTable({
          "ordering": false,
          "pageLength": 100
        });
      }

    }
  }, [processingDispositionList]);


  const loadWafers = () => {
    $('#dispositionStartTable').dataTable().fnDestroy();
    $('#underDispositionTable').dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isDisposed=false&reviewCompleted=true`;
    getWafers(data, tkn).then(res => {
      if (res.data.success === true) {
        setWafersList(res.data.data);
        setProcessingDispositionList(res.data.data.filter(x => x.reviewCompleted === true && x.dispositionInprocess === true));
        setNewDispositionList(res.data.data.filter(x => x.reviewCompleted === true && x.dispositionInprocess === false));
        // console.log(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(err => console.log(err.message))
  }

  const getAreaList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const area = fetchActiveArea(data, tkn).then((res) => {
      if (res.data.success === true) {
        setAreaList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  }

  const getVariableList = (area) => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&area=${area}`;
    const status = fetchActiveVariable(data, tkn).then((res) => {
      if (res.data.success === true) {
        setVariableList(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }


  const getStatusList = () => {
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&isActive=true`;
    const status = fetchActiveStatus(data, tkn).then((res) => {
      if (res.data.success === true) {
        setStatusList(res.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  }


  const addWaferDetails = () => {
    setLoading(true);
    console.log(status);
    if (lot === '' || layer === '' || folderUrl === '' || status === '-1' || areaType === '-1' || variableType === '-1') {
      toast.error("Please enter all values", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      setLoading(false);
    } else {
      var tkn = `Bearer ${token}`;
      const body = JSON.stringify({
        company: companyId,
        lot,
        layer,
        remarks,
        folderUrl,
        highPriority,
        status,
        area: areaType,
        variable: variableType
      });
      console.log(body);
      addWafer(body, tkn).then((res) => {
        loadWafers();
        setLoading(false);
        setLot('');
        setLayer('');
        setFolderUrl('');
        setRemarks('');
        setHighPriority(false);
        setStatus("-1");
        setAreaType("-1");
        setVariableType("-1");
        toast.success("Wafer details added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      });
    }
  }

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const onStatusSelect = (e) => {
    setAreaType(e.target.value);
    setVariableType("-1");
    getVariableList(e.target.value);
    // console.log(variableType);
  }

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Disposition" />
      <Container className="wafer-container" fluid={true}>

        {
          newDispositionList &&
          <>
            <h6>Start To Disposition</h6>
            <Card>

              <CardBody>
                <Table id="dispositionStartTable" className="withoutSort dash-status-table" bordered responsive>
                  <thead>
                    {/* <tr>
                   
                    <th colSpan='5' className='halogenix-column'>Halogenix File Upload</th>
                    <th rowSpan="2"  className='halogenix-column'></th>
                    
                  </tr> */}
                    <tr>
                      <th className="halogenix-column">S.No</th>
                      <th className="halogenix-column">Status</th>
                      {/* <th className="halogenix-column"></th> */}
                      <th className="halogenix-column lot-col">Lot #</th>
                      <th className="halogenix-column lot-col">Area</th>
                      <th className="date-col halogenix-column">Wafer Created Time</th>
                      <th className="name-col halogenix-column">FFI-Tech</th>
                      <th className="name-col halogenix-column">Remarks</th>
                      {/* <th rowSpan="2" className="halogenix-column"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {newDispositionList.length > 0 ? newDispositionList.map((wafer, index) => (

                      !wafer.dispositionInprocess && wafer.reviewCompleted &&
                      (
                        <DispositionTable key={index} wafer={wafer} index={index} />
                      )
                    )) :
                      <tr>
                        <td colSpan="11" className="text-center">
                          {" "}
                        No data found.{" "}
                        </td>
                        <td className="d-none"></td>
                        {/* <td className="d-none"></td> */}
                        <td className="d-none"></td>
                        <td className="d-none"></td>
                        <td className="d-none"></td>
                        <td className="d-none"></td>
                        <td className="d-none"></td>

                      </tr>
                    }
                  </tbody>
                </Table>
                {loading && (
                  <div className="text-center">
                    <div className="loader-box">
                      <div className="loader-9"></div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </>
        }

        {processingDispositionList &&
          <>
            <h6>Under Disposition</h6>
            <Card>

              <CardBody>
                <Table id="underDispositionTable" className="withoutSort dash-status-table" bordered responsive>
                  <thead>

                    <tr>
                      <th className="halogenix-column">S.No</th>
                      <th className="halogenix-column lot-col">Lot #</th>
                      {/* <th className="halogenix-column">Defects</th> */}
                      <th className="halogenix-column">Status</th>
                      <th className="halogenix-column lot-col">Layer</th>
                      <th className="date-col halogenix-column">Wafer Created Time</th>
                      <th className="name-col halogenix-column">FFI-Tech</th>
                      <th className="name-col halogenix-column">Remarks</th>
                      {/* <th rowSpan="2" className="halogenix-column"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {processingDispositionList.length > 0 ? processingDispositionList.map((wafer, index) => (
                      wafer.dispositionInprocess && wafer.reviewCompleted &&
                      (
                        <DispositionTable key={index} wafer={wafer} index={index} />
                      )
                    ))
                      :
                      <tr>
                        <td colSpan="11" className="text-center">
                          {" "}
                          No data found.{" "}
                        </td>
                        <td className="d-none"></td>
                        {/* <td className="d-none"></td> */}
                        <td className="d-none"></td>
                        <td className="d-none"></td>
                        <td className="d-none"></td>
                        <td className="d-none"></td>
                        <td className="d-none"></td>
                      </tr>
                    }
                  </tbody>
                </Table>
                {loading && (
                  <div className="text-center">
                    <div className="loader-box">
                      <div className="loader-9"></div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </>
        }
      </Container>

    </Fragment>
  );
}

export default DispositionPage;