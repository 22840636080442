import React, { useEffect, useState } from "react";
import moment from "moment";
import { Colors } from "../../../../constant";
import { TablePriorityStyle, GetStatusColor } from "../../../../helpers";

function WaferItem({ wafer }) {
  const [isExpandable, setIsExpandable] = useState(false);
  const [isDisExpandable, setIsDisExpandable] = useState(false);
  // const [waferItem, setWaferItem] = useState(null);

  const expandable = () => {
    if (isExpandable) setIsDisExpandable(false);
    setIsExpandable(!isExpandable);
  };

  const disExpandable = () => {
    setIsDisExpandable(!isDisExpandable);
  };


  useEffect(() => {
    // setWaferItem(wafer);
    // console.log(wafer);
  }, []);

  const waferStatusBadger = (status) => {
    // status = status.toLower;
    if (status === "Open") {
      return "badge badge-danger";
    } else if (status === "On Review") {
      return "badge badge-warning";
    } else {
      return "badge badge-success";
    }
  };
  const waferPriorityBadger = (priority) => {
    // status = status.toLower;
    if (priority === "Critical") {
      return "badge badge-danger";
    }
    else if (priority === "High") {
      return "badge badge-info";
    } else if (priority === "Medium") {
      return "badge badge-warning";
    } else {
      return "";
    }
  };

  const timeDifference = (fromDate, toDate) => {
    const updatingTime = moment(toDate) - moment(fromDate);
    const secs = String("0" + (Math.floor(updatingTime / 1000) % 60)).slice(-2);
    const mins = String("0" + (Math.floor(updatingTime / 1000 / 60) % 60)).slice(-2);
    // var hours = String("0" + Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
    const hrs = String("0" + Math.floor((updatingTime / (1000 * 60 * 60)) % 24)).slice(
      -2
    );
    const days = String("0" + Math.floor(updatingTime / (1000 * 60 * 60 * 24))).slice(
      -1
    );

    if (days > 0) {
      return `${days} D:${hrs} H:${mins} M: ${secs} S`;
    } else {
      return `${hrs} H:${mins} M:${secs} S`;
    }
  }


  return [
    <tr key={wafer._id}>
      <td style={{ borderLeft: TablePriorityStyle(wafer.highPriority) }} className="text-center">
        <span className={waferPriorityBadger(wafer.highPriority)} >
          {wafer.lot}
        </span>
      </td>
      <td className="text-center">
        <span style={{ backgroundColor: GetStatusColor(wafer.status) }} className={waferStatusBadger(wafer.status)}>{wafer.status}</span>
      </td>
      <td> {wafer.layer._id != null
        ? wafer.layer.layerType
        : wafer.layer}</td>
      <td>{wafer.area.areaType}</td>
      <td>{wafer.variable.variableType}</td>
      <td>{moment(wafer.createAt).format("MM-DD-YYYY HH:mm")}</td>
      <td>{wafer.user.name}</td>
      <td>{wafer.remarks}</td>
      <td>
        <span className="text-primary hand-cursor" onClick={() => expandable()}>
          Inspection Details  {!isExpandable ? <i className={`fa fa-angle-down`}></i> : <i className={`fa fa-angle-up`}></i>}
        </span>
      </td>
    </tr>,
    isExpandable && (
      <tr key={wafer._id + '1'}>
        <td colSpan="9">
          <table>
            <thead>
              <tr>
                <th className="date-col">Inspect Start Time</th>
                {/*<th className="name-col">BPO-Tech</th>
                 <th className="lot-col">Log File</th> */}
                <th className="date-col">Completed Time</th>
                <th className="lot-col">Total Inspection Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{moment(wafer.review.reviewStartDate).format("MM-DD-YYYY HH:mm")}</td>
                {/*<td>{wafer.review.user != null ? wafer.review.user.name : '-'}</td>
                <td>-</td> */}
                <td>{moment(wafer.reviewCompleteDate).format("MM-DD-YYYY HH:mm")}</td>
                <td>
                  {timeDifference(wafer.reviewStartDate, wafer.reviewCompleteDate)}
                </td>
                <td className="text-danger hand-cursor" onClick={() => disExpandable()}>
                  Disposition Details {!isDisExpandable ? <i className={`fa fa-angle-down`}></i> : <i className={`fa fa-angle-up`}></i>}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    ),
    isDisExpandable && (
      <tr key={wafer._id + 'isD'}>
        <td colSpan="6">
          <table>
            <thead>
              <tr>
                <th className='name-col dispostion-column'>Wafer Disposition By</th>
                <th className="date-col">Disposition Start Time</th>
                <th className="date-col">Disposition Completed Time</th>
                <th className="date-col">Total Disposition Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{wafer.dispositionSubmittedBy.name}</td>
                <td>{moment(wafer.disposeStartDate).format("MM-DD-YYYY HH:mm")}</td>
                <td>{moment(wafer.disposeCompleteDate).format("MM-DD-YYYY HH:mm")}</td>
                <td>
                  {timeDifference(wafer.disposeStartDate, wafer.disposeCompleteDate)}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    )
  ];
}

export default WaferItem;
