import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  NavLink,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ValidationForm, FileInput, TextInput, SelectGroup, } from 'react-bootstrap4-form-validation';
import { updateDispositionRemarks, updateDispositionToArchive } from "../../../api";
import { toast } from 'react-toastify'
import { useParams, useLocation } from "react-router-dom";
import Annotation from "react-image-annotation";
import {
  PointSelector,
  RectangleSelector,
  OvalSelector,
} from "react-image-annotation/lib/selectors";
import { DispositionResponseStyle, GetStatusColor } from "../../../helpers";
import { CSVLink } from "react-csv";
import {ExcelFile, ExcelSheet} from "react-export-excel";
import { getReviewsByWafer } from "../../../api";

toast.configure();

const DispositionValidation = (props) => {
  const [loading, setLoading] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [waferReview, setwaferReview] = useState([]);
  const [waferData, setWaferData] = useState([]);
  const [waferReviewJSON, setWaferReviewJSON] = useState("");
  const [defectLogs, setDefectLogs] = useState(null);
  const [waferId, setWaferId] = useState("");
  const [reviewData, setReviewData] = useState([]);
  const [reviewIndex, setReviewIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [archiveModal, setArchiveModal] = useState(false);
  const archiveToggleModal = () => setArchiveModal(!archiveModal);
  const [loadImageToAnnotate, setLoadImageToAnnotate] = useState("");
  const [loadImageToAnnotateCount, setLoadImageToAnnotateCount] = useState(0);
  const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [annotation, setAnnotation] = useState({});
  const [finishDisposition, setFinishDisposition] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [dispositionRemarks, setDispositionRemarks] = useState("");
  const [dispositionResponse, setDispositionResponse] = useState("");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("currentUser"))
  );
  const { id } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const formRef = useRef(null);

  useEffect(() => {
    getReviewData(state.waferId);
   
    //setDefectLogs(state.wafer.review)
    // console.log(state.waferReview);
    // console.log(state.wafer);
    // console.log(user);

  }, []);

  const assignReviewDeffects = () => {
      setDefectLogs(waferReview);
  };

   const getReviewData = (waferid) => {
    setLoading(true);
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&id=${waferid}&isDisposition=true`;
    getReviewsByWafer(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          console.log(res.data);
          const _wafer = res.data.reviewResultData;
          setwaferReview(_wafer);
          var waferForReview = _wafer.filter(x=> x.reviewType === "defects");
          if(waferForReview.length == 0){
            setIsNew(false);
            waferForReview = _wafer;
          }
          setWaferData(state.wafer);
          setwaferReview(waferForReview);
          setWaferReviewJSON(waferForReview);
          onSelectReview(waferForReview[0], 0);
          setLoadImageToAnnotate(waferForReview[0]);
          setLoadImageToAnnotateCount(0);
          setWaferId(waferid);
          checkDispositionStatus(waferForReview);
          assignReviewDeffects();

          setLoading(false);
          
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
    
  }

  const bindDefectClassification = (defectClassificationList) => {

    let defectStr = "";
    defectClassificationList.map((dc, index) => (
      defectStr = defectStr + dc + ","
    ));
    let returnString = defectStr.replace(/,\s*$/, "");
    return returnString;
  }
  const dispositionStatusBadger = (dispositionRemark) => {
    // status = status.toLower;
    if (dispositionRemark === true) {
      return "badge badge-success";
    } else if (dispositionRemark === false) {
      return "badge badge-warning";
    }
  };


  const onSelectReview = (review, index) => {
    setReviewData(review);
    setReviewIndex(index);
    // if (review.annotationValue.length > 0) {
    //   let bindData = [];
    //   for (let i = 0; i < review.annotationValue.length; i++) {
    //     //const { geometry, data } = review.annotationValue[i];
    //     let newData = [];
    //     if (review.annotationValue[i][0].geometry != undefined && review.annotationValue[i][0].geometry != null) {
    //       newData.geometry = review.annotationValue[i][0].geometry;
    //       newData.data = review.annotationValue[i][0].data;

    //       bindData.push(newData);
    //     }
    //   }
    //   setAnnotations(bindData);
    // }
    //setModal(!modal);
  };

  const moveToReview = (review, index) => {

    setLoadImageToAnnotate(waferReview[index]);
    setLoadImageToAnnotateCount(index);
    setReviewData(waferReview[index]);
    setReviewData(review);
    setReviewIndex(index);
    if (review.annotationValue.length > 0) {
      let bindData = [];
      for (let i = 0; i < review.annotationValue.length; i++) {
        //const { geometry, data } = review.annotationValue[i];
        let newData = [];
        if (review.annotationValue[i][0].geometry != undefined && review.annotationValue[i][0].geometry != null) {
          newData.geometry = review.annotationValue[i][0].geometry;
          newData.data = review.annotationValue[i][0].data;

          bindData.push(newData);
        }
      }
      setAnnotations(bindData);
    }
    closeCustomizer();
    //setModal(!modal);
  };

  const addDispositionDetails = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    var img = waferReview[loadImageToAnnotateCount].imageName;

    const data = `company=${companyId}&id=${waferId}&reviewDataId=${reviewData._id}&imagename=${img}&dispositionRemark=${dispositionRemarks}&dispositionResponse=${dispositionResponse}`
    updateDispositionRemarks(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Disposition remark added successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setLoading(false);
          const waferForReview = res.data.data.review.reviewData.filter(x=> x.reviewType === "defects");
          setwaferReview(waferForReview);
          checkDispositionStatus(waferForReview);
          // setDefectLogs(res.data.data.review);
          setDispositionRemarks("");
          setDispositionResponse("");
        } else {
          setLoading(false);
        }
        increaseTheCount();
      })
      .catch((err) => console.log(err.message));

  };

  const completeDispositionDetails = () => {
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const data = `company=${companyId}&id=${waferId}&archived=true`
    updateDispositionToArchive(data, tkn)
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Disposition Completed successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          history.push(`/admin/disposition`);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const checkDispositionStatus = (waferList) => {
    let reviewCount = waferList.length;
    const availableWaferList = waferList;

    let submittedDispositionCount = 0;

    availableWaferList.forEach(element => {
      if (element.dispositionRemark !== undefined) {
        submittedDispositionCount = +submittedDispositionCount + 1;
      }
    });

    if (reviewCount === submittedDispositionCount) {
      setFinishDisposition(true);
    }
    else {
      setFinishDisposition(false);
    }

  };

  const convertJSON = () => {
    //setwaferReview(state.waferReview);

    const waferReviewsList = waferReview;
    if (waferReviewsList.length > 0) {
      for (let i = 0; i < waferReviewsList.length; i++) {
        waferReviewsList[i].annotationValue = JSON.stringify();
        setWaferReviewJSON[i] = waferReviewsList[i];
      }
    }
    setWaferReviewJSON(waferReviewsList);
  };

  const decreaseTheCount = () => {
    // to move previous image to display
    setLoading(true);
    let currentCountindex = loadImageToAnnotateCount;
    let decreaseTheCountVal = currentCountindex - 1;
    setLoadImageToAnnotate(waferReview[decreaseTheCountVal]);
    setLoadImageToAnnotateCount(decreaseTheCountVal);
    setReviewData(waferReview[decreaseTheCountVal]);
    setReviewIndex(decreaseTheCountVal);
    let bindData = [];
    if (waferReview[decreaseTheCountVal].annotationValue.length > 0) {
      for (let i = 0; i < waferReview[decreaseTheCountVal].annotationValue.length; i++) {
        //const { geometry, data } = review.annotationValue[i];
        let newData = [];
        newData.geometry = waferReview[decreaseTheCountVal].annotationValue[i][0].geometry;
        newData.data = waferReview[decreaseTheCountVal].annotationValue[i][0].data;
        bindData.push(newData);
      }
    }
    setAnnotations(bindData);
    setLoading(false);
  };

  const increaseTheCount = () => {
    // to move to next image to display
    setLoading(true);
    let currentCountindex = loadImageToAnnotateCount;
    let increaseTheCountVal = currentCountindex + 1;

    if (increaseTheCountVal < waferReview.length) {
      setLoadImageToAnnotate(waferReview[increaseTheCountVal]);
      setLoadImageToAnnotateCount(increaseTheCountVal);

      setReviewData(waferReview[increaseTheCountVal]);
      setReviewIndex(increaseTheCountVal);
      let bindData = [];
      if (waferReview[increaseTheCountVal].annotationValue.length > 0) {
        for (let i = 0; i < waferReview[increaseTheCountVal].annotationValue.length; i++) {
          //const { geometry, data } = review.annotationValue[i];
          let newData = [];
          newData.geometry = waferReview[increaseTheCountVal].annotationValue[i][0].geometry;
          newData.data = waferReview[increaseTheCountVal].annotationValue[i][0].data;

          bindData.push(newData);
        }
      }
      setAnnotations(bindData);
    } else {
      // crossed the last image 
    }
    setLoading(false);
  };

  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".customizer-contain").classList.add("open");
    }
  };

  const closeCustomizer = () => {
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };

  const editReviewProcess = (rd) => {

  }

  const waferStatusBadger = (status) => {
    // status = status.toLower;
    if (status === "Rejected") {
      return "badge badge-danger";
    } else if (status === "Further Review") {
      return "badge badge-warning";
    } else if (status === "Acceptable") {
      return "badge badge-success";
    } else {
      return "badge";
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Manage Disposition Details" />
      {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
      <Container className="wafer-container" fluid={true}>
        <Row>
          <Col>
            <div className="info box-shadow">
              <div className="inspection-stats">
              {/* <ExcelFile>
                <ExcelSheet dataSet={multiDataSet} name="Organization"/>
            </ExcelFile> */}
                <CSVLink
                  data={
                    waferData &&
                    waferReviewJSON &&
                    waferReviewJSON.map((wafer, index) => ({
                      "Image Name": wafer.imageName,
                      "Image Url": wafer.screenshotUrl,
                      "Last Reviewed Image": wafer.lastReviewedImage,
                      "Review Remark": wafer.reviewRemark,
                      "Review Start Date": wafer.imageInstpectStartTime ? moment(
                        wafer.imageInstpectStartTime
                      ).format("MM-DD-YYYY HH:mm"): '-',
                      "Review Completed Date": moment(
                        wafer.reviewRemarkSubmittedAt
                      ).format("MM-DD-YYYY HH:mm"),
                      "submitted By": wafer.submittedBy,
                      "Defect Classification": wafer.defectClassification,
                      // "Annotation Value": JSON.stringify(wafer.annotationValue),
                    }))
                  }
                  filename="DispositionList.csv"
                >
                  <button className="btn btn-info">Export Log</button>
                </CSVLink>
              </div>
              <div className="disposition-stats">
                <div className="inspection-stats">
                  Review # :{" "}
                  <span className="color-light">
                    {loadImageToAnnotateCount + 1} / {waferReview.length}
                  </span>
                </div>

                {waferReview.length > 0 &&
                  waferReview[loadImageToAnnotateCount].dispositionRemark !==
                  undefined &&
                  waferReview[loadImageToAnnotateCount].dispositionRemark !==
                  null && (
                    <>
                      <div className="inspection-stats">
                        Disposition Remark :{" "}
                        <span className="color-light">
                          {
                            waferReview[loadImageToAnnotateCount]
                              .dispositionRemark
                          }
                        </span>
                      </div>
                      <div className="inspection-stats">
                        <span className="badge badge-success">Completed</span>
                      </div>
                    </>
                  )}
              </div>
              <div className="clear"></div>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col lg="8" md="8" sm="8">
                <Row className="mr_t_2">
                  <Col sm="6 text-left">
                    {loadImageToAnnotateCount > 0 && (
                      <Button
                        disabled={loadImageToAnnotateCount === 0}
                        onClick={() => decreaseTheCount()}
                        color="success"
                        className="mr-1 btn-outline-* btn-sm"
                      >
                        <i className="fa fa-angle-left m-r-10"></i>Prev.
                      </Button>
                    )}
                  </Col>
                  <Col sm="6 text-right">
                    {loadImageToAnnotateCount < waferReview.length - 1 && (
                      <Button
                        disabled={
                          loadImageToAnnotateCount === waferReview.length - 1
                        }
                        onClick={() => increaseTheCount()}
                        color="success"
                        className="mr-1 btn-outline-* btn-sm"
                      >
                        Next<i className="fa fa-angle-right m-l-10"></i>
                      </Button>
                    )}
                    {loadImageToAnnotateCount === waferReview.length - 1 && (
                      <Button
                        onClick={() => setArchiveModal(!archiveModal)}
                        color="success"
                        disabled={!finishDisposition}
                        className="mr-1 btn-air-*"
                      >
                        Complete Disposition And Archive
                      </Button>
                    )}
                  </Col>
                </Row>
                {/* {waferReview != null && waferReview.length > 0 && (
                  <>
                    <Annotation
                      loading={loading}
                      key={waferReview[loadImageToAnnotateCount].imageUrl}
                      //src={waferReview[loadImageToAnnotateCount]}

                      src={isNew?'https://inxpector.sharepoint.com/'+waferReview[loadImageToAnnotateCount].imageUrl:waferReview[loadImageToAnnotateCount].imageUrl }
                      annotations={annotations}
                      type={RectangleSelector.TYPE}
                      value={annotation}
                      disableSelector={true}
                      disableZoom={false}
                    />
                  </>
                )} */}


              </Col>

              <Col lg="4" md="4" sm="4">
                <div className="mar-tb-15 text-right">
                  <a className="defectsLogLink" onClick={openCustomizer}>
                    {"Defects Log >"}
                  </a>
                </div>


                <div className="mar-tb-10">
                  {/* {console.log(waferReview)} */}
                  {waferReview.length > 0 && (
                    <>
                     <Label htmlFor="destroy()">Image Name</Label>
                      <p>
                        <span className="color-light">
                       <b> {waferReview[
                          loadImageToAnnotateCount
                        ].imageName}</b>
                        </span>
                        </p>
                      <Label htmlFor="destroy()">Defect Classification</Label>
                      <p>
                        {waferReview[
                          loadImageToAnnotateCount
                        ].defectClassification.join(', ')}
                      </p>

                      <Label htmlFor="destroy()">Review Remarks</Label>
                      <p>
                        {waferReview[loadImageToAnnotateCount].reviewRemark}
                      </p>
                    </>
                  )}
                </div>

                <ValidationForm
                  onSubmit={addDispositionDetails}
                  ref={formRef}
                  className="theme-form customizer-body custom-scrollbar"
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="Response">Disposition Response</Label>
                        <SelectGroup
                          type="select"
                          name="Response"
                          onChange={(e) => setDispositionResponse(e.target.value)}
                          required
                          errorMessage={{ required: "Dispostion Response Is Required" }}
                          className="form-control priority"
                          selected={dispositionResponse}
                          value={dispositionResponse}
                        >
                          <option value="">{"Select Disposition Reponse"}</option>
                          <option value="Acceptable">{"Acceptable"}</option>
                          <option value="Further Review">{"Further Review"}</option>
                          <option value="Rejected">{"Rejected"}</option>
                        </SelectGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="destroy()">Disposition Remarks</Label>
                        <TextInput
                          required
                          className="form-control"
                          multiline
                          type="text"
                          name="dispositionRemark"
                          errorMessage={{
                            required: "Disposition Remark Is Required",
                          }}
                          placeholder="Disposition Remark"
                          value={dispositionRemarks}
                          onChange={(e) =>
                            setDispositionRemarks(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="text-center">
                        <Button disabled={loading} className="success">
                          Submit
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    {loadImageToAnnotateCount === waferReview.length - 1 && (
                      <Col sm="12">
                        <Button
                          onClick={() => setArchiveModal(!archiveModal)}
                          color="success"
                          className="mr-1 btn-air-*"
                        >
                          Complete Disposition And Archive
                        </Button>
                      </Col>
                    )}
                  </Row> */}
                </ValidationForm>
              </Col>
            </Row>
           
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain extended-side-container-large">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h5>Defects Log</h5>
        </div>
        <div className="theme-form customizer-body custom-scrollbar">
          {defectLogs != null ? (
            <>
              <Table className="dash-status-table" bordered responsive>
                <thead>
                  <tr>
                    {/* <th className="halogenix-column">S.No</th> */}
                    <th className="halogenix-column">Image Name</th>
                    <th className="name-col halogenix-column">Defects</th>
                    <th className="name-col halogenix-column">Remarks</th>
                    <th className="name-col halogenix-column">Dis. Remarks</th>
                    <th className="name-col halogenix-column">Dis. Response</th>
                    <th className="halogenix-column date-col">Reviewed Time</th>
                  </tr>
                </thead>
                <tbody>
                  {waferReview.map((rd, index) => (
                  
                    <tr key={rd._id}>
                      <td style={{ borderLeft: DispositionResponseStyle(rd.dispositionResponse) }} className="mouse-hand text-center">
                        <NavLink className={waferStatusBadger(rd.dispositionResponse)} onClick={() => moveToReview(rd, index)}>
                          {rd.imageName}
                        </NavLink>
                      </td>
                      <td>
                        {rd.defectClassification != null &&
                          rd.defectClassification.join(', ')}
                      </td>
                      <td>{rd.reviewRemark}</td>
                      <td>{rd.dispositionRemark !== undefined ? rd.dispositionRemark : "-"}</td>
                      <td>{rd.dispositionResponse !== undefined ? rd.dispositionResponse : "-"}</td>
                      <td> {rd.reviewRemarkSubmittedAt !== null
                        ? moment(rd.reviewRemarkSubmittedAt).format(
                          "MM-D-YYYY H:mm"
                        )
                        : "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            "No Defects Found"
          )}
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader>Disposition Review</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12" sm="12" md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="8" md="8" sm="8">
                      <Annotation
                        loading={loading}
                        key={1}
                        src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                        annotations={annotations}
                        type={RectangleSelector.TYPE}
                        value={annotation}
                        disableSelector={true}
                        disableZoom={false}
                      />
                    </Col>
                    <Col lg="4" md="4" sm="4">
                      <ValidationForm
                        onSubmit={addDispositionDetails}
                        ref={formRef}
                        className="theme-form customizer-body custom-scrollbar"
                      >
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label htmlFor="destroy()">
                                Disposition Remarks
                              </Label>
                              <TextInput
                                required
                                className="form-control"
                                type="textarea"
                                name="dispositionRemark"
                                errorMessage={{
                                  required: "Disposition Remark Is Required",
                                }}
                                placeholder="Disposition Remark"
                                value={dispositionRemarks}
                                onChange={(e) =>
                                  setDispositionRemarks(e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormGroup className="text-center">
                              <Button disabled={loading} className="success">
                                Submit
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </ValidationForm>
                    </Col>
                  </Row>
                  {loading && (
                    <div className="text-center">
                      <div className="loader-box">
                        <div className="loader-9"></div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} className="btn btn-secondary">
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={archiveModal} toggle={archiveToggleModal} size="sm">
        <ModalBody>Confirm Disposition Completed?</ModalBody>
        <ModalFooter>
          <Button onClick={archiveToggleModal} className="btn btn-default">
            No
          </Button>
          <button
            onClick={completeDispositionDetails}
            className="btn btn-success"
          >
            Yes
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DispositionValidation;