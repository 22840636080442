import React, { Fragment, useState, useRef, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";
import { ValidationForm,Checkbox, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import validator from 'validator';
import { createNewInspector, getUsers, updateUser } from "../../../api";
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { register } from '../../../actions/auth';
import PropTypes from 'prop-types';
import $ from 'jquery';
import DataTable from 'datatables.net';

import { toast } from 'react-toastify'
toast.configure();

const UsersPage = (props) => {
  const [rightSidebar, setRightSidebar] = useState(true);
  const [users, setUsers] = useState([]);
  const [groupedUsers, setGroupedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const [userId, setUserId] = useState([]);
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confrimPassword, setConfirmPassword] = useState("");
  const [isActive, setIsActive] = useState(true);
  // const [disabled, setDisabled] = useState(false);
  // const [enabled, setEnabled] = useState(true);
  const [companyId, setCompanyId] = useState(
    localStorage.getItem('cid')
  );
  const [token, setToken] = useState(
    localStorage.getItem('token')
  );

  const formRef = useRef(null);
  useEffect(() => {
    getUserList();
  }, [])


  useEffect(() => {
    if(groupedUsers.length>0){
      var groupColumn = 2;
      var table = $('#userTable').DataTable({
          "columnDefs": [
              { "visible": false, "targets": groupColumn },
              {
                'targets': [5], 
                'orderable': false,
             }
          ],
          "order": [[ groupColumn, 'asc' ]],
          "displayLength": 10,
          "drawCallback": function ( settings ) {
              var api = this.api();
              var rows = api.rows( {page:'current'} ).nodes();
              var last=null;
   
              api.column(groupColumn, {page:'current'} ).data().each( function ( group, i ) {
                  if ( last !== group ) {
                      $(rows).eq( i ).before(
                          '<tr class="group groupStyle"><td colspan="5">'+group+'</td></tr>'
                      );
   
                      last = group;
                  }
              } );
          }
      } );
      $('#userTable tbody').on( 'click', 'tr.group', function () {
        var currentOrder = table.order()[0];
        if ( currentOrder[0] === groupColumn && currentOrder[1] === 'asc' ) {
            table.order( [ groupColumn, 'desc' ] ).draw();
        }
        else {
            table.order( [ groupColumn, 'asc' ] ).draw();
        }
    } );
    }
  }, [groupedUsers])

  const getUserList = () => {
    $('#userTable').dataTable().fnDestroy();
    var tkn = `Bearer ${token}`;
    let data = `company=${companyId}&role=company-admin&role=company-user&role=inxpector`;
    getUsers(data, tkn).then(res => {
      if (res.data.success === true) {
        setUsers(res.data.data);
        // console.log(grouped);
        setGroupedUsers(res.data.data);
        // console.log(grouped['company-user']);
        // console.log(Object.keys(grouped));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(err => console.log(err.message))
  }

  const addUser = (event) => {
    event.preventDefault();
    setLoading(true);
    var tkn = `Bearer ${token}`;
    const body = JSON.stringify({
      company: companyId,
      name,
      email,
      password,
      isActive,
      role
    });
    clearValues();
    createNewInspector(body, tkn).then((res) => {

      toast.success("Inspector added successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    });
  };



  const openCustomizer = () => {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      // clearValues();
      document.querySelector(".customizer-contain").classList.add("open");
    }

  };

  const closeCustomizer = () => {
    setName('');
    setEmail('');
    setIsActive(true);
    setConfirmPassword("");
    setPassword("");
    setRole('');
    setLoading(false);
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
    setEdit(false);
  };
  const editUser = (user) => {
    setEdit(true);
    setUserId(user._id);
    setIsActive(user.isActive);
    setName(user.name);
    setEmail(user.email);
    setRole(user.role);

    openCustomizer();
  };

  const clearValues = () => {
    getUserList();
    setName('');
    setEmail('');
    setIsActive(true);
    setConfirmPassword("");
    setPassword("");
    setRole('');
    setLoading(false);
    let curentRormRef = formRef.current;
    curentRormRef.resetValidationState(true);
  }
  const matchPassword = (value) => {
    return value && value === password;
  }

  const updateUserProcess = (event) => {
    event.preventDefault();
    setLoading(true);

    var tkn = `Bearer ${token}`;

    const body = JSON.stringify({
      company: companyId,
      name: name,
      email: email,
      isActive: isActive,
      role: role,
    });
    updateUser(userId, body, tkn).then((res) => {

      if (res.data.success) {
        setEdit(false);
        toast.success("User details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        //getVariableList();
        clearValues();
        closeCustomizer();
      }
      else {
        toast.warning("Error in updating Variable", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        clearValues();
        closeCustomizer();
        setEdit(false);
      }
    }).catch(function (response) {
      //handle error
      clearValues();
      closeCustomizer();
      setEdit(false);
      console.log(response);
    });
    // closeCustomizer();
    //getDefetctsList();

    //setEdit(false);
  };

  

  return (
    <Fragment>
      <Breadcrumb parent="InXpector" title="Users List" />
      <Container className="master-container" fluid={true}>
        <Card>
          <CardHeader>
            <Button onClick={openCustomizer} color="primary" size="md">
              <i className="fa fa-plus-circle"></i> Add User
            </Button>
          </CardHeader>
          <CardBody>
            <Table id="userTable" className="master-table" bordered responsive>
              <thead>
                <tr>
                 
                  <th className="name-col">Name</th>
                  <th className="name-col">Email</th>
                  <th className="name-col">Role</th>
                  <th>Added on</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
             
              <tbody>
              {groupedUsers.length>0?groupedUsers.map((user, index) =>
                      <tr key={user._id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>
                        {moment(user.createAt).format("MM-DD-YYYY H:mm")}
                      </td>
                      <td data-order={user.isActive} className="text-center">
                        {user.isActive ? (
                          <i className="icon-green fa fa-check-square-o"></i>
                        ) : (
                          <i className="icon fa fa-minus-circle"></i>
                        )}
                      </td>
                      <td className="text-center">
                        <i
                          onClick={() => editUser(user)}
                          className="icon fa fa-edit"
                        ></i>
                      </td>
                    </tr>
                ):
                <tr>
                    <td colSpan="12" className="text-center">
                      {" "}
                      No data found.{" "}
                    </td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                    <td className="d-none"></td>
                  </tr>
              }
                 </tbody>
                {/* {groupedUsers.forEach((value, key) => 
               
                    {value.map((user, index) => (
                      <tr key={user._id}>
                        <td></td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>
                          {moment(user.createAt).format("MM-DD-YYYY H:mm")}
                        </td>
                        <td className="text-center">
                          {user.isActive ? (
                            <i className="icon-green fa fa-check-square-o"></i>
                          ) : (
                            <i className="icon fa fa-minus-circle"></i>
                          )}
                        </td>
                        <td className="text-center">
                          <i
                            onClick={() => editUser(user)}
                            className="icon fa fa-edit"
                          ></i>
                        </td>
                      </tr>
                    ))} 
                )} */}
                
             
            </Table>
            {loading && (
              <div className="text-center">
                <div className="loader-box">
                  <div className="loader-9"></div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <div className="customizer-contain">
        <div className="customizer-header">
          <i className="icon-close" onClick={closeCustomizer}></i>
          <h6>{edit ? `Edit` : `Add New`} User</h6>
        </div>
        <ValidationForm
          onSubmit={edit ? updateUserProcess : addUser}
          ref={formRef}
          className="updateBar-container theme-form customizer-body custom-scrollbar"
        >
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="status-type">Select Role</Label>
                <SelectGroup
                  type="select"
                  name="status-type"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  errorMessage={{ required: "Role Is Required" }}
                  className="form-control digits"
                  selected={role}
                >
                  <option value="">{"Select Role"}</option>
                  <option value="company-admin">{"Company Admin"}</option>
                  <option value="company-user">{"Company User"}</option>
                  <option value="inxpector">{"Inxpector"}</option>
                </SelectGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="waferName">Name</Label>
                <TextInput
                  className="form-control"
                  required
                  type="text"
                  name="waferName"
                  value={name}
                  errorMessage={{ required: "Name Is Required" }}
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="waferEmail">Email</Label>
                <TextInput
                  className="form-control"
                  type="text"
                  value={email}
                  name="waferEmail"
                  required
                  validator={validator.isEmail}
                  errorMessage={{
                    required: "Email Is Required",
                    validator: "Invalid Email",
                  }}
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="waferPassword">Password</Label>
                <TextInput
                  value={password}
                  required={edit ? true : false}
                  type="password"
                  className="form-control"
                  nane="waferPassword"
                  // pattern="(?=.*).{5,}"
                  validator={validator.isStrongPassword}
                  errorMessage={{
                    required: "Password Is Required",
                    validator:
                      "Use 8 or more characters with a mix of letters, numbers & symbols",
                  }}
                  placeholder="Enter Password"
                  disabled={edit ? true : false}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setConfirmPassword("");
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="wafer">Confirm Password</Label>
                <TextInput
                  value={confrimPassword}
                  className="form-control"
                  type="password"
                  required={edit ? true : false}
                  name="waferConPassword"
                  validator={matchPassword}
                  errorMessage={{
                    required: "Confirm Password Is Required",
                    validator: "Password does not match",
                  }}
                  placeholder="Enter password"
                  disabled={edit ? true : false}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
              <Checkbox
                  name="status-type"
                  className="master-checkbox"
                  label="Active?"
                  id="status-type"
                  value={isActive}
                  onChange={e => setIsActive(!isActive)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="text-center">
                {edit ? (
                  <Button disabled={loading} className="success">
                    Update User
                  </Button>
                ) : (
                  <Button className="success" disabled={loading}>
                    Create User
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ValidationForm>
      </div>
    </Fragment>
  );
}

UsersPage.prototypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
}
export default connect(null, { setAlert, register })(UsersPage);