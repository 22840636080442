import React, { useEffect, useState } from "react";
import moment from "moment";
import {
    Modal,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { updateDisposition } from "../../../api";
import { TablePriorityStyle, GetStatusColor } from "../../../helpers";


function ArchivedTable({ wafer, index }) {
    const [loading, setLoading] = useState(true);
    const [companyId, setCompanyId] = useState(localStorage.getItem("cid"));
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isExpandable, setIsExpandable] = useState(false);
    const [isDisExpandable, setIsDisExpandable] = useState(false);
    const [waferData, setWaferData] = useState(null);
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    // const [waferItem, setWaferItem] = useState(null);

    const history = useHistory();

    const expandable = () => {
        if (isExpandable) setIsDisExpandable(false);
        setIsExpandable(!isExpandable);
    };

    const disExpandable = () => {
        setIsDisExpandable(!isDisExpandable);
    };

    const onSelectWafer = (wafer) => {
        setWaferData(wafer);
        // setModal(!modal);
        //
        
        history.push(`/archive-log/${wafer._id}`, { waferReview: wafer.review.reviewData, waferId: wafer._id, wafer: wafer });

        //
    };

    useEffect(() => {
        // setWaferItem(wafer);
        // console.log(wafer);
    }, []);

    // const waferStatusBadger = (isDispositionCompleted) => {
    //     // status = status.toLower;
    //     if (isDispositionCompleted === false) {
    //         return "badge badge-warning";
    //     } else if (isDispositionCompleted === true) {
    //         return "badge badge-success";
    //     }
    //     // else {
    //     //   return "badge badge-success";
    //     // }
    // };
    const waferStatusBadger = (status) => {
        // status = status.toLower;
        if (status === "Open") {
            return "badge badge-danger";
        } else if (status === "On Review") {
            return "badge badge-warning";
        } else {
            return "badge badge-success";
        }
    };

    const timeDifference = (fromDate, toDate) => {
        const updatingTime = moment(toDate) - moment(fromDate);
        const secs = String("0" + (Math.floor(updatingTime / 1000) % 60)).slice(-2);
        const mins = String("0" + (Math.floor(updatingTime / 1000 / 60) % 60)).slice(-2);
        // var hours = String("0" + Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
        const hrs = String("0" + Math.floor((updatingTime / (1000 * 60 * 60)) % 24)).slice(
            -2
        );
        const days = String("0" + Math.floor(updatingTime / (1000 * 60 * 60 * 24))).slice(
            -1
        );

        if (days > 0) {
            return `${days} D:${hrs} H:${mins} M: ${secs} S`;
        } else {
            return `${hrs} H:${mins} M:${secs} S`;
        }
    }

    const waferPriorityBadger = (priority) => {
        // status = status.toLower;
        if (priority === "Critical") {
            return "badge badge-danger";
          }
          else if (priority === "High") {
            return "badge badge-info";
          } else if (priority === "Medium") {
            return "badge badge-warning";
        } else {
            return "";
        }
    };
    return [

        <tr key={wafer._id}>
            <td style={{ borderLeft: TablePriorityStyle(wafer.highPriority) }} className="text-center">

                <span className="text-primary hand-cursor" onClick={() => expandable()}>
                    {index + 1}  {!isExpandable ? <i className={`fa fa-angle-down`}></i> : <i className={`fa fa-angle-up`}></i>}
                </span>
            </td>
            <td onClick={() => onSelectWafer(wafer)} className="text-center mouse-hand">
                <span className={waferPriorityBadger(wafer.highPriority)} >
                    {wafer.lot}
                </span>
                {/* <span className={waferPriorityBadger(wafer.highPriority)}>
                    {wafer.lot}
                </span> */}
            </td>
            <td className="text-center">
                {/* <span className={waferStatusBadger(wafer.isDispositionCompleted)}>
                    {"Archived"}
                </span> */}
                <span style={{ backgroundColor: GetStatusColor(wafer.status) }} className={waferStatusBadger(wafer.status)}>{wafer.status}</span>

            </td>
            <td>{wafer.layer._id != null ? wafer.layer.layerType : "-"}</td>
            <td>{moment(wafer.createAt).format("MM-DD-YYYY HH:mm")}</td>
            <td>{wafer.user.name}</td>
            <td>{wafer.remarks}</td>
            <td>{wafer.area.areaType}</td>
            <td>{wafer.variable.variableType}</td>
        </tr>,
        isExpandable && (
            <tr key={wafer._id + '1'}>
                <td colSpan="8">
                    <table>
                        <thead>
                            <tr>

                                <th>Folder Url</th>
                                <th className="date-col">Review Start Time</th>
                                <th className="date-col">Review Completed Time</th>
                                <th className="lot-col">Total Inspection Time</th>
                                <th className="date-col">Disposition Start Time</th>
                                <th className="date-col">Disposition Completed Time</th>
                                <th className="date-col">Total Disposition Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td className='url-col'>
                                    <a href={wafer.folderUrl} target="_blank" rel="noopener noreferrer"> {wafer.folderUrl} </a>
                                </td>
                                <td>{moment(wafer.reviewStartDate).format("MM-DD-YYYY HH:mm")}</td>
                                <td>{moment(wafer.reviewCompleteDate).format("MM-DD-YYYY HH:mm")}</td>
                                <td>
                                    {timeDifference(moment(wafer.reviewStartDate).format("MM-DD-YYYY HH:mm"), moment(wafer.reviewCompleteDate).format("MM-DD-YYYY HH:mm"))}
                                </td>
                                <td>{moment(wafer.disposeStartDate).format("MM-DD-YYYY HH:mm")}</td>
                                <td>{moment(wafer.disposeCompleteDate).format("MM-DD-YYYY HH:mm")}</td>
                                <td>
                                    {timeDifference(moment(wafer.disposeStartDate).format("MM-DD-YYYY HH:mm"), moment(wafer.disposeCompleteDate).format("MM-DD-YYYY HH:mm"))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        ),
    ];
}

export default ArchivedTable;
