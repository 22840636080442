import React, { Fragment, useEffect, useState } from "react";
import Chart from 'react-apexcharts'
import dayjs from "dayjs";
import { fetchWeeklyChart } from "../../api";
import { Colors } from "../../constant/index";

const WaferWeeklyChart = (props) => {

    const [options, setOptions] = useState({});
    const [series, setSeries] = useState({});
    const [companyId, setCompanyId] = useState(
        localStorage.getItem('cid')
    );
    const [token, setToken] = useState(
        localStorage.getItem('token')
    );

    useEffect(() => {
        loadWaferChart();
    }, [])

    const loadWaferChart = () => {
        var tkn = `Bearer ${token}`;
        let data = `companyId=${companyId}`;
        fetchWeeklyChart(data, tkn).then(res => {
            if (res.data && res.data.success === true) {
                const reportData = res.data.result;
                let wafer = [], date = [];
                let waferCount = 0;
                if (res.data.result && res.data.result.length > 0) {
                    reportData && reportData.map(data => {
                        wafer.push(data.weeklyCount);
                        date.push(dayjs(Date.UTC(data.year, (data.month - 1), data.day)).valueOf())
                    })
                    waferCount = wafer.reduce((total, data) => total = total + data, 0)

                } else {
                    wafer.push(0);
                    const today = dayjs(new Date()).valueOf();
                    date.push(today)
                }

                let seriesData = [
                    {
                        name: "Wafer: " + waferCount,
                        data: wafer
                    },
                ]

                let optionsData = {
                    chart: {
                        id: 'waferChart',
                        type: "bar",
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    stroke: {
                        show: true,
                        width: 2
                    },
                    colors: [Colors.open],
                    // colors: ['#B21F00','#C9DE00','#2FDE00','#00A6B4','#6800B4'],
                    xaxis: {
                        type: "category",
                        categories: date,
                        labels: {
                            hideOverlappingLabels: true,
                            format: undefined,
                            formatter: function (val) {
                                return dayjs(val).format("MMM D 'YY")
                            }
                        },
                        tickAmount: date.length <= 13 ? date.length : 9, //6 is the default behaviour
                        tickPlacement: 'on',
                    },
                    yaxis: res.data.result.length > 0 ? {
                        labels: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    } :
                        {
                            max: 1,
                            labels: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        },
                    legend: {
                        position: 'top',
                        itemMargin: {
                            vertical: 10,
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        x: {
                            format: 'MMM d \'yy',
                        },
                        y: {
                            formatter: function (val) {
                                return ": " + val
                            },
                            title: {
                                formatter: function (value) {
                                    return value.split(":")[0]
                                },
                            },
                        }
                    },
                };

                setSeries(seriesData)
                setOptions(optionsData)
            }
        }).catch(err => console.log(err.message))
    }

    return (
        <div>
            {
                series && series.length > 0 &&
                <Chart options={options} series={series} type="bar" width="100%" height={400} />
            }
        </div>
    );
};

export default WaferWeeklyChart;
