import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


const StatusUpdate = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      <ModalFooter>
        
      </ModalFooter>
    </Modal>
  );
};

export default StatusUpdate;